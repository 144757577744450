<template>
  <b-col cols="6" offset=2>
    <h1>Eintrag anlegen</h1>

    <b-form-row class="my-2">
      <b-col cols="2">Name</b-col>
      <b-col cols="5"><input type="text" class="form-control" v-model="aktiver.vorname" autocomplete="off" placeholder="Vorname" @blur="updateExistingNames"/></b-col>
      <b-col cols="5"><input type="text" class="form-control" v-model="aktiver.nachname" autocomplete="off" placeholder="Nachname" @blur="updateExistingNames"/></b-col>
    </b-form-row>
    <b-form-row class="my-2">
      <b-col cols="10" offset="2">
        <b-alert variant="warning" :show="existingNames.length != 0">
          Zu dem eingegebenen Namen liegen bereits folgende andere Einträge vor. Bitte prüfe, bevor du fortfährts, ob die Person nicht schon eingetragen ist.
          <ul>
            <li v-for="existing in existingNames">
              <router-link :to="{ name: 'viewAktiven', params: { aid: existing.link.id }}">{{existing.label}}</router-link>
            </li>
          </ul>
        </b-alert>
      </b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Adresse</b-col>
      <b-col cols="10"><b-input v-model="aktiver.enteredAddress.strasse" placeholder="Straße Nr."/></b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2" offset="2"><b-input v-model="aktiver.enteredAddress.plz" placeholder="Plz"/></b-col>
      <b-col cols="8"><b-input v-model="aktiver.enteredAddress.ort" placeholder="Ort"/></b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Geburtstag</b-col>
      <b-col cols="10"><datepicker v-model="aktiver.geburtstagEntered" /></b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">E-Mail</b-col>
      <b-col cols="10"><b-input v-model="aktiver.enteredEmail"/></b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Telefon</b-col>
      <b-col cols="10"><b-input v-model="aktiver.telefonEntered"/></b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Mobil</b-col>
      <b-col cols="10"><b-input v-model="aktiver.mobilEntered"/></b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Wissenswertes</b-col>
      <b-col cols="10"><b-textarea rows="4" v-model="aktiver.infos"/></b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Beruf</b-col>
      <b-col cols="10"><b-input v-model="aktiver.beruf"/></b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Kindergruppen</b-col>
      <b-col cols="10"><b-input v-model="aktiver.gruppenName"/></b-col>
    </b-form-row>


    <b-form-row class="my-2">
      <b-col cols="2">Funktionen</b-col>
      <b-col cols="8">
      <aktivenFunktionEditor :aktiver="aktiver"/>
      </b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="10" offset="2">
        <saveBtn ref="saveBtn" :saveable="aktiver" api="seminare/saveAktiven.php" :initialSaved="false" :disabled="saveDisabled" @saved="openAktiven"/>
        <span v-if="saveDisabled">(Du musst zumindest einen Vor- und Nachnamen eingeben)</span>
      </b-col>
    </b-form-row>

  </b-col>
</template>


<script>

import aktivenFunktionEditor from './aktivenFunktionEditor.vue'
import saveBtn from '../saveBtn.vue'
import datepicker from "../datepicker.vue"

export default {
  name: 'NewAktiven',
  components:{
    aktivenFunktionEditor,
    saveBtn,
    datepicker
  },
  data(){
    return {
      aktiver: {
        id: "new",
        vorname:"",
        nachname:"",
        addressChanged: Date.now(),
        geburtstagChanged: Date.now(),
        enteredEmail:"",
        emailChanged: Date.now(),
        telefonEntered: "",
        telefonChanged: Date.now(),
        mobilEntered: "",
        mobilChanged: Date.now(),
        infos:"",
        beruf: "",
        gruppenName:"",
        enteredAddress:{strasse:"", plz:"", ort:""},
        geburtstagEntered: null,
        gruppen: []
      },
      existingNames:[]

    };
  },
  props:[],
  methods:{
    updateExistingNames(){
      var t = this;
      t.existingNames = [];
      if(this.saveDisabled)
        return;
      var name = this.aktiver.vorname + " " + this.aktiver.nachname;
      name = name.trim();

      $.ajax( {
        url: t.$root.api_root + "seminare/get_search.php",
        data: {
            startingWith: name,
            findNames: true,
            findSems: false,
            findGroups: false,
            findFlyer: false
        },
        success: function( data ) {
          t.$root.checkRespForLoginFail(data);
          t.existingNames =  JSON.parse(data);
        }
    });
    },
    openAktiven(res){
        this.$router.push({ name: 'viewAktiven', params: { aid: res.trim() } })
    }
  },

  created(){
    this.$store.dispatch('loadFunktionen');
  },
  computed:{
    saveDisabled(){
      return this.aktiver.vorname == null || this.aktiver.vorname == '' || this.aktiver.nachname == null || this.aktiver.nachname == '';
    }
  },
}

</script>

<style scoped>


</style>


