<template>
  <div id="pageNrBox" v-bind:class="{ right:  rightOriented, left: !rightOriented}">
    <span>{{nr}}</span>
  </div>
</template>


<script>
export default {
  name: 'PageNrBox',
  data(){
    return {};
  },
  props: ['nr', 'rightOriented'],
}

</script>

<style>
#pageNrBox{
  position: absolute;
  bottom: 5mm;
  font-size:14px;
}

#pageNrBox.left{
  right: 68mm;
  text-align: right;
}

#pageNrBox.right{
  left: 68mm;
  text-align: left;
}



</style>

