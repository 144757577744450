import {base64ToArray, arrayToBase64} from "./helperFunctions";

class Fido2Authentification {
    constructor(api_root) {
        this.api_root = api_root;
    }

    authenticate(userName) {
        return this.getChallengeForUser(userName)
            .then((challenge) => this.decodeChallenge(challenge))
            .then((challenge) => navigator.credentials.get({ 'publicKey': challenge }))
            .then(challenge => this.encodeResponse(challenge))
            .then(response => JSON.stringify(response));
    }

    getChallengeForUser(userName) {
        return new Promise((resolve, reject) => {
            $.post({
                url: this.api_root + "fido2/fido2authStart.php",
                data: {
                    name: userName
                },
                success: function (data) {
                    resolve(data);
                },
                error: function () {
                    //something went wrong. Show error box
                    reject("Verbindungsfehler");
                },
                dataType: 'json',
            });
        });
    }

    decodeChallenge(publicKey){
        publicKey.challenge = base64ToArray(publicKey.challenge);
        if (publicKey.allowCredentials) {
            publicKey.allowCredentials = publicKey.allowCredentials.map(function(data) {
                data.id = base64ToArray(data.id);
                return data;
            });
        }
        return publicKey;
    }

    encodeResponse(data){
        return {
            id: data.id,
            type: data.type,
            rawId: arrayToBase64(data.rawId),
            response: {
                authenticatorData: arrayToBase64(data.response.authenticatorData),
                clientDataJSON: arrayToBase64(data.response.clientDataJSON),
                signature: arrayToBase64(data.response.signature),
                userHandle: data.response.userHandle ? arrayToBase64(data.response.userHandle) : null
            }
        };
    }
}

export {Fido2Authentification};