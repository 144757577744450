<template>
  <div v-if="isChrome"><slot/></div>
  <b-col v-else cols="8" offset="2">
    <b-alert show variant="danger">
      <b>Bitte verwende Chrome / Chromium</b><br> Leider funktioniert die Flyer bearbeitung derzeit nur in Chrome & Chromium
    </b-alert>
  </b-col>
</template>


<script>

export default {
  name: 'OnlyInChrome',
  data(){
    return {
     isChrome: false
    };
  },
  mounted() {
    this.isChrome = !!window.chrome;
  }
}

</script>

