<template>

</template>


<script>

export default {
  name: 'ListItemSaver',
  props:["listItem"],
  data(){
    return {
        isSaved: true,
        timeout: null
    };
  },

  methods:{
    deleteMe(){
      var t = this;
      $.get(this.$root.api_root + "stock/deleteListItem.php",{
        liid: this.listItem.id
      },function(data){
        t.$root.checkRespForLoginFail(data);
        t.$emit("deleted");
      });
    },

    save(t){
      if(this.isSaved)
        return;
      console.log("save");
      var t = this;
      $.get(this.$root.api_root + "stock/saveListItem.php",{
        liid: this.listItem.id,
        num: this.listItem.num,
        comment: this.listItem.comment
      },function(data){
        t.$root.checkRespForLoginFail(data);
        t.isSaved = true;
      });
    },
    edited(){
      this.isSaved = false;
      var t = this;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(function(){
        t.save(t);
      },3000);
    }
  },
  watch:{
    "listItem.num"(){
      this.edited();
    },
    "listItem.comment"(){
      this.edited();
    }
  },
  beforeDestroy(){
    this.save();
  }

}

</script>

<style scoped>
#num{
  width:80px;
}

@media print{
  td{
    padding: 2px !important;
  }
  .form-group{
    margin:0;
  }
}
</style>

