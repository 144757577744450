<template>
  <b-col cols="8" offset=2>
    <h1>{{aktiver.vorname | capitalize}} {{aktiver.nachname | capitalize}}</h1>
    <b-row style="margin-bottom: 100px;">
      <b-col cols="3">
        <aliases :aktiver="aktiver" />

        <p>
          <b>Adressse</b><br>
          {{aktiver.strasse}}<br>
          {{((aktiver.plz != null ? aktiver.plz : "") + " " + (aktiver.ort != null ? aktiver.ort : "")).trim()}}<br>
          <span @click="$refs.addressDetails.show()" class="btn-link">mehr</span>
        </p>

        <p>
          <b>E-Mail Adresse</b> <emailErrorChecker :email="aktiver.email" :defektEmails="aktiver.defektEmails"/><br>
          {{aktiver.email}} <br>
          <span @click="$refs.emailDetails.show()"  class="btn-link">mehr</span>
        </p>

        <p>
          <b>Geburtstag</b><br>
          {{aktiver.geburtstag != null ? new Date(aktiver.geburtstag).toLocaleDateString("de-DE") : ""}}<br>
          <span @click="$refs.gebDetails.show()"  class="btn-link">mehr</span>
        </p>

        <p>
          <b>Telefon</b><br>
          {{aktiver.telefon}}<br>
          <span @click="$refs.telDetails.show()"  class="btn-link">mehr</span>
        </p>

        <p>
          <b>Handy</b><br>
          {{aktiver.mobil}}<br>
          <span @click="$refs.mobilDetails.show()"  class="btn-link">mehr</span>
        </p>

        <p>
          <b>Beruf</b><br>
          <b-input v-model="aktiver.beruf" :disabled="!aktiver.editable"/>
        </p>

        <p>
          <b>Kindergruppen</b><br>
          <b-input v-model="aktiver.gruppenName" :disabled="!aktiver.editable"/>
        </p>
      </b-col>
      <b-col cols="9">


        <differentGebDat v-if="hasDifferentGebDaten && aktiver.editable" :differentGebDat="hasDifferentGebDaten" :aid="aktiver.id" :isSaved="isSaved"  @splitted="abort"/>

        <b-alert show variant="info" v-if="aktiver.other != null && aktiver.other.length != 0">
          Es gibt mehrere Einträge mit den Namen "{{aktiver.vorname}} {{aktiver.nachname}}":
          <ul>
            <li v-for="other in aktiver.other">
              <router-link :to="{ name: 'viewAktiven', params: { aid: other.id }}">
                {{other.name}}
                <template v-if="other.geburtstag != null"> ({{new Date(other.geburtstag).toLocaleDateString("de-DE")}})</template>
                <span v-if="other.ort != null"> aus {{other.ort}}</span>
              </router-link>
            </li>
          </ul>
          <p v-if="aktiver.existsTruelyDublicate">
            <b>Dieser Eintrag wurde als wirklich doppelt markiert. <span class="btn-link" @click="unmarkAsTrueDub" v-if="aktiver.editable">(zurücksetzen)</span>)</b>
          </p>
          <p v-else>
            <template v-if="aktiver.editable">
              Falls es tatsächlich zwei Personen gibt, klicke bitte <span class="btn-link" @click="markAsTrueDub">hier</span>.
            </template>
          </p>
          <p v-if="aktiver.editable">
          Falls es sich um einen doppelten Eintrag für die gleiche Person handelt, solltest du einen der Beiden Einträge löschen. Die Seminaranmeldungen werden dabei automatisch auf den anderen Eintrag übertragen.<br>
          <b-button @click="delAktiven" variant="danger">Diesen Eintrag löschen</b-button>
          </p>
        </b-alert>

        <b-alert show variant="danger" v-if="aktiver.noMoreEmails">
          <b>Achtung:</b> {{aktiver.vorname}} hat angegeben nie wieder E-Mails von der NAJU erhalten zu wollen. Er/Sie kann das nur selber ändern, indem er/sie sich über die Website für einen Newsletter anmeldet.
        </b-alert>

        <h2>Seminaranmeldungen</h2>
        <b-table :items="aktiver.semanmeld" :fields='["seminar","jahr"]' striped @row-clicked="openSemanmdeld">
          <template v-slot:cell(seminar)="data">
            {{data.value.name}}
          </template>
          <template v-slot:cell(jahr)="data">
            {{data.item.seminar.year}}
          </template>
        </b-table>
        <h2>Wissenswertes</h2>
        <b-textarea v-model="aktiver.infos" rows="5" :disabled="!aktiver.editable"/>
        <br>
        <h2>Funktionen</h2>
        <aktivenFunktionEditor :aktiver="aktiver"/>

        <kindergruppen :aktiver="aktiver" />

        <criminalRecordCertificateEditor :aktiver="aktiver"/>

        <saveBtn ref="saveBtn" :saveable="aktiver" api="seminare/saveAktiven.php" :isSaved.sync="isSaved" @saved="abort" v-show="aktiver.editable" class="mx-1"/>
        <b-button @click="delAktiven" v-if="aktiver.semanmeld != null && aktiver.semanmeld.length == 0 && aktiver.editable" variant="danger" class="mx-1">Eintrag löschen</b-button>
        <b-button @click="abort" v-if="aktiver.editable" class="mx-1">Änderungen verwerfen</b-button>
        <b-button variant="info" v-if="aktiver.email != null && aktiver.editable" @click="email" class="mx-1">E-Mail schreiben</b-button>

        <aDetailsGeburtstag ref="gebDetails" :aktiver="aktiver" :hasDifferent.sync="hasDifferentGebDaten"/>
        <aDetails ref="emailDetails" :aktiver="aktiver" semanmeldField="email" label="Adressen" :enteredValue.sync="aktiver.enteredEmail" :enterDate.sync="aktiver.emailChanged" headline="E-Mail Adressen"
          :selectedValue.sync="aktiver.email">
          <template slot-scope="data">
            {{data.value}} <emailErrorChecker :email="data.value" :defektEmails="aktiver.defektEmails"/>
          </template>
        </aDetails>

        <aDetails ref="telDetails" :aktiver="aktiver" semanmeldField="festnetz" label="Nummer" :enteredValue.sync="aktiver.telefonEntered" :enterDate.sync="aktiver.telefonChanged" headline="Telefon (Festnetz)"
          :selectedValue.sync="aktiver.telefon" />
        <aDetailsAddress ref="addressDetails" :aktiver="aktiver"/>

        <aDetails ref="mobilDetails" :aktiver="aktiver" semanmeldField="mobil" label="Nummer" :enteredValue.sync="aktiver.mobilEntered" :enterDate.sync="aktiver.mobilChanged" headline="Handy"
          :selectedValue.sync="aktiver.mobil" />
        <aDetailsAddress ref="addressDetails" :aktiver="aktiver"/>
      </b-col>
    </b-row>
  </b-col>
</template>


<script>

import aDetails from './viewAktivenDetails.vue'
import aDetailsAddress from './viewAktivenDetailsAddress.vue'
import aDetailsGeburtstag from './viewAktivenDetailsGeburtstag.vue'
import saveBtn from '../saveBtn.vue'
import aktivenFunktionEditor from './aktivenFunktionEditor.vue'
import emailErrorChecker from './viewAktivenEmailErrorChecker.vue'
import differentGebDat from './viewAktivenDifferentGebDat.vue'
import aliases from './viewAktivenAliases.vue'
import criminalRecordCertificateEditor from "./aktivenCriminalRecordCertificateEditor.vue"
import kindergruppen from "./aktivenKindergruppen.vue"

import { mapState } from 'vuex'

export default {
  name: 'ViewAktiven',
  data(){
    return {
      aktiver: {kindergruppen:[]},
      hasDifferentGebDaten:false,
      isSaved:false
    };
  },
  components:{
    aDetails,
    aDetailsAddress,
    aDetailsGeburtstag,
    saveBtn,
    aktivenFunktionEditor,
    emailErrorChecker,
    differentGebDat,
    aliases,
    criminalRecordCertificateEditor,
    kindergruppen
  },
  props:[],
  methods:{
    loadAktiven(id){
      var t = this;
      $.get(this.$root.api_root + "seminare/getAktiven.php",{
        aid: id,
        incSemanmeld: true,
        incLogs:true
      },function(res){
        t.$root.checkRespForLoginFail(res);
        if(res.trim().indexOf("not found") == 0){
          alert("Eintrag nicht gefunden");
          t.aktiver = {};
          t.$refs.saveBtn.reset();
          return;
        }
        t.aktiver = JSON.parse(res);
        t.$refs.saveBtn.reset();
      });
    },

    abort(){
      this.loadAktiven(this.aktiver.id);
    },

    delAktiven(){
      var t = this;
      if(!confirm("Soll dieser Eintrag wirklich unwiederruflich gelöscht werden?"))
        return;
      $.post(this.$root.api_root + "seminare/get_deleteJugenddbAktiven.php",{
          id: this.aktiver.id
      },function(res){
        t.$root.checkRespForLoginFail(res);
        t.$router.push("/");
      });
    },
    openSemanmdeld(row){
      if(!this.user.canViewSeminare)
        return;

      if(!this.$refs.saveBtn.confirmLeave())
        return;
      //window.location = "?page=seminaranmeldungInfo&id=" + row.id;
      this.$router.push({ name: 'editSeminaranmeldung', params: { id: row.id } })
    },
    email(){
      this.$store.commit("setEmailRecipiants",[{
        name: this.aktiver.vorname + " " + this.aktiver.nachname,
        id: this.aktiver.id
      }]);
      this.$router.push({ name: 'email'});
    },

    markAsTrueDub(){
      this.setTrueDub(true);
    },
    unmarkAsTrueDub(){
      this.setTrueDub(false);
    },
    setTrueDub(set){
      var t = this;
      $.get(this.$root.api_root + "seminare/setTruelyDublicate.php",{
        vorname: this.aktiver.vorname,
        nachname: this.aktiver.nachname,
        action: set ? "set" : "unset"
      },function(res){
        t.$root.checkRespForLoginFail(res);
        t.$set(t.aktiver,"existsTruelyDublicate", set);
      });
    },

  },
  created(){
    this.loadAktiven(this.$route.params.aid);
    this.$store.dispatch('loadFunktionen')
    this.$store.dispatch("loadUser");
  },
  beforeRouteUpdate (to, from, next) {
    if(! this.$refs.saveBtn.confirmLeave()){
      next(false);
      return;
    }
    this.loadAktiven(to.params.aid);
    next();
  },
  beforeRouteLeave (to, from, next) {
    if(! this.$refs.saveBtn.confirmLeave()){
      next(false);
      return;
    }
    next();
  },
  computed:{
    funktionen(){
      if(this.aktiver.gruppen == null || this.$store.state.seminare.funktionen == null)
        return [];
      return this.aktiver.gruppen.map(gid => {
        return this.$store.state.seminare.funktionen[gid].name;
      });
    },
    freeFunction(){
      if(this.aktiver.gruppen == null || this.$store.state.seminare.funktionen == null)
        return [];
      return Object.values(this.$store.state.seminare.funktionen)
        .filter(x => this.aktiver.gruppen.indexOf(x) == -1)
        .sort((a,b) => a.name.localeCompare(b.name));
    },
    ...mapState({
      user: state => state.base.user,
    })
  }
}

</script>

<style scoped>
table{
  background: #fff;
}

</style>


