<template>
  <input type="text" name="searchfield" class="form-control" v-model="searchText" ref="search" @input="clear" @blur="blur" no-autocomplete autocomplete="off"/>
</template>


<script>



export default {
  name: 'SeachField',
  data(){
    return {
      searchText: "",
      wasJustSet:false
    };
  },
  props:{
    value:{ },
    selectedType:{ },
    selectedLabel:{ },
    resetOnFound:{type:Boolean, default: false},
    findNames:{type:Boolean, default: true},
    findSems:{type:Boolean, default: false},
    findGroups:{type:Boolean, default: false},
    findFlyer:{type:Boolean, default: false},
  },
  mounted(){
      var t = this;
      $(this.$refs.search).autocomplete({
          source: function( request, response ) {
              $.ajax( {
                  url: t.$root.api_root + "seminare/get_search.php",
                  data: {
                      startingWith: request.term,
                      findNames: t.findNames,
                      findSems: t.findSems,
                      findGroups: t.findGroups,
                      findFlyer: t.findFlyer
                  },
                  success: function( data ) {
                    t.$root.checkRespForLoginFail(data);
                    response( JSON.parse(data) );
                  }
              } );
          },
          select:function(event,ui){
            t.wasJustSet = true;
            t.$emit("input", ui.item.link.id);
            t.$emit("update:selectedType", ui.item.link.type);
            t.$emit("update:selectedLabel", ui.item.label);
            t.$emit("found", {name: ui.item.label, id: ui.item.link.id, type: ui.item.link.type});
            if(t.resetOnFound){
              t.searchText = null;
            }
          }
      });
  },
  beforeDestroy(){
    $(this.$refs.search).autocomplete("destroy");
  },
  methods:{
    clear(){
      this.$emit("input", null);
    },
    blur(){
      if(this.value == null)
        this.searchText = null;
    },
    fetchLabel(){
      var t = this;
      if(this.value == "" || this.value == null || this.selectedType == "" || this.selectedType == null )
        return;
      if(this.selectedType == "seminar"){
        $.get(this.$root.api_root + "seminare/getSeminar.php",{
          sid:this.value
        },function( data ) {
            t.$root.checkRespForLoginFail(data);
            data = JSON.parse(data);
            t.searchText = data.title + " (" + data.year + ")";
        });
      }
    }
  },
  watch:{
    selectedLabel(l){
      this.searchText = l;
    },
    value(){
      if(this.wasJustSet){
        this.wasJustSet = false;
        return;
      }
      this.fetchLabel();
    }
  },
  created(){
    this.fetchLabel();
  }
}

</script>

<style scoped>


</style>


