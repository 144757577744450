export default {
  state: {
    user: {
      id: null,
      name:  "",
      canViewSeminare:false,
      canEditSeminare: false,
      canViewStock: false,
      canEditStock: false,
      canFlyer: false,
      canEditKindergruppen: false,
      isFunktionOwner: 0,
      ownedFunctions:[],
      isAdmin: false,
      isPwEditable: false,
      noBgImage: false,
      ownProjects: [],
      readProjects: [],
      ownSeminare: [],
      canEditFundMonitor: false,
      canViewFundMonitor: false,
      logedIn: false,
      canInsta: false,
      canPoll: false,
    },
  },
  mutations: {
    setUser(state, user){
       state.user = user;  
    }
  },
  actions: {
    loadUser({ commit, state }, force = false){
      var t = this;
      if(state.user.id != null && !force)
       return;
      $.get(api + "getCurrentUseInfo.php",{
      },function(res){
        t.checkRespForLoginFail(res);
        commit("setUser", JSON.parse(res));
      });
    },
    logedOut({ commit, state }){
      commit("setUser", {logedIn: false});
    }
  }
}
