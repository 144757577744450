<template>
  <b-col class="col-lg-8 col-xl-6 col-12 offset-lg-2">
    <h1>
      {{year.feederbowl.name}} {{year.name}}
      <router-link :to="{ name: 'editFeederbowelYear', params: { id: year.id }}"><b-button>Bearbeiten</b-button></router-link>
    </h1>
    <p class="description" v-html="year.description" />
    <h3>Zahlen</h3>
    Fördersummer: {{formatPrice(year.amount)}}€<br>
    Einnehmen / Spende: {{formatPrice(year.revenues)}}€<br>
    Fördersumme + Einnahmen: {{formatPrice(year.amount + year.revenues)}}€<br><br>
    Bisher ausgegeben (incl. Eigenanteil): {{formatPrice(year.spent)}}€<br>
    Eigenanteil: {{100-year.percentage}}%<br>
    Eigenanteil bisher: {{formatPrice(year.deducible)}}€<br><br>
    Verbleibend: {{formatPrice(year.amount + year.revenues - (year.spent * year.percentage/100))}}€
    <h3>Buchungen</h3>
    <b-table :items="entries" :fields="[
      {key:'name', sortable:'true'},
      {key:'subfeederbowl', label:'Unterfördertopf', sortable:'true'},
      {key:'price', label:'Preis', sortable:'true'}]">
        <template v-slot:cell(name)="data">
          <template v-if="data.item.isDeficit == null">
            <template v-if="data.item.isDeducible > 0">Eigenanteil: </template><router-link :to="{ name: 'editFundEntry', params: { id: data.item.id }}">{{data.value}}</router-link>
          </template>
          <template v-else>{{data.value}}</template>
        </template>
        <template v-slot:cell(price)="data"><template v-if="data.item.isGuess">Schätzung: </template>{{formatPrice(data.value)}} €</template>
    </b-table>


  </b-col>
</template>

<script>

import { mapState } from 'vuex'


export default {

  name: 'ShowFeederbowlYear',
  data(){
    return{
      year: {name: "", entries:[], feederbowl:{name: ""}}
    }
  },
  components:{

  },
  created(){
    this.loadYear(this.$route.params.id, this.$route.query);
  },
  methods:{
    loadYear(id){
      var t = this;
      $.get(this.$root.api_root + "fundmonitor/getFeederbowlYear.php",{
          id: id,
          incEntries:true
        },function(res){
          t.$root.checkRespForLoginFail(res);
          t.year = JSON.parse(res);
      });
    },
    formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.loadYear(to.params.id, to.query);
    next();
  },
  computed:{
    entries(){
      return this.year.entries.map(e => {
            e._rowVariant = e.isGuess ? "guess" : "";
            return e;
      });
    }
  }
}
</script>

<style scoped>

>>> table{
    background: #fff;
  }

  >>> .table-guess{
  color:#666;
}


  .description{

  }

</style>
