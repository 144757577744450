<template>
  <div class="queryGroup">
    <b-button-group class="float-right">
      <b-button size="sm" variant="success" @click="addRule" :disabled="disabled">+ Regel</b-button>
      <b-button size="sm" variant="success" @click="addGroup" :disabled="disabled">+ Gruppe</b-button>
      <b-button size="sm" variant="danger" v-if="!isToplevel" @click="$emit('deleted')" :disabled="disabled"><span class="oi oi-trash"></span></b-button>
    </b-button-group>
    <b-form-group label="">
      <b-form-radio-group
        id="btn-radios-1"
        v-model="rules.condition"
        :options="condOptions"
        buttons
        button-variant="primary"
        size="sm"
        name="radios-btn-default"
        :disabled="disabled"
      ></b-form-radio-group>
    </b-form-group>
    <template v-for="subRule, index in rules.rules">

        <QueryBuilder v-if="subRule.condition != null" :rules="subRule" :isToplevel="false" :filters="filters" @deleted="rules.rules.splice(index,1)" :disabled="disabled">
          <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
        </QueryBuilder>

        <div class="filter" v-else>
          <b-row>
            <b-col cols="4">
              <b-form-select v-model="subRule.field" :options="filterOptions" :disabled="disabled"></b-form-select>
            </b-col>

            <b-col cols="7" >
              <template v-if="filters[subRule.field] != null">
                <slot :name="subRule.field" v-bind:rule="subRule" v-bind:disabled="disabled">
                  <b-form-select v-if="filters[subRule.field].options != null" v-model="subRule.value" :options="filters[subRule.field].options" :disabled="disabled"></b-form-select>
                </slot>
              </template>
            </b-col>
            <b-col cols="1">
              <b-button size="sm" variant="danger" @click="rules.rules.splice(index,1)"><span class="oi oi-trash"></span></b-button>
            </b-col>
          </b-row>
        </div>
    </template>
  </div>
</template>


<script>
//Rule Syntax and Style inspired by jquery query builder
export default {
  name: 'QueryBuilder',
  components:{

  },
  props:{
    "rules":{type:Object},
    "filters":{},
    "disabled":{type: Boolean, default: false},
    "isToplevel":{type:Boolean, default:true}
  },
  data(){
    return {
      condOptions:[
         { text: 'UND', value: 'AND' },
         { text: 'ODER', value: 'OR' },
      ],
    }
  },
  methods:{
    addRule(){
      this.rules.rules.push(
        JSON.parse('{"field":"", "value":""}')
      );
    },
    addGroup(){
      this.rules.rules.push(
        JSON.parse('{"condition":"AND","rules":[{"field":"", "value":""}]}')
      );
    }
  },
  computed:{
    filterOptions(){
      var ret = [{value:null, text: '---'},
        ...Object.values(this.filters).map(f => {
          return {
            value: f.id, text:f.label
          };
        })
      ];

      return ret;
    }
  },
  created(){
    if(this.rules.rules == null){
      this.$set(this.rules,"rules",[]);
    }
    if(this.rules.condition == null){
      this.$set(this.rules,"condition","AND");
    }
  }
}
</script>

<style scoped>
  .queryGroup{
    padding: 12px;
    padding-bottom: 6px;
    border: 1px solid #dcc896;
    background: rgba(250,240,210,.5);
    border-radius:5px;
    margin-bottom: 7px;
  }

  .filter{
    margin: 6px 0;
    border-radius: 5px;
    padding: 5px;
    border: 1px solid #eee;
    background: rgba(255,255,255,.9);
  }
</style>
