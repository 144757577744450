<template>
  <b-button variant="primary" :pressed="anmeldung.bezahlt != null" :disabled="pending" @click="clicked">
    {{anmeldung.bezahlt == null ? "Nein" : anmeldung.bezahlt}}
  </b-button>
</template>


<script>



export default {
  name: 'BezahltBtn',
  data(){
    return {
      pending: false
    };
  },
  props:["anmeldung"],
  methods:{
    clicked(){
      var t = this;
      t.pending = true;
      $.get(this.$root.api_root + "seminare/get_setbezahlt.php",{
        id: t.anmeldung.id,
        value: t.anmeldung.bezahlt == null //invertiert da geändert werden soll
      },
      function(data){
        t.$root.checkRespForLoginFail(data);
        if(data.includes("error")){
            alert(data);
            return;
        }
        t.anmeldung.bezahlt = (data.trim() == "null" ? null : data);
        t.pending = false;
      });
    }
  }

}

</script>

<style scoped>


</style>


