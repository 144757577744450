<template>
  <b-col cols="6" offset="2">
    <h1>Erweiterte Suche</h1>

        <div class="form-group row">
            <div class="col-md-4">
                <b-button @click="resetFilter()" class="my-1">Zurücksetzten</b-button>
                <b-dropdown text="Suche laden" class="mx-1 my-1">
                  <b-dropdown-item v-for="avsearch in avSearches" @click="loadSearch(avsearch)">{{avsearch}}</b-dropdown-item>
                </b-dropdown>
            </div>


            <div class="col-md-8">
                <b-input-group>
                    <b-input v-model="searchName" />
                    <b-input-group-append>
                      <b-button variant="success" @click="saveSearch">{{saveBtnText}}</b-button>
                      <b-button variant="danger" v-if="currentSearchExists" @click="delSearch">Löschen</b-button>
                    </b-input-group-append>
                </b-input-group>
            </div>

        </div>

        <queryBuilder :rules="rules" :filters="filters" :disabled="searchPending">
          <template v-slot:seminaranmeldung="data">
            <search :findNames="false" :findSems="true" v-model="data.rule.value" selectedType="seminar" placeholder="Seminar suchen" :disabled="data.disabled"/>
          </template>

          <template v-slot:seminaranmeldungYear="data">
            <b-input v-model="data.rule.value"  placeholder="z.B. 1910" :disabled="data.disabled"/>
          </template>

          <template slot="location" slot-scope="data">
            <locationFilter v-model="data.rule.value" :disabled="data.disabled"/>
          </template>
          <template v-slot:age="data">
            <ageFilter v-model="data.rule.value" :disabled="data.disabled"/>
          </template>
          <template v-slot:crimerecord="data">
            <b-form-select v-model="data.rule.value" class="">
              <option value="">Benötigt</option>
              <option value="missing">Benötigt + fehlt (noch nie abgegeben)</option>
              <option value="older2.5">Benötigt + fehlt oder älter als 2,5 Jahre</option>
              <option value="older3">Benötigt + fehlt oder älter als 3 Jahre</option>
              <option value="older5">Benötigt + fehlt oder älter als 5 Jahre</option>
            </b-form-select>
          </template>
        </queryBuilder>
        <div class="form-group row my-2">
            <div class="col-md-10">
                <b-button variant="primary" @click="runFilter" :disabled="searchPending">{{searchPending ? "Wird gesucht..." : "Suchen"}}</b-button>
                <b-button @click="exportCsv(false)" :disabled="searchPending" class="mx-1 my-1">Adressen als csv</b-button>
                <b-button @click="exportCsv(true)" :disabled="searchPending" class="mx-1 my-1">Datei für Etikettendrucker</b-button>
                <b-button @click="email" :disabled="searchPending || result.length == 0" class="mx-1 my-1">E-Mail</b-button>
            </div>
        </div>
        <br>
        <p>{{result.length}} Einträge gefunden</p>
        <b-alert variant="warning" :show="incompleteAddress.length > 0">
          <b>Achtung</b> Folgende Treffer haben keine Adresse angegeben:<br>
          <ul>
            <li v-for="a in incompleteAddress"><router-link :to="{ name: 'viewAktiven', params: { aid: a.id }}">{{a.vorname}} {{a.nachname}}</router-link></li>
          </ul>
        </b-alert>
        <b-table :items="result" :fields="fields" striped>
          <template v-slot:cell(adresse)="data">
            {{data.item.strasse}}<br>
            {{data.item.plz}} {{data.item.ort}}
          </template>
        </b-table>
        <b-modal ref="etikettenModal" title="Etiketten drucken" ok-only>
          <b>Download wurde gestartet!</b><br>
          <p>
            Weiteres Vorgehen:
            <ol>
            <li>Drucker anschalten</li>
            <li><a href="http://etiketten/" target="_blank">http://etiketten</a> aufrufen</li>
            <li>"Textdatei mit Textrennzeichen" auswählen, Datei hochladen, prüfen und drucken</li>
            </ol>
          </p>
        </b-modal>
  </b-col>
</template>

<script>
import { mapState } from 'vuex'
import queryBuilder from "./queryBuilder.vue"
import search from "./search.vue"
import locationFilter from "./advSearchLocationFilter.vue"
import ageFilter from "./advSearchAgeFilter.vue"


export default {
  name: 'AdvSearch',
  components:{
    queryBuilder,
    search,
    locationFilter,
    ageFilter
  },
  data(){
    return {
      seminare: [],
      result:[],
      fields:[
        {key:"vorname", sortable:true },
        {key:"nachname", sortable:true},
        {key:"adresse"}
      ],
      rules:{},
      avSearches:[],
      searchName: "",
      saved:false,
      searchPending: false
    }
  },
  methods:{


    runFilter(){
      var t = this;
      var query = this.rules;
      t.searchPending = true;
      if(query == null)
        return;
      $.get(this.$root.api_root + "seminare/get_advSearch.php",
      {
          query: JSON.stringify(query)
      },
      function(resp){
          t.searchPending = false;
          t.$root.checkRespForLoginFail(resp);
          try{
          resp = JSON.parse(resp);
          console.log(resp.sql)
          //showTable(resp.result);
          t.result = resp.result;
          }catch(e){
            t.result = [];
          }
      });
    },
    exportCsv(etiketten){
      window.open(this.$root.api_root + "seminare/get_advSearch.php?csvExport=true&incAddress=true&etiketten=" + (etiketten ? "true"  :"false") + "&query=" + encodeURIComponent(JSON.stringify(this.rules)));
      if(etiketten)
        this.$refs.etikettenModal.show();
    },

    loadSearch(name){
      var t = this;
      $.get(this.$root.api_root + "seminare/advSearchGetFilter.php",{
        name: name
      },function(res){
        t.$root.checkRespForLoginFail(res);
        t.$set(t,"rules", JSON.parse(res).rules);
        t.searchName = name;
        t.runFilter();
      });
    },

    delSearch(){
      var t = this;
      $.get(this.$root.api_root + "seminare/advSearchDeleteFilter.php",{
        name: this.searchName,
      },function(res){
        t.$root.checkRespForLoginFail(res);
        t.searchName = "";
        t.$set(t,"rules",{rules:[],condition:"AND"});
        t.fetchSearches();
      });
    },

    saveSearch(){
      var t = this;
      $.post(this.$root.api_root + "seminare/advSearchSaveFilter.php",{
        name: this.searchName,
        rules: JSON.stringify(this.rules)
      },function(res){
        t.$root.checkRespForLoginFail(res);
        t.saved = true;
        t.fetchSearches();
      });
    },
    fetchSearches(){
      var t = this;
      $.get(this.$root.api_root + "seminare/advSearchGetFilters.php",{},function(res){
        t.$root.checkRespForLoginFail(res);
        t.avSearches = JSON.parse(res);
      });
    },
    resetFilter(){
      this.rules = {rules:[],condition:"AND"};
    },
    email(){
      var t = this;
      this.$store.commit("setEmailRecipiants",this.result.map(a => {return {
        name: a.vorname + " " + a.nachname,
        id:a.id,
      }}));
      this.$router.push({ name: 'email'});
    }

  },
  created(){
    var t = this;
    $.get(this.$root.api_root + "seminare/get_seminarliste.php",{
    },function(res){
      t.$root.checkRespForLoginFail(res);
      t.seminare = JSON.parse(res);
      t.seminare.sort(function(a, b){
        var yearDiff = a.syear - b.syear;
        if(yearDiff != 0)
          return yearDiff;
      });

    });

    this.fetchSearches();

    this.$store.dispatch('loadFunktionen');
  },
  mounted(){
    var t = this;

  },
  computed:{
    ...mapState({
      funktionen: state => state.seminare.funktionen,
    }),
    incompleteAddress(){
      return this.result.filter(r => r.strasse == "" || r.ort == "" || r.plz == "" || r.strasse == null || r.ort == null || r.plz == null);
    },
    filters(){
      return {
        "function": {
          id:"function",
          label:"Funktion",
          options: this.funktionen == null ? [] : Object.values(this.funktionen).map(f => {
            return {
              value: f.id,
              text: f.name
            };
          })
        },
        "seminaranmeldung":{
          id: "seminaranmeldung",
          label: "Teilnahme an Seminar"
        },
        "seminaranmeldungYear":{
          id: "seminaranmeldungYear",
          label: "Teilnahme an Seminar im Jahr"
        },
        "location":{
          id: "location",
          label: "Im Umkreis von "
        },
        "hasKigru":{
          id: "hasKigru",
          label: "Leitet / betreut eine Kindergruppe"
        },
        "age":{
          id: "age",
          label: "Alter"
        },

        "crimerecord": {
          id: "crimerecord",
          label: "Führungszeugnis"
        }
      }
    },
    avSearchesLowercase(){
      return this.avSearches.map(s => s.toLowerCase());
    },
    currentSearchExists(){
      return this.avSearchesLowercase.includes(this.searchName.toLowerCase());
    },
    saveBtnText(){
      if(this.saved)
        return "Gespeichert";
      if(this.currentSearchExists)
        return "Überschreiben";
      return "Speichern"
    }
  },
  watch:{
    rules:{
      deep:true,
      handler(){
        this.saved = false;
      }
    }
  }
}

</script>

<style scoped>
>>> .rules-group-container{
  width: 100%;
}

table{
  background: #fff;
}

</style>
