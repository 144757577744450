<template>
  <span>{{datestr}}</span>
</template>

<script>
var idCtr = 0;
export default {
  name: 'DateViewer',
  props:{
    "dateFrom":{},
    "dateTo":{default: null},
    showTime:{default: false}
  },
  data(){
    return {
    };
  },
  
  computed:{
    datestr(){
        if(this.dateTo != null){
            var ret = "";
            if(this.moment(this.dateFrom).format("MM.Y") == this.moment(this.dateTo).format("MM.Y") && !this.showTime){
                ret = this.moment(this.dateFrom).format("DD.");
            }else if(this.moment(this.dateFrom).format("Y") == this.moment(this.dateTo).format("Y")){
                ret = this.moment(this.dateFrom).format("DD.MM.");
            }
            else{
                ret = this.moment(this.dateFrom).format("DD.MM.Y");
            }
            
            if(this.showTime){
                ret += " (" + this.moment(this.dateFrom).format("HH") + " Uhr)";
            }
            
            ret += " – " + this.moment(this.dateTo).format("DD.MM.Y");
            
            if(this.showTime){
                ret += " (" + this.moment(this.dateTo).format("HH") + " Uhr)";
            }
            
            return ret;
        }
        else if(this.dateFrom != null){
            var ret = this.moment(this.dateFrom).format("DD.MM.Y");
            if(this.showTime){
                ret += " (" + this.moment(this.dateFrom).format("HH") + " – " + this.moment(this.dateTo).format("HH") +  " Uhr)";
            }
            return ret;
        }
        return "";
    }
  }
}
</script>
