<template>
  <b-col cols="8" offset="1">
    <h1>{{seminar.title}}
      <template v-if="user.canEditSeminare">(<router-link :to="{ name: 'editSeminar', params: { aid: seminar.id }}">bearbeiten</router-link>)</template></h1>
    <ColSelectTable :cols="cols">
      <template slot-scope="selected">
        <br><br>
        <b-button-group>
          <b-button variant="primary"  :pressed.sync="showHidden">Ausgeblendete anzeigen</b-button>
        </b-button-group>
        <br><br>
        <b-alert :show="hasNoFahregm" variant="warning">
          <b>Achtung:</b> Dieses Seminar enthält Anmeldungen die keine Erlaubnis für das Versenden der daten für Fahrgemeinschaften abgegeben haben.<br>
          <b-form-checkbox  v-model="filterFahrgem">Anmeldungen ohne Erlaubnis für Fahrgeminschaftsliste verstecken</b-form-checkbox><br>
          <b-form-checkbox  v-model="filterFahrgemUnverschl" v-if="filterFahrgem">Anmeldungen ohne Erlaubnis für unverschlüsste Fahrgeminschaftsliste verstecken</b-form-checkbox>
        </b-alert>

        <TableCopyBtn :table="$refs.semTable" :fields="cols"/>
        <vue-context ref="menu">
            <ul>
                <li @click="">Bearbeiten</li>
                <li @click="">Ausblenden</li>
                <li @click="">Einblenden</li>
            </ul>
        </vue-context>
        Es werden {{filterdAnmeldungen.length}} Anmeldungen angezeigt<br>
        <b-table ref="semTable" striped bordered hover :items="filterdAnmeldungen" :fields="selected.cols" @row-contextmenu="console.log('134')"   >
          <template v-slot:cell(name)="data">
            <router-link :to="{ name: 'viewAktiven', params: { aid: data.item.aktiver }}">{{data.value}}</router-link>
          </template>

          <template v-slot:cell(mitglied)="data">
            {{data.value ? "Ja" : "Nein"}}
          </template>
          <template v-slot:cell(sex)="data">
            {{sexName(data.value)}}
          </template>
          <template v-slot:cell(bezahlt)="data">
            <BezahltBtn :anmeldung="data.item"/>
          </template>
          <template v-slot:cell(fahrgemeinschaften)="data">
            {{fahrgemText(data.item)}}
          </template>
          <template v-slot:cell(anmeldedatum)="data">
            {{new Date(data.value).toLocaleDateString("de-DE")}}
          </template>
          <template v-slot:cell(action)="data">
            <b-dropdown id="ddown1" text="Aktionen" class="m-md-2">
              <b-dropdown-item @click="edit(data.item)">Bearbeiten</b-dropdown-item>
              <b-dropdown-item @click="toggleHidden(data.item)">{{data.item.hidden ? "Einbelnden" : "Ausblenden"}}</b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </template>

    </ColSelectTable>
    <b-button variant="info" @click="email">E-Mail schreiben</b-button>

    <b-button :pressed.sync="showStatistics">Statistiken</b-button>
    <a :href="$root.api_root + 'seminare/genBjrList.php?sid=' + seminar.id" target="_blank"><b-button>BJR Liste erstellen</b-button></a>
    <br><br>
    <SeminarStatistics :seminar="seminar" v-if="showStatistics"/>
  </b-col>

</template>



<script>


import ColSelectTable from './colSelectTable.vue'
import BezahltBtn from './bezahltBtn.vue'
import TableCopyBtn from './tableCopyBtn.vue'
import SeminarStatistics from './seminarStatistics.vue';
import { VueContext } from 'vue-context';
import { mapState } from 'vuex'

export default {
  name: 'SeminarInfo',
  data(){
    var t = this;
    return {
      seminar:{
        title:"",
        start: null,
        end:null,
        year:null,
        anmeldungen:[]
      },
      cols:[
        {key: "sex", label:"Geschlecht", checked:false, sortable:true, selectable:true},
        {key: "name", label:"Name", checked:true, sortable:true, selectable:false},
        {key: "adresse", label:"Adresse", checked:true, sortable:true},
        {key: "ort", label:"Ort", checked: false, sortable: true},
        {key: "telefon", label: "Telefon", checked: true},
        {key: "email", label: "E-Mail", checked: true},
        {key: "alter", label:"Alter", checked: true, sortable:true},
        {key: "gebdatum", label:"Geburtsdatum", sortable: true},
        {key: "anmeldedatum", label:"Anmeldedatum", checked: false, sortable: true},
        {key: "kommentar", label:"Kommentar", checked: false},
        {key: "mitglied", label:"Mitglied", checked: false, sortable: true},
        {key: "verpflegung", label:"Verpflegung", checked: false, sortable: true},
        {key: "bezahlt", label:"Bezahlt", checked: false, sortable:true},
        {key: "fahrgemeinschaften", label:"Fahrgemeinschaften", checked: false, sortable:true},
        {key: "action", label:"Aktionen", checked: t.editable, sortable:false, selectable:false},
      ],
      showHidden: false,
      filterFahrgem: false,
      filterFahrgemUnverschl: false,
      showStatistics: false
    };
  },
  components:{
    ColSelectTable,
    BezahltBtn,
    TableCopyBtn,
    VueContext,
    SeminarStatistics
  },
  created(){
    this.loadSeminar(this.$route.params.sid);
    this.$store.dispatch("loadUser");
    this.cols[this.cols.length - 1].checked = this.user.canEditSeminare;
  },
  methods: {
    loadSeminar(sid){
      var t = this;
      $.get(this.$root.api_root + "seminare/getSeminar.php",{
        sid:sid,
        incAnmeldungen: "true"
      },function(res){
        t.$root.checkRespForLoginFail(res);
        if(res == "not found"){
          t.data = null;
          alert("Seminar nicht gefunden");
          return;
        }
        t.seminar = JSON.parse(res);
      });
    },
    fahrgemText(item){
      if(item.fahrgemeinschaften){
        if(item.unverschluesselt)
          return "Ja";
        return "Ja, verschlüsselt";
      }
      return "Nein";
    },
    ctxMen(item, index, event){

      console.log(e.which);
      this.$refs.menu.open(event);
    },
    edit(item){
      //window.location = "?page=seminaranmeldungInfo&id=" + item.id + "&backlink=" + encodeURIComponent(window.location);
      this.$router.push({ name: 'editSeminaranmeldung', params: { id: item.id } })
    },
    toggleHidden(item){
      var t = this;
      item.hidden = !item.hidden;
      $.get( t.$root.api_root + "seminare/get_seminaranmeldung_hidden.php",{id: item.id, hidden:item.hidden},
      function(res){
        t.$root.checkRespForLoginFail(res);
      });
    },
    email(){
      this.$store.commit("setEmailRecipiants",this.filterdAnmeldungen.map(a => {return {
        id: a.aktiver,
        name: a.name
      }}));
      this.$router.push({ name: 'email'});
    },
    sexName(sex){
      switch(sex){
        case 'm': return "Männlich";
        case 'f': return "Weiblich";
        case 'd': return "Divers";
      }
    }
  },
  beforeRouteUpdate(to, from, next){
    this.loadSeminar(to.params.sid);
    next();
  },

  computed:{

    markHidden(){
      return this.seminar.anmeldungen.map(each => {
        each._rowVariant = each.hidden ? "hidden" : "";
        return each;
      });
    },

    hiddenFiltered(){
      if(this.showHidden)
        return this.markHidden;
      var ret =this.markHidden.filter(x => !x.hidden);
      return ret;
    },

    hasNoFahregm(){
      return this.hiddenFiltered.some(a => ! a.fahrgemeinschaften);
    },

    filterdAnmeldungen(){
      var t = this;
      if(!this.hasNoFahregm || !this.filterFahrgem)
        return this.hiddenFiltered;
      return this.hiddenFiltered.filter(x => {
        if(!x.fahrgemeinschaften)
          return false;
        if(!t.filterFahrgemUnverschl)
          return true;
        return x.unverschluesselt;
      });
    },
    ...mapState({
      user: state => state.base.user,
    }),
  },
  watch:{
    "user.canEditSeminare"(v){
        console.log(v);
        this.cols[this.cols.length - 1].checked = v;
      }
  }

}

</script>

<style scoped>
table{
  background: #fff;
}

>>> .table-hidden{
  color:gray;
}

</style>

