<template>
  <input type="text" class="form-control" v-model="searchText" ref="search" @input="clear" @blur="blur" no-autocomplete autocomplete="off"/>
</template>


<script>



export default {
  name: 'RegionInput',
  data(){
    return {
      searchText: "",
    };
  },
  props:{
    value:{ },
    resetOnFound:{type:Boolean, default: false},
  },
  mounted(){
      var t = this;
      $(this.$refs.search).autocomplete({
          source: function( request, response ) {
              $.ajax( {
                  url: t.$root.api_root + "flyer/regionSearch.php",
                  data: {
                      search: request.term
                  },
                  success: function( data ) {
                    t.$root.checkRespForLoginFail(data);
                    response( JSON.parse(data) );
                  }
              } );
          },
          select:function(event,ui){
            t.$emit("input", {id: ui.item.link, label:ui.item.label});
            if(t.resetOnFound){
              t.searchText = null;
            }
          }
      });
  },
  beforeDestroy(){
    $(this.$refs.search).autocomplete("destroy");
  },
  methods:{
    clear(){
      this.$emit("input", {id: null, label:""});
    },
    blur(){
      if(this.value.id == null)
        this.searchText = "";

    },
  },
  created(){
    if(this.value != null && this.value.hasOwnProperty("label"))
      this.searchText = this.value.label
  },
  watch:{
    value(){
      if(this.searchText == "")
        this.searchText = this.value.label
    }
  }
}

</script>

<style scoped>


</style>


