<template>
  <tbody>
    <template v-for="row in rowsFlattened">
      <tr v-if="row.pendingRow">
        <td>{{"&nbsp;".repeat(row.depth*4)}}<i>Wird geladen...</i></td>
      </tr>
      <tr v-else :depth="row.depth" v-bind:class="{hasChildren: row.y1 != null && row.y1.hasChildren}">
        <td class="name" @click="toggleRow(row)">{{"&nbsp;".repeat(row.depth*4) + row.name}}</td>
        <tableTd v-model="row.y1.in" v-if="!totalOnly" />
        <tableTd v-model="row.y2.in - row.y1.in" v-if="!totalOnly && compare" :colorize="true"/>
        <tableTd v-model="row.y2.in" v-if="!totalOnly && compare" />

        <tableTd v-model="row.y1.out * -1" v-if="!totalOnly" />
        <tableTd v-model="row.y2.out * -1 - row.y1.out * -1" v-if="!totalOnly && compare" :colorizeInv="true"/>
        <tableTd v-model="row.y2.out * -1" v-if="!totalOnly && compare" />

        <tableTd v-model="row.y1.value" :colorize="true" />
        <tableTd v-if="compare" v-model="row.y2.value - row.y1.value"  :colorize="true"/>
        <tableTd v-if="compare" v-model="row.y2.value" :colorize="true" />
      </tr>
    </template>
  </tbody>
</template>
<script>
import { mapState } from 'vuex'

import tableTd from "./tableTd.vue"

export default {
  name: 'TableContent',
  props:{"projekt":{}, "baseYear":{}, "compareYear":{}, "compare":{}, "totalOnly":{}, "compare":{}, "root":{default: ""} },
  data(){
    return{
      rows: [],
      regenCtr : 0,
    }
  },
  components:{
    tableTd
  },
  methods:{
    fetchRoot(){
      var t = this;
      this.rows = [];

      t.fetchRows("",function(res){
        t.rows = res;
      });
    },

    fetchRows(root, cb){
      var t = this;
      if(this.projekt == null){
        return;
      }
      $.get(this.$root.api_root + "chartJson.php?root=" + root,{
          "type":"bil",
          "year": this.baseYear,
          "project": this.projekt,
          "kartGroup":"true",
          "compare":this.compare ? this.compareYear : "",
          "newformat":true
      },function(res){
        t.$root.checkRespForLoginFail(res);
        cb(JSON.parse(res).years);
      });
    },

    toggleRow(row){
      console.log("toggle");
      if(!row.y1.hasChildren)
        return;
      if(row.children == undefined || row.children == null){
        this.openRow(row);
      }
      else{
        row.children = null; //close row
        //this.regenCtr++;
      }
    },

    openRow(row){
      var t = this
      t.$set(row, "pending", true);
      this.fetchRows(row.dbid,function(res){
        t.$set(row, "children", res);
        t.$set(row, "pending", false);
      });
    }
  },
  watch:{
    projekt(){
      this.fetchRoot();
    },
    baseYear(){
      this.fetchRoot();
    },
    compareYear(){
      this.fetchRoot();
    }
  },
  created(){
    this.fetchRoot();
  },
  computed:{
    rowsFlattened(){
      console.log("flatten");
      var ret = [];
      var flattener = function(rows, depth = 0){
        console.log(rows);
        Object.values(rows).forEach(row => {
          row.depth = depth;
          ret.push(row);
          if(row.pending){
            ret.push({pendingRow: true, depth: depth + 1});
          }
          if(row.children != null){
            flattener(row.children, depth + 1);
          }
        });
      }
      flattener(this.rows);
      return ret;
    }

  }
}
</script>

<style scoped>
  tr{
    font-family: "Source Sans Pro"
  }

  tr[depth = '0'] .name{
    font-weight: 700;
  }
  tr[depth = '1'] .name{
    font-weight: 600;
  }
  tr[depth = '3'] .name{
    font-weight: 300;
  }

  tr.hasChildren .name{
    cursor: pointer;
  }

  .currency{
    text-align: right;
  }
</style>


