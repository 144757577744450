<template>
  <b-col cols="6" offset=2>
    <h1>Funktionen</h1>
    <b-table :items="Object.values(funktionen)" :fields="[
      {key:'name', sortable:true},
      {key:'numMember', label:'Mitglieder', sortable:true}
    ]">
      <template v-slot:cell(name)="data"><router-link :to="{ name: 'viewFunktion', params: {fid: data.item.id}}">{{data.value}}</router-link></template>
    </b-table>
    <router-link :to="{ name: 'editFunktion', params:{fid:'new'}}"><b-button variant="success" class="my-2">Neu</b-button></router-link>
  </b-col>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Funktionen',
  data(){
    return {
    };
  },
  components:{

  },
  created(){
    this.$store.dispatch('loadFunktionen');
  },
  computed:{
    ...mapState({
      funktionen: state => state.seminare.funktionen,
    })
  },
  methods:{

  },

}

</script>


<style scoped>


table{
  background: #fff;
}

 </style>
