<template>
  <div>
    <p>
      <b>Auch bekannt als:</b>
      <template v-for="alias in aktiver.aliases"><br>{{alias.vorname | capitalize}} {{alias.nachname | capitalize}}</template>
      <br><span @click="showModal = true" class="btn-link" v-if="aktiver.editable">mehr</span>
    </p>

    <b-modal title="Aliase bearbeiten" v-model="showModal" ok-only>
      <b-table :items="aktiver.aliases" :fields="cols">
        <template v-slot:cell(action)="data">
          <b-button variant="danger" @click="rm(data.item)"><span class="oi oi-trash"></span></b-button>
        </template>

        <template v-slot:bottom-row="data">
          <td><b-input placeholder="Vorname" v-model="newVorname"/></td>
          <td><b-input placeholder="nachname" v-model="newNachname"/></td>
          <td><b-button @click="add" variant="success">+</b-button></td>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>


<script>


export default {
  name: 'ViewAktiveAliases',
  components:{

  },
  data(){
    return {
      showModal: false,
      cols:[
        {key: "vorname"},
        {key: "nachname"},
        {key: "action", label: "Aktion"}
      ],
      newVorname: "",
      newNachname: ""
    };
  },
  props:["aktiver"],
  methods:{
    add(){
      this.aktiver.aliases.push({
        vorname:this.newVorname,
        nachname: this.newNachname
      });
      this.newVorname = "";
      this.newNachname = "";
    },
    rm(item){
      this.aktiver.aliases.splice(this.aktiver.aliases.indexOf(item),1);
    }
  },
  computed:{

  }
}

</script>


