<template>
  <div id="titlepageWrapperBox" v-bind:class="{withBjrLogo: page.options != null && page.options.showBjrLogo}">
    <dragableImage v-model="page.images.image1" fullHeight="true" :hq="true"></dragableImage>
    <img src="../poster/abriss.png" id="abriss"/>
    <div id="anschnitt"></div>
    <div id="contentBox">
      <FlyerTextBox class="mainText" :value="page.text" :rightOriented="page.rightOriented" :fullWidth='true'
          :fullHeight='false' :result="result" />
    <template v-if="page.options.otherSems != null && page.options.otherSems.length != 0">
        <p id="otherSemsText" class="my-2">Du hast Lust auf noch mehr Freizeiten bei der NAJU?<br>
        Bei diesen Veranstaltungen sind auch <b>noch Plätze frei:</b>
        </p>
        <otherSemViewer :otherSems="page.options.otherSems"/>
    
    
    </template>
    
    <p id="wsLinkBox" class="my-2">
    <b>Weitere Informationen und Veranstaltungen<br> findest Du unter:</b> www.naju-bayern.de
    </p>
    </div>
    <imgRightsBox :images="page.images" :rightOriented="true"></imgRightsBox>
  </div>
</template>


<script>
import DragableImage from '../draggableFlyerImage.vue'
import InfoBox from '../infoBox.vue'
import FlyerTextBox from '../flyerTextBox.vue'
import imgRightsBox from '../imgRightsBox.vue'
import otherSemViewer from "./otherSemViewer.vue"

export default {
  name: 'handzettelBack',
  data () {
    return {
    }
  },
  props:["page","themeColor", "result"],
  components: {
    'dragableImage': DragableImage,
    InfoBox,
    FlyerTextBox,
    imgRightsBox,
    otherSemViewer,
  },
}
</script>

<style scoped>

#titlepageWrapperBox{
  width: 111mm;
  height: 154mm;
  font-size: 10.8pt;
  line-height:1.2;
}
#abriss{
  width:100%;
  position:absolute;
  z-index:2;
  top: 47mm;
}

#anschnitt{
  position: absolute;
  z-index: 1;
  top: 40mm;
  left:0;
  width: 80%;
  background: var(--themeColor);
  height: 100px;
  pointer-events: none;
  -webkit-mask-image: url(./keil.svg);
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  -webkit-mask-position: left top;
  
}

#contentBox{
  position:absolute;
  top: 55mm;
  z-index:3;
  left: 10mm;
  padding-right:10mm;
  height:440px;
}

>>> #contentBox b{
    color: var(--themeColor)
}


#titlepageWrapperBox >>> .flyerImage{
  height:52mm;
  position:absolute;
  top:0;
}


#contentBox >>> #textBox{
  font-size:inherit;
  padding:0;
  margin:0;
}

#otherSemsText, #wsLinkBox{
    font-weight: bold;
}

#titlepageWrapperBox >>> #imgRightsBox{
  z-index:5;
}

#titlepageWrapperBox >>> #imgRightsBox.left{
  right:20mm;
}

#imgRightsBox.right{
    left: 10mm;
}

</style>
