<template>
<div class="form-group row">
    <div class="col-md-3">
      <input ref="num" class="form-control" type="number" v-model="item.num" />
    </div>
    <div class="col-md-7">
      <input class="form-control" type="text" placeholder="Name, Alternativbegriff 1, ..." v-model="item.name" @keydown.enter="submit()"/>
    </div>
    <div class="col-md-2">
      <button class="btn btn-success" @click="submit()">+</button>
    </div>
  </div>
</template>


<script>


export default {
  name: 'AddItem',
  data(){
    return {
      item: {
        num:1,
        name:""
      }
    };
  },
  props:{
    containing:{
      default: 0
    }
  },
  methods:{
    submit(){
      if(this.item.name == "")
        return;
      var nameParts = this.item.name.split(",").map(each => each.trim());
      var num = this.item.num != "" ? this.item.num : 1;
      console.log("num:" + num);
      var t = this;
      $.get(this.$root.api_root + "stock/addItem.php",{
        name: nameParts[0],
        synonyms: JSON.stringify(nameParts.slice(1)),
        num: num,
        containing: this.containing
      }, function(resp){
          t.$root.checkRespForLoginFail(resp);
          t.$emit("newItem",{
            name: nameParts[0],
            id: resp,
            num: num
          });
      });


      this.item = {
        num: "",
        name: ""
      }
      $(this.$refs.num).focus();
    }


  }

}

</script>

<style scoped>



</style>

