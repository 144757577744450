<template>
<div>
    <div v-for="sem in sems" :key="sem.id">
        <span class="date">{{moment(sem.start).format("DD.MM")}} - {{moment(sem.end).format("DD.MM.")}}</span>
        <span>{{sem.name}}</span>
    </div>
</div>
</template>

<script>
export default {
  name: 'OtherSemViewer',
  data () {
    return {
        sems:[]
    }
  },
  props:["otherSems"],
  created(){
    this.updateSems(this.otherSems);
  },
  methods:{
    updateSems(otherSems){
        console.log(JSON.stringify(otherSems));
        var t = this;
        $.get(this.$root.api_root + "flyer/getPagesQuickInfo.php",{
            ids: JSON.stringify(otherSems)
        },function(res){
            t.$root.checkRespForLoginFail(res);
            t.sems = JSON.parse(res);
        });
    }
  },
  watch:{
    otherSems(newV){
        this.updateSems(newV);
    }
  }
}
</script>
<style scoped>
.date{
    display:inline-block;
    width: 90px;
}
</style>
