<template>
  <ul>
    <li v-for="todo in todos" :key="todo.id">
      <router-link :to="{ name: 'editItem', params: { iid: todo.id }}">{{todo.name}}</router-link>
      - {{todo.todo}}
    </li>
  </ul>
</template>



<script>


export default {
  name: 'Todos',
  data(){
    return {
      todos: []
    };
  },
  created(){
    var t = this;
    $.get(this.$root.api_root + "stock/getTodos.php",{},function(data){
      t.$root.checkRespForLoginFail(data);
      t.todos = JSON.parse(data);
    });
  }
}
</script>
