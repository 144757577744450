<template>
  <b-col class="col-lg-8 col-xl-6 col-12 offset-lg-2">
    <h1>Buchung bearbeiten</h1>
    <b-form ref="myForm" class="needs-validation" novalidate>
      <b-form-row class="my-2">
        <b-col cols="2">Name</b-col>
        <b-col cols="10"> <b-input v-model="entry.name" name="fundEntryName" required/></b-col>
      </b-form-row>
      <b-form-row class="my-2">
        <b-col cols="2">Preis</b-col>
        <b-col cols="10">
          <b-input-group append="€">
            <b-input v-model="entry.price" name="fundEntryPrice" type="number" step=".01" required/>
          </b-input-group>
        </b-col>
      </b-form-row>

      <b-form-row class="my-2">
        <b-col cols="2">Fördertopf</b-col>
        <b-col cols="10"> <feederbowlPicker v-model="entry.subfeederbowl"/></b-col>
      </b-form-row>

      <b-form-row class="my-2">
        <b-col cols="2">Infos</b-col>
        <b-col cols="10"> <vue-editor v-model="entry.description" :editorToolbar="[
            [{ 'header': 1 }, { 'header': 2 }],
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['link']
          ]"></vue-editor></b-col>
      </b-form-row>
      <b-form-row class="my-2">
        <b-col cols="2">Buchungsnummer</b-col>
        <b-col cols="10"> <b-input v-model="entry.buchungsnummer" name="fundEntryBuchungsnr" placeholder="Die beim LBV verwendete Buchungsnr."/></b-col>
      </b-form-row>
      <b-form-row class="my-2">
        <b-col cols="2">Datum</b-col>
        <b-col cols="10"> <datepicker v-model="entry.date" name="fundEntryBuchungsnr"/></b-col>
      </b-form-row>

      <b-form-row class="my-2">
        <b-col cols="2">Schätzung</b-col>
        <b-col cols="10"><b-form-checkbox v-model="entry.isGuess">Es handelt sich um eine Schätzung</b-form-checkbox></b-col>
      </b-form-row>

      <b-form-row class="my-2">
        <b-col cols="2"></b-col>
        <b-col cols="10"><saveBtn ref="saveBtn" :saveable="entry" :validation="checkForm" api="fundmonitor/saveFundEntry.php" @saved="setId"/></b-col>
      </b-form-row>
    </b-form>
  </b-col>
</template>

<script>

import { mapState } from 'vuex'
import saveBtn from '../saveBtn.vue'
import { VueEditor } from 'vue2-editor'
import datepicker from "../datepicker.vue"
import feederbowlPicker from "./feederbowlPicker.vue"

export default {

  name: 'editFundEntry',
  data(){
    return{
      entry: {
        name: "",
        date:null
      }
    }
  },
  components:{
    saveBtn,
    VueEditor,
    datepicker,
    feederbowlPicker
  },
  created(){
    this.loadEntry(this.$route.params.id, this.$route.query);
  },
  methods:{
    loadEntry(id, query){
      var t = this;

      if(id == null){
        t.entry = {id: null, name: "", description: "", date: new Date().getTime(), isGuess:false};
        if(t.$refs.saveBtn != null){
          t.$refs.saveBtn.reset();
        }
        return;
      }

      $.get(this.$root.api_root + "fundmonitor/getFundEntry.php",{
        id: id
      },function(res){
        t.$root.checkRespForLoginFail(res);
        if(res == "not found"){
          alert("Nichts gefunden!");
          return;
        }
        t.entry = JSON.parse(res);
        t.$refs.saveBtn.reset();
      });
    },
    setId(resp){
      var respD = JSON.parse(resp);
      if(respD == null || !respD.success){
        alert(resp);
        return;
      }

      if(respD.id != this.entry.id){
        this.$router.push({ name: 'editFundEntry', params: { id: respD.id } })
      }

    },
    checkForm(event) {
        //event.preventDefault();
        this.$refs.myForm.classList.add('was-validated');
        var valid = this.$refs.myForm.checkValidity();
        /*if(valid)
            valid = this.validateCb();*/
        return valid;
    }
  },
  beforeRouteUpdate (to, from, next) {
    if(! this.$refs.saveBtn.confirmLeave()){
      next(false);
      return;
    }
    this.loadEntry(to.params.id, to.query);
    next();
  }
}
</script>


<style scoped>
 >>> #quill-container{
  height: auto;
}
>>> .ql-editor{
  height: auto;
}

>>> .quillWrapper{
  background: #fff;
}
</style>

