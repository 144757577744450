<template>
  <b-button :variant="log.done ? 'success' : ''" :pressed="log.done" @click="clicked" :disabled="pending">{{log.done ? "Ja" : "Nein"}}</b-button>
</template>


<script>



export default {
  name: 'AktiveLogsDoneBtn',
  data(){
    return {
      pending: false
    };
  },
  props:["log"],
  methods:{
    clicked(){
      var t = this;
      t.pending = true;
      $.get(this.$root.api_root + "seminare/setLogDone.php",{
        id: t.log.id,
        value: !t.log.done //invertiert da geändert werden soll
      },
      function(data){
        t.$root.checkRespForLoginFail(data);
        if(data.includes("error")){
            alert(data);
            return;
        }
        t.log.done = !t.log.done;
        t.pending = false;
      });
    }
  }

}

</script>

<style scoped>


</style>


