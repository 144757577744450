<template>
  <tr v-if="showPrice || showPriceMember || showPriceGroupleader">
    <td class="left">Preis?</td>
    <td class="right">
      <span v-if="showPrice">{{formatPrice(page.price)}}</span>
      <span v-if="showPrice && showPriceMember"> / </span>
      <span v-if="showPriceMember">{{formatPrice(page.priceMember)}} als LBV-Mitglied</span>
      <span v-if="showPriceGroupleader && (showPrice || showPriceMember)"> / </span>
      <span v-if="showPriceGroupleader">{{formatPrice(page.priceGroupleader)}} als Gruppenleiter*in</span>
    </td>
  </tr>
</template>


<script>

export default {
  name: 'InfoBoxPrice',
  data () {
    return {
    }
  },
  props:["page"],
  methods:{
     isNull(p){
        return p == null || p == '';
      },
      formatPrice(price){
        if(price == 0){
          return "Kostenlos";
        }
        return price + ",- €";
      },
  },
  computed:{
    showPrice(){
      return !this.isNull(this.page.price);
    },
    showPriceMember(){
      return !this.isNull(this.page.priceMember) && this.page.price != this.page.priceMember
    },
    showPriceGroupleader(){
      return !this.isNull(this.page.priceGroupleader);
    }
  }
}
</script>

<style scoped>



</style>
