<template>
  <div id="indexWrapperBox">
    <div class="singlePage" v-for="pIndex in Math.ceil(selectedPages.length / 11)">
        <headline :value="page.headline" :rightOriented="false" :color="themeColor" v-if="pIndex % 2 == 1"></headline>
        <subheadline :value="page.subheadline" :rightOriented="false" :color="themeColor" v-if="pIndex % 2 == 1"></subheadline>
          <indexTable id="indeTable1" :pages="selectedPages.slice((pIndex-1)*11,pIndex*11)"></indexTable>
        <pageNrBox :nr="page.pageNr + pIndex - 1" :rightOriented="false"></pageNrBox>
    </div>
  </div>
</template>


<script>
import FlyerImageBox from './flyerImageBox.vue'
import HeadlineBox from './headlineBox.vue'
import SubheadlineBox from './subheadlineBox.vue'
import FlyerTextBox from './flyerTextBox.vue'
import InfoBox from './infoBox.vue'
import imgRightsBox from './imgRightsBox.vue'
import pageNrBox from './pageNrBox.vue'
import indexTable from './indexTable.vue'

export default {
  name: 'IndexPage',
  data () {
    return {
      pages: [],
      overflow: {pages: []},
      overflow2: {pages: []}
    }
  },
  props:["page", "themeColor", "result"],
  components: {
    'flyerImageBox': FlyerImageBox,
    'headline': HeadlineBox,
    'subheadline': SubheadlineBox,
    'mainText': FlyerTextBox,
    "infobox": InfoBox,
    imgRightsBox,
    pageNrBox,
    indexTable
  },
  created(){
    var t = this;
    $.get(this.$root.api_root + "flyer/get_flyer.php",{
      fid: this.page.flyer
    },function(data){
      t.$root.checkRespForLoginFail(data);
      t.pages = JSON.parse(data).pages;
    });
  },
  computed:{
    selectedPages() {
      return this.pages.filter(function(each){return each.template == 'semSinglePage' || each.template == 'semDoublePage'});
    }
  }
}
</script>

<style>

#indexWrapperBox{
  display: inline-block;
}

.singlePage{
  width: 154mm;
  height: 111mm;
  position: relative;
  display: inline-block;
  vertical-align:top;
}

#indeTable1{
  margin-top: 80px;
}

#indeTable2{
  margin-top: 80px;
}
</style>
