<template>
<div class="row">
  <div class="col-md-12 col-xl-10">
  <div class="alert alert-warning" role="alert" v-if="page.locked == false">
              <strong>Warnung!</strong> Diese Seite wird gerade von {{page.lockedBy}} bearbeitet. Du kannst daher nur lesen.
  </div>

  <b-tabs content-class="mt-3">
    <b-tab title="Allgemeines" active>
      <div class="form-group row" v-if="page.flyerType != 'handzettel'">
          <div for="name" class="col-md-2 col-form-label">Seitentyp</div>
          <div class="col-md-10">
              <select v-model="page.template" :disabled="!page.locked">
                  <template v-if="page.flyerType != 'poster'">
                    <option value="semSinglePage">einseites Seminar</option>
                    <option value="semDoublePage">zweiseitiges Seminar</option>
                    <option value="titlepage">Titlelseite</option>
                    <option value="singleTextpage">einseitige Textseite</option>
                    <option value="index">Inhaltsverzeichnis</option>
                    <option value="lastPage">Letzte Seite - altes Layout</option>
                    <option value="lastPage-new-generic">Letzte Seite - allgemein</option>
                    <option value="lastPage-new-sem">Letzte Seite - Kinder/Jugend</option>
                    <option value="lastPage-new-multi">Letzte Seite - Multiplikatoren</option>
                  </template>
                  <option value="vpPoster" v-if="page.flyerType == 'poster'">Veranstaltungsplakat</option>
              </select>
          </div>
      </div>

      <div class="form-group row">
          <div for="name" class="col-md-2 col-form-label">Farbkonzept</div>
          <div class="col-md-10">
              <select v-model="page.flyerColor" :disabled="!page.locked">
                  <option value="jugend">Jugend</option>
                  <option value="kinder">Kinder</option>
                  <option value="multi">Multiplikatoren</option>
                  <option value="green">Grün</option>
              </select>
          </div>
      </div>
      
      <b-form-row class="my-2" v-if="page.template == 'handzettelFront'">
        <b-col cols="2">Typ</b-col>
        <b-col cols="10">
            <b-form-select v-model="page.options.handzettelType" :options="['Kinderfreizeit', 'Jugendfreizeit', 'Ferienfreizeit', 'NAJU-Camp', 'Kinderzeltlager']"/>
        </b-col>
      </b-form-row>

      <div v-if="page.template != 'lastPage' && page.template != 'lastPage-new-generic' && page.template != 'lastPage-new-sem' && page.template != 'lastPage-new-multi' && page.template != 'handzettelBack'" class="form-group row">
          <div for="name" class="col-md-2 col-form-label">Überschrift</div>
          <div class="col-md-10">
              <input class="form-control" type="text" v-model="page.headline" placeholder="z.B. Kanutour" :disabled="!page.locked">
          </div>
      </div>

      <div v-if="page.template != 'lastPage' && page.template != 'lastPage-new-generic' && page.template != 'lastPage-new-sem' && page.template != 'lastPage-new-multi' && page.flyerType != 'handzettel'" class="form-group row">
          <div for="subHeadlineEdit" class="col-md-2 col-form-label">2. Überschrift</div>
          <div class="col-md-10">
              <input id="subHeadlineEdit" class="form-control" type="text" v-model="page.subheadline" placeholder="z.B. auf dem Main" :disabled="!page.locked" >
          </div>
      </div>

      <div v-if="page.template != 'lastPage' && page.template != 'lastPage-new-generic' && page.template != 'lastPage-new-sem' && page.template != 'lastPage-new-multi' && page.flyerType != 'handzettel'" class="form-group row">
          <div for="subHeadlineEdit" class="col-md-2 col-form-label">Überschrift für Inhaltsverzeichnis</div>
          <div class="col-md-10">
              <input id="subHeadlineEdit" class="form-control" type="text" v-model="page.headlineForIndex" placeholder="z.B. Kanutour auf dem Main" :disabled="!page.locked" >
          </div>
      </div>

      <div v-if="page.template == 'semSinglePage' || page.template == 'semDoublePage' || page.template == 'vpPoster' " class="form-group row">
          <div for="workingTitle" class="col-md-2 col-form-label">Arbeitstitel</div>
          <div class="col-md-10">
              <workingTitlePicker id="workingTitle" v-model="page.workingTitle" placeholder="z.B. kanutour" :disabled="!page.locked" />
          </div>
      </div>


      <div class="form-group row" v-if="page.template != 'lastPage' || page.template != 'lastPage-new-generic' || page.template != 'lastPage-new-sem' || page.template != 'lastPage-new-multi'">
        <div for="printer" class="col-md-2 col-form-label">Druckerei</div>
        <div class="col-md-10">
            <input id="printer" name="printer" class="form-control" type="text" v-model="page.printer" autocomplete="off" placeholder="" :disabled="!page.locked">
        </div>
      </div>
    </b-tab>





    <b-tab title="Text" v-if="page.template == 'semSinglePage' || page.template == 'semDoublePage'  || page.template == 'singleTextpage'  || page.template == 'vpPoster' || page.template =='lastPage' || page.template == 'handzettelBack'">
            <textarea id="textEdit" name="text" class="form-control" v-model="page.text" rows="8" :disabled="!page.locked"></textarea>
            <br>
            <div class="alert alert-warning" role="alert" v-if="result.overflow != ''">
              <strong>Warnung!</strong> Der Text ist zu lang! Es wird Text abgeschnitten.
            </div>
            <b-form-checkbox v-model="page.textIsFinal">Text ist final geschrieben</b-form-checkbox>
            <b-form-checkbox v-model="page.textAgreed">Text ist vom Seminarleiter freigegeben / nicht nötig</b-form-checkbox>
            <b-form-checkbox v-model="page.textSpellingOk">Text ist geprüft (Iris)</b-form-checkbox>

            <b-form-group label="Kurzbeschreibung" :description="'Maximal 100 Zeichen. Noch: ' + ( 100 - (page.shortText == null ? 0 : page.shortText.length))" class="my-2">
              <b-textarea v-model="page.shortText" :maxlength="100"/>
            </b-form-group>
    </b-tab>


    <b-tab title="Details" v-if="page.template == 'semSinglePage' || page.template == 'semDoublePage' || page.template == 'vpPoster' || page.template == 'handzettelFront'">
      <dateeditor :page="page"></dateeditor>
      <ageeditor :page="page"></ageeditor>

      <div class="form-group row">
          <div for="name" class="col-md-2 col-form-label">Ort</div>
          <div class="col-md-3">
              <b-form-group label="PLZ">
                <input id="plzEdit" name="plzEdit" class="form-control" type="text" v-model="page.plz" placeholder="" :disabled="!page.locked">
              </b-form-group>
          </div>
          <div class="col-md-5">
              <b-form-group label="Ortsname" description="Wenn mögl. nur eine Zeile verwenden">
                <b-textarea id="placeEdit" class="form-control" type="text" v-model="page.place" placeholder="" :disabled="!page.locked" />
              </b-form-group>
          </div>
          <div class="col-md-2">
              <b-form-group label="Bezirk">
                <input id="bezirkEdit" name="bezirk" class="form-control" type="text" v-model="page.bezirk" autocomplete="off" placeholder="" :disabled="!page.locked">
              </b-form-group>
          </div>
      </div>

      <div class="form-group row">
          <div for="name" class="col-md-2 col-form-label">Preis</div>
          <div class="col-md-2">
              <input id="priceEdit" class="form-control" type="number" v-model="page.price" autocomplete="off" placeholder="" :disabled="!page.locked">
          </div>
          <div class="col-md-4">
              <div class="input-group">
                  <input id="priceMemberEdit" class="form-control" type="number" v-model='page.priceMember' placeholder="" :disabled="!page.locked">
                  <div class="input-group-append">
                    <span class="input-group-text" style=" text-align: left;">als Mitglied</span>
                  </div>
              </div>
          </div>
          <div class="col-md-4">
              <div class="input-group">
                  <input id="priceMemberEdit" class="form-control" type="number" v-model='page.priceGroupleader' placeholder="" :disabled="!page.locked">
                  <div class="input-group-append">
                    <span class="input-group-text" style=" text-align: left;">als Gruppenleiter*in</span>
                  </div>
              </div>
          </div>
      </div>

      <leitungsEditor :page="page" v-if="page.template == 'semSinglePage' || page.template == 'semDoublePage' || page.template == 'vpPoster'"></leitungsEditor>
      
      <b-form-row v-if="page.template == 'vpPoster'">
          <div for="name" class="col-md-2 col-form-label">Alternative Kontaktdaten</div>
          <div class="col-md-10">
              <b-input v-model="page.options.altPhoneNumber" autocomplete="off" placeholder="alt. Telefonnummer" :disabled="!page.locked" class="my-2"/>
              <b-input v-model="page.options.altWebsite" autocomplete="off" placeholder="alt. Website" :disabled="!page.locked" class="my-2"/>
          </div>
      </b-form-row>
    </b-tab>
    
    <otherSemEditor v-if="page.template == 'handzettelBack'" :page="page"/>


    <b-tab title="Bilder"  v-if="page.template == 'semSinglePage' || page.template == 'semDoublePage' || page.template == 'titlepage' || page.template == 'vpPoster' || page.template == 'handzettelFront' || page.template == 'handzettelBack'">
      <imagesEditor :page="page"  />
      <b-form-radio-group :disabled="!page.locked" v-model="page.options.showBjrLogo" :options="[
        {text: 'Kein Logo', value: false},
        {text: 'BJR-Logo', value: true},
        {text: 'StMUV', value: 'StMUV'},
      ]" v-if="page.template == 'vpPoster'" class="my-2"/>
    </b-tab>
  </b-tabs>

  <button id="saveBtn" ref="saveBtn" v-on:click="submit()" type="button" class="btn btn-success my-1 mx-1" :disabled="!page.locked">{{isSaved ? "Gespeichert" : "Speichern"}}</button>
  <button v-on:click="delPage()" type="button" class="btn btn-danger my-1 mx-1" :disabled="!page.locked" v-if="page.flyerType != 'poster' && page.flyerType != 'handzettel'">Seite löschen</button>
  <button v-on:click="toPoster('poster')" type="button" class="btn btn-info my-1 mx-1" v-if="page.template == 'semSinglePage' || page.template == 'semDoublePage'">Plakat erstellen</button>
  <button v-on:click="toPoster('handzettel')" type="button" class="btn btn-info my-1 mx-1" v-if="page.template == 'semSinglePage' || page.template == 'semDoublePage'">Handzettel erstellen</button>
  <page2wordpress ref="p2w"/>
  <b-button v-if="page.template == 'semSinglePage' || page.template == 'semDoublePage'" @click="toWebsite" class="my-1 mx-1">Auf Website übertragen</b-button>
  <router-link :to="{ name: 'ferienboersen', params: { pid: page.id }}">
    <b-button v-if="page.template == 'semSinglePage' || page.template == 'semDoublePage'" class="my-1 mx-1">Ferienbörsen</b-button>
  </router-link>
  <router-link :to="{ name: 'insta', query: { 
        text: page.headlineForIndex != null ? page.headlineForIndex :  page.headline + ' ' + page.subheadline,
        dateFrom: page.dateFrom,
        dateTo: page.dateTo,
        city: page.place,
        type: page.flyerColor
        }}">
    <b-button v-if="page.template == 'semSinglePage' || page.template == 'semDoublePage' || page.template == 'vpPoster'" class="my-1 mx-1">Instagram Post</b-button>
  </router-link>
  </div>



</div>
</template>
<script>
import Ageeditor from './ageEditor.vue'
import Dateeditor from './dateEditor.vue'
import imagesEditor from './imagesEditor.vue'
import leitungsEditor from './leitungsEditor.vue'
import page2wordpress from "../page2wordpress.vue"
import workingTitlePicker from "../../workingTitlePicker.vue"
import otherSemEditor from "./otherSemEditor.vue"

export default {
  name: 'Flyereditor',
  data(){
    return {isSaved: true,
      lockInterval: null};
  },
  props: ['page',"result","small"],
  components: {
    "ageeditor": Ageeditor,
    "dateeditor": Dateeditor,
    imagesEditor,
    leitungsEditor,
    page2wordpress,
    workingTitlePicker,
    otherSemEditor
  },
  methods: {
      submit(){
        var t = this;
        $.post(this.$root.api_root + "flyer/get_savePage.php",{
          "page": JSON.stringify(this.page)},
          function(res){
            t.$root.checkRespForLoginFail(res);
          });
        this.isSaved = true;
      },
      delPage(){
        var t = this;
        if(!confirm("Willst du diese Seite wirklich löschen?"))
          return;
        $.get(this.$root.api_root + "flyer/get_delPage.php",{
          pid: this.page.id
        },function(res){
          t.$root.checkRespForLoginFail(res);
          t.$router.go(-1);
        });

      },
      tryLock(){
        var t = this;
        if(this.page.id != null){
          $.get(this.$root.api_root + "flyer/get_lock.php",{
            action: "lock",
            "pid":this.page.id
          }, function(resp){
            t.$root.checkRespForLoginFail(resp);
            if(resp.indexOf("success") != -1)
              t.page.locked = true;
          });
        }
      },
      toPoster(toWhat){
        var t = this;
        var url = this.$root.api_root;
        if(toWhat == "handzettel"){
            url += "flyer/page2handzettel.php";
        }else{ //poster
            url += "flyer/page2poster.php";
        }
        $.get(url,{
          pid: t.page.id
        }, function(resp){
            t.$root.checkRespForLoginFail(resp);
            resp = JSON.parse(resp);
            t.$router.push({ name: 'editFlyer', params: { fid: resp.fid } })
        });
      },
      toWebsite(){
        this.$refs.p2w.show([{
          id: this.page.id,
          title: this.page.headline,
          template: this.page.template
          }]);
      }
  },
  mounted(){
    var t = this;
    $(document).keydown(function(event) {
        //if (!(event.which == 115 && event.ctrlKey) && !(event.which == 19)) return true;
        //alert("Ctrl-S pressed");
        if(!event.ctrlKey)
          return;

        if(event.key != 's')
          return;

        t.submit();

        event.preventDefault();
        return false;
    });

    this.lockInterval = setInterval(this.tryLock, 10000);

  },
  beforeDestroy(){
    clearInterval(this.lockInterval);
    var t = this;
    if(this.page.id != null){
      $.get(this.$root.api_root + "flyer/get_lock.php",{
        action: "unlock",
        "pid":this.page.id
      }, function(res){
        t.$root.checkRespForLoginFail(res);
      });
    }
  },
  watch:{
    page:{
      handler(){
        this.isSaved = false;
      },
      deep: true
    }
  }
}
</script>
