<template>
<div>
  <VueApexCharts v-if="type == 'multipleChoice'" width="500" type="bar" :options="chartOptions" :series="chartSeries"></VueApexCharts>
  <VueApexCharts v-else width="500" type="pie" :options="pieChartOptions" :series="choiceNums"></VueApexCharts>
  <b-form-checkbox class="my-2" v-model="showTable">Einzelne Antworten anzeigen</b-form-checkbox>
  <choiceTable v-if="showTable" :choiceNames="choiceNames" :replys="replys" :names="names" :choices="choices"/>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import VueApexCharts from 'vue-apexcharts'
import choiceTable from "./choiceTable.vue"

export default {

  name: 'choiceResults',
  data(){
    return{
        showTable: false,
    }
  },
  props:{
    "choices":{}, 
    replys: {},
    type: {},
    names:{}
  },
  components:{
    VueApexCharts,
    choiceTable
  },
  computed:{
  
    choiceNames(){
        return [...this.choices.map(c => {
            return c.text;
        }), "Keine Antwort"];
    },
    choiceNums(){
        return [...this.choices, {text:null}].map(c => {
            return this.replys.filter(r => {
                return r == c.text || (Array.isArray(r) && r.indexOf(c.text) != -1)
            }).length;
        });
    },
    chartOptions(){
        return {
            chart: {
            id: 'vuechart-example'
            },
            xaxis: {
            categories: this.choiceNames
            }
        };
    },
    
    pieChartOptions(){
        return {
            chart: {
            id: 'vuechart-example'
            },
            labels: this.choiceNames
        };
    },
    
    chartSeries(){
        return [{
        name: 'Verteilung',
        data: this.choiceNums
      }];
    }
  },
  methods:{
    
  },
  
}
</script>
