<template>
  <input type="text" class="form-control" v-model="searchText" ref="search" @input="clear" @blur="blur" no-autocomplete autocomplete="off"/>
</template>


<script>



export default {
  name: 'LocationInput',
  data(){
    return {
      searchText: "",
    };
  },
  props:{
    value:{ },
    resetOnFound:{type:Boolean, default: false},
  },
  mounted(){
      var t = this;
      $(this.$refs.search).autocomplete({
          source: function( request, response ) {
              $.ajax( {
                  url: t.$root.api_root + "seminare/locationSearch.php",
                  data: {
                      search: request.term
                  },
                  success: function( data ) {
                    t.$root.checkRespForLoginFail(data);
                    response( JSON.parse(data) );
                  }
              } );
          },
          select:function(event,ui){
            t.$emit("input", {pos: ui.item.link, label:ui.item.label});
            //t.$emit("found", {name: ui.item.label, id: ui.item.link.id, type: ui.item.link.type});
            if(t.resetOnFound){
              t.searchText = null;
            }
          }
      });
  },
  beforeDestroy(){
    $(this.$refs.search).autocomplete("destroy");
  },
  methods:{
    clear(){
      this.$emit("input", null);
    },
    blur(){
      if(this.value == null)
        this.searchText = null;
    },
  },
  created(){
    if(this.value != null && this.value.hasOwnProperty("label"))
      this.searchText = this.value.label
  }
}

</script>

<style scoped>


</style>


