<template>
  <b-col class="col-lg-8 col-xl-6 col-12 offset-lg-2">
    <h1>Buchungen durchsuchen</h1>
    <b-input v-model="search" class="my-2"/>
    <b-table :items="result" :fields="[
      {key:'name', sortable:'true'},
      {key:'feederbowl', label:'Fördertopf', sortable:'true'},
      {key:'price', label:'Preis', sortable:'true'}]" class="my-2">
        <template v-slot:cell(name)="data">
          <router-link :to="{ name: 'editFundEntry', params: { id: data.item.id }}">{{data.value}}</router-link>
        </template>
        <template v-slot:cell(price)="data"><template v-if="data.item.isGuess">Schätzung: </template>{{data.value}} €</template>
    </b-table>

    <i>Es werden nur die ersten 20 Treffer angezeigt</i>


  </b-col>
</template>

<script>

import { mapState } from 'vuex'


export default {

  name: 'SearchFundEntries',
  data(){
    return{
      search:"",
      result:[]
    }
  },
  components:{

  },
  created(){
  },
  watch:{
    search(newS){
      var t = this;
      $.get(this.$root.api_root + "fundmonitor/searchFundEntries.php",{
        search: newS
      },function(res){
          t.$root.checkRespForLoginFail(res);
          t.result = JSON.parse(res).map(e => {
            e._rowVariant = e.isGuess ? "guess" : "";
            return e;
          });
      });
    }
  }
}
</script>

<style scoped>

>>> table{
    background: #fff;
  }

>>> .table-guess{
  color:#666;
}

  .description{

  }

</style>
