<template>
  <p id="textBox" ref="textBox"
  v-bind:class="{ right:  rightOriented, left:  !rightOriented, fullWidth: fullWidth, hasOverflow: overflow != '', showDash: showDash }"><span v-html="text"></span></p>
</template>


<script>
export default {
  name: 'FlyerTextBox',
  data(){
    return {
      text: "",
      overflow: "",
      showDash: false,
    };
  },
  props: ['value', 'rightOriented', "fullWidth", "fullHeight", "result"],
  computed:{

  },
  created(){
  var t = this;
    console.log("asdf:");
    if(typeof Hyphenator === 'undefined'){
      $.getScript("Hyphenator.js").done(function(){
        Hyphenator.config({
          displaytogglebox : false,
          minwordlength : 4,
          defaultlanguage: 'de'
        });
        t.textChanged();
      });
    }
  },
  mounted(){
  var FontFaceObserver = require('fontfaceobserver');
  var ssp = new FontFaceObserver('Source Sans Pro');
  var sspb = new FontFaceObserver('Source Sans Pro', {weight: 700});

  var t = this;
  Promise.all([ssp.load(), sspb.load()]).then(function(){
    console.log("loaded");
    t.textChanged();
  });


   this.textChanged();
  },
  methods:{
    cutAt(index){
      this.overflow = this.text.substring(index) + this.overflow;
      this.text = this.text.substring(0,index);
    },
    textChanged(){
      if(typeof Hyphenator === 'undefined')
        return
      this.overflow = "";
      var text = "";
      if(this.value != null)
        text = this.value;
      var blocks = Hyphenator.hyphenate(text.trim(),'de').split("\n");
      this.text = blocks.reduce(function(ret, each){
        return ret + "<p>" + each + "</p>\n";
      },"");
      this.text = this.text.replace("<br />","<br/>");
      this.showDash = false;
    },
  },
  updated(){
    var overflow  = 0;
    if(this.fullHeight)
      overflow = 260 - $(this.$refs.textBox).height();
    else{
      var start = $(this.$refs.textBox).css("padding-top").replace("px","") * 1 + $(this.$refs.textBox).position().top * 1;

      overflow = $("#infoTable").position().top - (start + $(this.$refs.textBox).height());
    }

    if(overflow < -20){//guess index
      this.cutAt(this.text.length - 20);
    }
    else if(overflow < 10){//has overflow
      var lastWhitespace = this.text.lastIndexOf(" ");
      if(this.text.lastIndexOf("\n") > lastWhitespace)
        lastWhitespace = this.text.lastIndexOf("\n");
      this.showDash = false;
      if(this.text.lastIndexOf("\u00AD") > lastWhitespace){
        lastWhitespace = this.text.lastIndexOf("\u00AD");
        this.showDash = true;
      }
      this.cutAt(lastWhitespace)
    }
    else{
      this.$set(this.result, "overflow", this.overflow.trim());
    }
  },
  watch:{
    value: function(){
      this.textChanged();
    },
    fullHeight:function(){
      this.textChanged();
    },
    fullWidth:function(){
      this.textChanged();
    },
  }
}

</script>

<style>


#textBox{
    margin-top: 0;
    padding-top: 100px;
    text-align:justify;
    font-size: 12px;
}

#textBox.left{
  margin-right: 45px;
}

#textBox.right{
  margin-left: 45px;
}

#textBox.fullWidth{
  margin-left: 45px;
  margin-right: 45px;
}

#textBox.hasOverflow p:last-child{
  text-align-last: justify;
}

#textBox.showDash p:last-child::after {
    content: "-";
}

#textBox p{
  margin-bottom: 1px;
}

ul{
   list-style-position: inside;
    padding-left:3px;
}
</style>

