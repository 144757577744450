<template>
    <b-col class="col-12 col-md-6 col-xl-3 offset-md-2 offset-xl-3 my-5" id="loginbox">
        <h2>Anmelden</h2>
        <b-form-group label="Nutzername" description="">
            <b-input v-model="username" name="username" :disabled="pending"/>
        </b-form-group>
        <b-form-group label="Passwort" description="">
            <b-input v-model="password" type="password" @keyup.enter="login" class="my-1" :disabled="pending"/>
        </b-form-group>
        <b-button variant="success" :disabled="username === '' || password === '' || pending" @click="login">Anmelden
        </b-button>
    </b-col>
</template>


<script>
    import {Fido2Authentification} from "./fido2/authentification"

    export default {
        name: 'Login',
        data() {
            return {
                username: "",
                password: "",
                pending: false
            };
        },
        props: [],
        components: {},
        methods: {
            login() {
                this.loginInt(null);
            },
            loginInt(fido2resp) {
                const t = this;
                return new Promise((resolve, reject) => {
                    $.post(this.$root.api_root + "login.php", {
                        user: this.username,
                        pass: this.password,
                        fido2resp: fido2resp
                    }, function (resp) {
                        if (resp.indexOf("success") === 0) {
                            t.$store.dispatch("loadUser", true);
                            resolve();
                        } else if (resp.indexOf("2factor") === 0) {
                            if (fido2resp != null) {
                                reject("fido2 login failed");
                            }
                            new Fido2Authentification(t.$root.api_root).authenticate(t.username).then((response) => {
                                t.loginInt(response).then(resolve).catch(reject);
                            }).catch((error) => {
                                reject(error);
                            });
                        }
                    });
                });
            }
        },

    }

</script>

<style scoped>
    #loginbox {
        border-radius: 4px;
        background: #fff;
        padding: 20px;
    }
</style>
