<template>
  <td class="currency" v-bind:class="{neg: (colorize && value < 0) || (colorizeInv && value > 0)}">{{value | currency(' €', 2, { decimalSeparator: ',' , symbolOnLeft: false, thousandsSeparator: '.'})}}</td>
</template>
<script>

export default {
  name: 'TableTd',
  props:{"value":{}, "colorize":{default: false}, "colorizeInv":{default: false}},
  data(){
    return{

    }
  },
  components:{

  },
  methods:{
  },
  watch:{

  },
  created(){

  },
  computed:{

  }
}
</script>

<style scoped>

  .currency{
    text-align: right;
  }
  .neg{
    color: #f00;
  }
</style>


