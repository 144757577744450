<template>
  <div class="col-md-6 col-xl-4">
      <b-form-file v-model="image" class="my-2"/>
      <div class="border" ref="border">
        <img src="./NAJU_Klammer.png" class="klammer"/>
        <div class="textbox" v-if="text != ''" v-bind:style="{'font-size': fontSize, 'border-radius': borderRadius, padding: textBoxPadding,  lineHeight: lineHeight}">
            <div v-if="announcement" class='smallText'>Jetzt anmelden {{this.vName}}:</div>
            <div v-html="textWithBrs"></div>
            <div class='smallText' v-if="announcement"><dateViewer :dateFrom="dateFrom" :dateTo="dateTo"/> | {{city}}</div>
        </div>
        <draggableImage v-model="preview" :url="previewUrl" class="dragableImage"/>
      </div>
      <b-form-checkbox v-model="announcement">Veranstaltungsankündigung</b-form-checkbox>
      <b-form-select v-if="announcement" v-model="vName" :options="['zur Kinderfreizeit', 'zur Jugendfreizeit', 'zur Fortbildung', 'zum Kindercamp', ' zum NAJU-Camp', 'zum Arbeitskreis', 'zur Ferienfreizeit']" />
      <b-textarea  v-model="text" class="my-2" placeholder="Text im Bild"/>
      <b-input v-if="announcement" v-model="city" placeholder="Ort" class="my-2"/>
      <b-form-row v-if="announcement" class="my-2">
        <b-col cols="6"><datepicker v-model="dateFrom" placeholder="Von"/></b-col>
        <b-col cols="6"><datepicker v-model="dateTo" placeholder="bis"/></b-col>
      </b-form-row>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import draggableImage from "../draggableImage.vue"
import html2canvas from 'html2canvas';
import dateViewer from "../dateViewer.vue"
import datepicker from "../datepicker.vue"

export default {
  name: 'InstaImgEditor',
  data(){
    return {
      image:null,
      preview: {
        posX:0,
        posY:0,
         scale:100
      },
      previewUrl: "",
      text: "",
      borderWidth:0,
      announcement:false,
      vName:"zur Kinderfreizeit",
      city: "",
      dateFrom: null,
      dateTo: null,
    };
  },
  props:["callback"],
  components:{
    draggableImage,
    dateViewer,
    datepicker
  },
  methods:{
    fetchBorderWidth(){
      var oldWidth = this.borderWidth;
      this.borderWidth = $(this.$refs.border).width();
      if(oldWidth == 0)
        return;
      this.preview.posX = this.preview.posX / oldWidth * this.borderWidth;
      this.preview.posY = this.preview.posY / oldWidth * this.borderWidth;
      //this.preview.posY = this.preview.posY / oldWidth * this.borderWidth;
    }
  },

  watch:{
    image(){
        console.log(this.image);
        var reader = new FileReader();
        var t = this;
        reader.onload = function(e) {
          console.log("readed");
          t.previewUrl = e.target.result;

        }
        reader.readAsDataURL(this.image);
    }
  },
  computed:{
    textWithBrs(){
        return this.text.replace("\n", "<br>");
    },
    fontSize(){
      return this.borderWidth / 350 *25 + "px";
    },
    borderRadius(){
      return this.borderWidth / 350 *10 + "px";
    },
    textBoxPadding(){
      return this.borderWidth / 350 *10 + "px";
    },
    lineHeight(){
      return this.borderWidth / 350 *25 + "px";
    }
  },
  created(){
    if(this.$route.query.text != null) this.text = this.$route.query.text;
    this.dateFrom = this.$route.query.dateFrom;
    this.dateTo = this.$route.query.dateTo;
    this.city = this.$route.query.city;
    
    if(this.$route.query.dateFrom != null) this.announcement = true;
    
    if(this.$route.query.type != null){
        if(this.$route.query.type == "kinder"){
            this.vName = "zur Kinderfreizeit";
        }
        else if(this.$route.query.type == "jugend"){
            this.vName = "zur Jugendfreizeit";
        }
        else if(this.$route.query.type == "multi"){
            this.vName = "zur Fortbildung";
        }
    }
  },
  mounted(){
    var t = this;
    t.fetchBorderWidth();

    this.callback.cb = function(){
      return new Promise((resolve, reject) => {
        $(t.$refs.border).css("position", "fixed");
        $(t.$refs.border).css("top", "0");
        $(t.$refs.border).css("width","1000px");
        t.fetchBorderWidth();
        t.$nextTick(function(){
          html2canvas(t.$refs.border).then(canvas => {
            var img    = canvas.toDataURL("image/jpeg");
            $(t.$refs.border).css("position", "relative");
            $(t.$refs.border).css("width","auto");
            t.fetchBorderWidth();
            resolve(img);
          });
        });
      });
    }
  }
}
</script>
<style scoped>
  .border{
    position: relative;
    width:100%;
    border: 1px solid #444;
    background:#000;
  }
  .dragableImage{
    padding-top:100%;
    height:0px;
    position:static;
  }

  .klammer{
    position: absolute;
    top:0;
    width: 40%;
    left: 5%;
    z-index: 8;
  }

  .textbox{
    position: absolute;
    right:4%;
    bottom: 4%;
    color: #fff;
    background: #F39315;

    font-weight: bold;
    font-family: "Source Sans Pro";
    text-align: center;
    transform: rotate(-2.5deg);
    max-width:93%;
    
  }
  .smallText{
    font-size: 80%;
    font-weight: normal;
  }
</style>


