<template>
    <div>
      <router-view v-if="user.logedIn"></router-view>
    </div>
</template>



<script>
import { mapState } from 'vuex'
import Searchfield from "./seminare/search.vue"
import login from "./login.vue"

export default {
  name: 'DefaultView',
  data(){
    return {

    };
  },
  props:[],
  components:{
    Searchfield,
    login
  },
  methods:{
    logOut(){
      var t = this;
      $.get(this.$root.api_root + "logout.php",{},function(resp){
        if(resp.indexOf("success") == 0){
          t.$store.dispatch("logedOut");

        }
      });
    }
  },
  mounted(){
  },
  created(){
    this.$store.dispatch("loadUser");
  },
  computed:{
    ...mapState({
      user: state => state.base.user,
    })
  }
}

</script>

<style scoped>

.navbar{
    background: #a6bd0c top center repeat;
    margin:0;
    border:0;
    border-radius:0;
}
.navbar-default .navbar-nav>li>a, .navbar-default .navbar-brand{
 color: #111;
}

#bgbox{
    margin:00px !important;
  background: url('./assets/title_blure.jpg') top center no-repeat;
  background-size:cover;
  width:100%;
  height: 100%;
  position: fixed;
  top:0;
  z-index: -10;
}

#main{
  padding:40px;
}

</style>
