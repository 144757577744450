
function base64url2base64(input) {
    input = input
        .replace(/=/g, "")
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const pad = input.length % 4;
    if (pad) {
        if (pad === 1) {
            throw new Error('InvalidLengthError: Input base64url string is the wrong length to determine padding');
        }
        input += new Array(5 - pad).join('=');
    }

    return input;
}

// convert base64 to array
function base64ToArray(value) {
    const $base64 = base64url2base64(value);
    return Uint8Array.from(atob($base64), c => c.charCodeAt(0));
}

// convert array to base64
function arrayToBase64(value) {
    return btoa(String.fromCharCode.apply(null, new Uint8Array(value)))
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=/g, "");
}

//extract JWT
function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return jsonPayload;
}

export {base64ToArray, arrayToBase64};