<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light">
        <!-- Brand and toggle get grouped for better mobile display -->
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <router-link :to="{path: '/'}" class="navbar-brand">NAJULytics</router-link>

        <!-- Collect the nav links, forms, and other content for toggling -->
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
              <template v-if="user.logedIn">
                <li class="nav-item active"><router-link :to="{path: '/'}" class="nav-link">Start<span class="sr-only">(current)</span></router-link></li>
                <li class="nav-item active" v-if="user.canViewStock"><router-link :to="{path: '/stock/'}" class="nav-link">Lagerverwaltung</router-link></li>
              </template>
            </ul>
            <ul class="navbar-nav">
                <li class="nav-item"><a class="nav-link" href="https://gitlab.com/naju-bayern/najulytics">Source Code</a></li>
                <li class="nav-item" v-if="user.logedIn"><a class="nav-link" @click="logOut">Logout</a></li>
            </ul>
        </div><!-- /.navbar-collapse -->
    </nav>
    <div id="bgbox"></div>
    <div id="main">
      <router-view v-if="user.logedIn || noLoginRequired"></router-view>
      <login v-else/>
    </div>
  </div>
</template>



<script>
import { mapState } from 'vuex'
import Searchfield from "./seminare/search.vue"
import login from "./login.vue"

export default {
  name: 'DefaultView',
  data(){
    return {

    };
  },
  props:{noLoginRequired: {default: false}},
  components:{
    Searchfield,
    login
  },
  methods:{
    logOut(){
      var t = this;
      $.get(this.$root.api_root + "logout.php",{},function(resp){
        if(resp.indexOf("success") == 0){
          t.$store.dispatch("logedOut");

        }
      });
    }
  },
  mounted(){
  },
  created(){
    this.$store.dispatch("loadUser");
  },
  computed:{
    ...mapState({
      user: state => state.base.user,
    })
  }
}

</script>

<style scoped>

.navbar{
    background: #a6bd0c top center repeat;
    margin:0;
    border:0;
    border-radius:0;
}
.navbar-default .navbar-nav>li>a, .navbar-default .navbar-brand{
 color: #111;
}

#bgbox{
    margin:00px !important;
  background: url('./assets/title_blure.jpg') top center no-repeat;
  background-size:cover;
  width:100%;
  height: 100%;
  position: fixed;
  top:0;
  z-index: -10;
}

#main{
  padding-top:40px;
  padding-bottom:100px;
}

</style>
