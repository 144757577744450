<template>
  <b-col cols="6" offset=2>
    <h1>(Kinder-) Gruppe bearbeiten</h1>
    <b-form-row class="my-2">
      <b-col cols="2">Name</b-col>
      <b-col cols="10"> <b-input v-model="gruppe.name" :disabled="!gruppe.editable"/></b-col>
    </b-form-row>
    <b-form-row class="my-2">
      <b-col cols="2">Beschreibun<br>(wird auf Website angezeigt)</b-col>
      <b-col cols="10"> <b-textarea v-model="gruppe.description" :disabled="!gruppe.editable" rows="8"/></b-col>
    </b-form-row>
    <b-form-row class="my-2">
      <b-col cols="2">Notizen<br>(wird nicht auf Website angezeigt)</b-col>
      <b-col cols="10"> <b-textarea v-model="gruppe.comments" :disabled="!gruppe.editable" rows="6"/></b-col>
    </b-form-row>
    <b-form-row class="my-2">
      <b-col cols="2">Treffpunkt</b-col>
      <b-col cols="10">
        <b-form-checkbox v-model="gruppe.geopos" :value="null" class="my-2" :disabled="!gruppe.editable">Treffpunkt unbekannt</b-form-checkbox>
        <placepicker v-if="gruppe.geopos != null" :geopos.sync="gruppe.geopos" :gen_geopos="gruppe.gen_geopos" :disabled="!gruppe.editable"/>
        <b-form-group class="my-2" v-if="gruppe.geopos != null" :disabled="!gruppe.editable">
          <b-form-radio v-model="gruppe.enteredGeoposIsExact" name="some-radios" :value="true">Der ausgewählte Treffpunkt stimmt exakt</b-form-radio>
          <b-form-radio v-model="gruppe.enteredGeoposIsExact" name="some-radios" :value="false">Es wurde nur grob der Ort markiert</b-form-radio>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row class="my-2">
      <b-col cols="2">Gründung</b-col>
      <b-col cols="10">
        <datepicker v-model="gruppe.founded" placeholder="Unbekannt" :disabled="!gruppe.editable"/>
      </b-col>
    </b-form-row>
    <b-form-row class="my-2">
      <b-col cols="2">Leitung</b-col>
      <leitung :gruppe="gruppe"/>
    </b-form-row>

    <saveBtn ref="saveBtn" :saveable="gruppe" :disabled="!gruppe.editable" api="kindergruppen/saveKindergruppe.php" @saved="setId" class="mx-1"/>
    <b-button variant="danger" @click="del" v-if="gruppe.editable" class="mx-1">Gruppe löschen</b-button>
  </b-col>

</template>


<script>
import saveBtn from '../saveBtn.vue'
import datepicker from "../datepicker.vue"
import leitung from "./editKindergruppenLeitung.vue"
import placepicker from "./placepicker.vue"

export default {
  name: 'EditKindergruppe',
  data(){
    return {
      gruppe:{
        name:"",
        leiter:[],
        editable: false,
        enteredGeoposIsExact: false
      },
      fields:[
        {key:"name", sortable:true},
        {key:"type", label:"Funktion"}
      ]
    }
  },
  components:{
    saveBtn,
    datepicker,
    leitung,
    placepicker
  },
  methods:{
    loadGruppe(id){
      var t = this;
      if(id=="new"){
        t.gruppe = {
          id:"new",
          name:"",
          leiter: [],
          editable: 2,
          enteredGeoposIsExact: false
        };
        if(this.$refs.saveBtn != null)
          this.$refs.saveBtn.reset();
        return;
      }
      $.get(this.$root.api_root + "kindergruppen/getKindergruppe.php",{
        id: id,
        token: token
      },function(res){
        t.$root.checkRespForLoginFail(res);
        if(res.trim().indexOf("not found") == 0){
          alert("Eintrag nicht gefunden");
          t.gruppe = {};
          return;
        }
        t.gruppe = JSON.parse(res);
        t.$refs.saveBtn.reset();
      });
    },
    setId(resp){
      this.gruppe = JSON.parse(resp);
      this.$refs.saveBtn.reset();
    },
    del(){
      var t = this;
      if(!confirm("Willst du diese Gruppe wirklich löschen?") || !confirm("Wirklich wirklich?"))
        return;
      $.get(this.$root.api_root + "kindergruppen/deleteKindergruppe.php",{
        id: this.gruppe.id
      },function(res){
        t.$root.checkRespForLoginFail(res);
        t.$router.push("/");
      });
    },
  },
  created(){
    this.loadGruppe(this.$route.params.id);
  },


  beforeRouteUpdate (to, from, next) {
    if(! this.$refs.saveBtn.confirmLeave()){
      next(false);
      return;
    }
    this.loadGruppe(to.params.id);
    next();
  }
}

</script>

<style scoped>


</style>
