<template>
    <div class="potBox">
      <div class="mask">
        <div class="fill" v-bind:style="{height: heightSpent, background: colorSpent}"></div>
        <div class="soll" v-bind:style="{height: heightShould}"></div>
        <span class="spent" v-if="amount- spent < 0">{{Math.round(amount- spent)}}€</span>
      </div>
      <img src="./potOutline.png" class="potOutline" v-if="amount - spent >= 0"/>
      <img src="./potOutlineRed.png" class="potOutline" v-else/>
    </div>
</template>

<script>

import { mapState } from 'vuex'

export default {

  name: 'PotDiagramm',
  props:{
    amount: {default: 100},
    spent: {default: 80},
    should: {default: 60}
  },
  data(){
    return{

    }
  },
  computed:{
    heightSpent(){
      return this.per2height(1 - (this.spent / this.amount));
    },
    colorSpent(){
      var per =  1 - (this.spent / this.amount);
      if(per > 0.80)
        return "#93a80b";
      else if(per > 0.50)
        return "#a8b939";
      else if(per > 0.25)
        return "#d6ce00";
      else if(per > 0.10)
        return "#d67e00";
      else return "#d63200";

    },
    heightShould(){
      return this.per2height(1 - (this.should / this.amount));
    },
  },
  methods:{
    per2height(per){
      var ret = 4 + (per) *79;
      if(ret < 0)
        ret = 0;
      return ret + "%";
    }
  }
}
</script>
<style scoped>
.potBox{
  width:100%;
  position: relative;
  top: 0;
  left:0;

}

.potOutline{
  width:100%;
  z-index:2;
}

.mask{
  position: absolute;
  top:0;
  left:0;
  height:100%;
  width: 100%;
  z-index:2;
  -webkit-mask-image: url("./potMask.png");
  -webkit-mask-size: 100%;
  mask-image: url("./potMask.png");
  mask-size: 100%;
}

.fill{
  position: absolute;
  bottom:0;
  left:0;
  width: 100%;
  background: green;
}

.soll{
  position: absolute;
  bottom: 0;
  left:0;
  width:100%;
  height:50%;
  border-top: dashed;
  color: #000;
}

.spent{
  position: absolute;
  top:50%;
  left:0%;
  width:100%;
  text-align: center;
  color: #d20000;
  font-weight: bold;
  font-size: 1.5em;
}

</style>
