<template>
  <ul>
    <li v-for="i in found">
      <router-link :to="{ name: 'editItem', params: { iid: i.iid }}" v-bind:class="{notMatched: !i.matched}">{{i.label}}</router-link>
      <ShowList v-if="Object.keys(i.children).length > 0" :found="i.children"/>
    </li>
  </ul>
</template>


<script>

export default {
  name: 'ShowList',
  data(){
    return {};

  },
  props:["found"],
}

</script>


<style>

.notMatched{
  font-style: italic;
}
</style>
