<template>
  <span>
  <span ref="icon" v-if="email in defektEmails" class="defektEmailLink oi oi-warning" v-b-popover.hover="errorStr" title="E-Mails an diese Adresse konnten nicht zugestellt werden:"></span>
  </span>
</template>


<script>
export default {
  name: 'EmailErrorChecker',
  data(){
    return {
      showModal: true
    };
  },
  props:{
    email:{default:""},
    defektEmails: {default: function(){return []}}
  },
  computed:{
    errorStr(){
      if(this.defektEmails == null)
        return "";
      return this.defektEmails[this.email]
    }
  }
}

</script>

<style scoped>
  .defektEmailLink{
    color: #cc0000;
    cursor: pointer;
  }
</style>
