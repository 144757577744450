<template>
  <div>
    <b-button variant="primary" @click="copy">Kopieren</b-button><br><br>
    <b-alert variant="success"
            dismissible
            :show="showCopyedAlert"
            @dismissed="showCopyedAlert=false">
      <b>Kopiert!</b> Die Tabelle wurde in die Zwischenablege kopiert. Du kannst sie jetzt in Word oder Exel einfügen
    </b-alert>
  </div>
</template>


<script>



export default {
  name: 'TableCopyBtn',
  data(){
    return {
      showCopyedAlert: false,
      prevVal: null
    };
  },
  props:["table", "fields"],
  methods:{
    copy(){
      this.prevVal = this.fields[this.fields.length - 1].checked;
      this.fields[this.fields.length - 1].checked = false; //hide action row
      setTimeout(() => {
        var range = document.createRange();
        range.selectNode(this.table.$el);
        var selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("Copy");
        selection.removeAllRanges();
        this.showCopyedAlert = true;
        this.fields[this.fields.length - 1].checked = this.prevVal; //show action row
      }, 100);
    }
  }
}

</script>

<style scoped>


</style>


