<template>
  <div id="titlepageWrapperBox" v-bind:class="{withBjrLogo: page.options != null && page.options.showBjrLogo}">
    <dragableImage v-model="page.images.image1" fullHeight="true" :hq="true"></dragableImage>
    <div id="welle"></div>
    <div id="welleExtension" ></div>
    <div id="zielgruppenBox">
        <div id="zielgruppenText">{{page.options.handzettelType}}</div>
    </div>

    <div id="contentBox">
      <div class="headline" v-bind:class="{muchText: (page.headline.length > 29)}">{{page.headline}}</div>
      <InfoBox :page="page" :rightOriented="true" ></InfoBox>
    </div>
    <img id="najulogo" src="../logo.svg" />
    
    <imgRightsBox :images="page.images" :rightOriented="true"></imgRightsBox>
  </div>
</template>


<script>
import DragableImage from '../draggableFlyerImage.vue'
import InfoBox from '../infoBox.vue'
import FlyerTextBox from '../flyerTextBox.vue'
import imgRightsBox from '../imgRightsBox.vue'

export default {
  name: 'handzettelFront',
  data () {
    return {
    }
  },
  props:["page","themeColor", "result"],
  components: {
    'dragableImage': DragableImage,
    InfoBox,
    FlyerTextBox,
    imgRightsBox
  },
}
</script>

<style scoped>

#titlepageWrapperBox{
  width: 111mm;
  height: 154mm;
  font-size: 12pt;
  line-height:1.0;
  background: var(--themeColor);
}
#welle{
  position: absolute;
  z-index: 2;
  top: 80mm;
  right: 0;
  width: 100%;
  background: var(--themeColor);
  height: 25%;
  -webkit-mask-image: url(../welle.svg);
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: auto 100%;
}

#welleExtension{
    position: absolute;
    bottom:0px;
    left:0px;
    width: 100%;
    height:40mm;
}

#zielgruppenBox{
  position: absolute;
  z-index: 2;
  top: 0mm;
  left: 0;
  width: 30%;
  height: 30mm;
  -webkit-mask-image: url(./zielgruppenBox.svg);
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: 100% auto;
  background: var(--themeColor);
  pointer-events: none;
}

#zielgruppenText{
    font-family:"AmaticSC";
    font-weight: bold;
    transform: rotate(-17deg);
    font-size: 15pt;
    position:absolute;
    top: 8mm;
    right: 4mm;
    pointer-events: none;
}


#titlepageWrapperBox >>> .flyerImage{
  height:100mm;
  position:absolute;
  top:0;
}

.headline{
  font-size: 20pt;
  font-weight:bold;
  margin-bottom: 5px;
  position: absolute;
  left: 10mm;
  bottom: 44mm;
  z-index:5;
}

.muchText.muchText{
    font-size: 17pt;
}

.subheadline{
  font-weight:bold;
  margin-top:-10px;
  font-size: 18pt;
  margin-bottom: 5px;
}


#najulogo{
  position: absolute;
  z-index:5;
  width: 26mm;
  bottom: 8mm;
  right: 10mm;
  left:auto;
  top: auto;
}


#titlepageWrapperBox >>> #infoTable{
  margin-top:inherit;
  position:absolute;
  font-size:inherit;
  top:417px;
  left:10mm;
  z-index:5;
}

#titlepageWrapperBox >>> #infoTable .left{
  font-weight: bold;
}

#titlepageWrapperBox >>> #infoTable .right{
  padding-left:20px;
}

#contentBox >>> #textBox{
  font-size:inherit;
  padding:0;
  margin:0;
}

#titlepageWrapperBox >>> #imgRightsBox{
  z-index:5;
}

#titlepageWrapperBox >>> #imgRightsBox.left{
  right:20mm;
}

#imgRightsBox.right{
    left: 10mm;
}

</style>
