<template>
  <b-row>
    <b-col cols="4"> <b-form-select v-model="feederbowlId" :options="feederbowls"></b-form-select> </b-col>
    <b-col cols="4"> <b-form-select v-model="feederbowlYearId" :options="feederbowlYears" :disabled="feederbowlId == null"></b-form-select> </b-col>
    <b-col cols="4"> <b-form-select v-model="subfeederbowlId" :options="subfeederbowls" :disabled="feederbowlYearId == null" required></b-form-select> </b-col>
  </b-row>
</template>


<script>

import { mapState } from 'vuex'

export default {
  name: 'FeederbowlPicker',
  props:["value"],
  data(){
    return {
      feederbowlId:null,
      feederbowlYearId:null,
      feederbowlYears:[],
      subfeederbowls:[],
      subfeederbowlId:null,
      doNotSetIds: false
    };
  },
  created(){
    this.$store.dispatch('loadFeederbowls');
    this.setSubfeederbowl(this.value);
  },
  mounted() {

  },
  methods:{
    setSubfeederbowl(v){
      var t = this;
      if(v == this.subfeederbowlId)
        return;
      this.feederbowlId = null;
      this.feederbowlYearId = null;
      this.subfeederbowlId = null;

      if(v == null) return;

      $.get(this.$root.api_root + "fundmonitor/getSubfeederbowl.php",{
        id: v
      },function(res){
        t.$root.checkRespForLoginFail(res);
        res = JSON.parse(res);
        t.doNotSetIds = true;
        t.feederbowlId = res.feederbowl.id;
        t.feederbowlYearId = res.feederbowlYear.id;
        t.subfeederbowlId = v;
      });
    }
  },
  computed:{
    ...mapState({
      feederbowlsRaw: state => state.fund.feederbowls,
    }),
    feederbowls(){
      return this.feederbowlsRaw.map(each => {
        return {
          value: each.id,
          text: each.name
        };
      });
    },
  },
  watch:{
    value(v){
      this.setSubfeederbowl(v);
    },
    feederbowlId(newId){
      var t = this;
      if(!t.doNotSetIds){
        t.feederbowlYearId = null;
      }
      if(newId == null){
        t.feederbowlYears = [];
        return;
      }
      $.get(this.$root.api_root + "fundmonitor/getFeederbowl.php",{
        id: newId
      },function(res){
        t.$root.checkRespForLoginFail(res);
        t.feederbowlYears = JSON.parse(res).years.map(each => {
          return {
            value: each.id,
            text: each.name,
            start: each.start
          };
        });

        if(!t.doNotSetIds){
          var newestYear = t.feederbowlYears.reduce(function(acc, cur){
            if(acc == null || acc.start < cur.start){
              return cur;
            }
            return acc;
          },null);
          if(newestYear != null){
            t.feederbowlYearId = newestYear.value;
          }
        }

      });
    },

    feederbowlYearId(newId){
      var t = this;
      if(!t.doNotSetIds){
        t.subfeederbowlId == null;
      }
      if(newId == null){
        t.subfeederbowls = [];
        return;
      }
      $.get(this.$root.api_root + "fundmonitor/getFeederbowlYear.php",{
        id: newId
      },function(res){
        t.$root.checkRespForLoginFail(res);
        t.subfeederbowls = JSON.parse(res).subfeederbowls.map(each => {
          return {
            value: each.id,
            text: each.name,
          };
        });

        if(!t.doNotSetIds){
          var defaultSubfeederbowl = t.subfeederbowls.find(each => {
            if(each.text == "Standard")
              return each;
          });

          if(defaultSubfeederbowl != null){
            t.subfeederbowlId = defaultSubfeederbowl.value;
          }
        }
        t.doNotSetIds = false;

      });
    },

    subfeederbowlId(newId){
      if(newId != this.value){
        this.$emit("input", newId);
      }
    }
  }
}

</script>

<style scoped>



</style>

