<template>
  <div >

    <div class="form-group row">
      <div for="name" class="col-md-2 col-form-label">Bearbeiter</div>
      <div class="col-md-4">
        <ul>
          <li v-for="owner, index in list.owners">{{owner.name}} <span class="oi oi-trash btn-link" @click="removeOwner(owner, index)" v-if="! owner.isMe && list.editable"/></li>
        </ul>
      </div>
    </div>

    <div class="form-group row" v-show="list.editable">
      <div for="name" class="col-md-2 col-form-label"></div>
      <div class="col-md-5">
        <input ref="userSearch" v-model="searchUser" type="text" class="form-control" placeholder="Bearbeiter hinzufügen"/>
      </div>
    </div>

  </div>
</template>


<script>


export default {
  name: 'EditListOwner',
  data(){
    return {
      searchUser: ""
    };
  },
  props:["list"],
  methods:{
    removeOwner(owner, index){
      var t = this;
      $.get(t.$root.api_root + "stock/removeListOwner.php",{
        list: t.list.id,
        owner: owner.id
      },function(data){
        t.$root.checkRespForLoginFail(data);
        t.list.owners.splice(index, 1);
      });
    }
  },
  mounted(){
  var t = this;
  console.log("mount");
    $(this.$refs.userSearch).autocomplete({
        minLength: 1,
        source: function( request, response ) {
            $.ajax( {
                url: t.$root.api_root + "searchUser.php",
                data: {
                    startingWith: request.term,
                    excluding: JSON.stringify(t.list.owners.map(function(o){
                      return o.id
                    }))
                },
                success: function( data ) {
                    t.$root.checkRespForLoginFail(data);
                    data = JSON.parse(data);
                    response( data );
                }
            } );
        },
        select:function(event,ui){
            console.log(ui.item.uid);

            $.get(t.$root.api_root + "stock/addListOwner.php",{
              list: t.list.id,
              owner: ui.item.uid
            },function(data){
              t.$root.checkRespForLoginFail(data);
              t.list.owners.push({
                id: ui.item.uid,
                name: ui.item.label,
                isMe:false
              });
              t.searchUser = "";
            });
        }
    });
  }
}

</script>

<style scoped>



</style>

