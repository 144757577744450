<template>
    <div id="ageBox">
        <div class="form-group row">
            <div for="age" class="col-md-2 col-form-label">Alter</div>
            <div class="col-md-7">
                <div class="input-group fromTo">
                    <div class="input-group-prepend" style=" text-align: left;">
                      <div class="input-group-text" style="width: 70px; text-align: left;">
                        <input type="radio" name="ageType" v-on:click="page.ageFree = null; page.ageTo = page.ageFrom + 4;" v-model="method" value="fromTo" :disabled="!page.locked">&nbsp; von
                      </div>
                    </div>
                    <input type="number" v-model="page.ageFrom" :disabled="method != 'fromTo' || !page.locked" class="form-control" aria-label="...">
                    <div class="input-group-prepend input-group-append">
                      <span class="input-group-text">bis</span>
                    </div>
                    <input type="number" v-model="page.ageTo" :disabled="method != 'fromTo' || !page.locked" class="form-control" aria-label="...">
                    <div class="input-group-append">
                      <span class="input-group-text">Jahren</span>
                    </div>

                </div><!-- /input-group -->
            </div>
        </div>

        <div class="form-group row">
            <div for="age" class="col-md-2 col-form-label"></div>
            <div class="col-md-7">
                <div class="input-group fromTo">
                    <div class="input-group-prepend" style=" text-align: left;">
                      <div class="input-group-text" style="width: 70px; text-align: left;">
                          <input type="radio" name="ageType" @click="page.ageFree = null; page.ageTo = null" v-model="method" value="from" :disabled="!page.locked" />&nbsp; ab
                      </div>
                    </div>
                    <input id="ageFrom" name="ageFrom2" type="number" class="form-control" :disabled="method != 'from' || !page.locked" v-model="page.ageFrom" aria-label="...">
                    <div class="input-group-append">
                      <span class="input-group-text">Jahren</span>
                    </div>

                </div><!-- /input-group -->
            </div>
        </div>

        <div class="form-group row">
            <div for="age" class="col-md-2 col-form-label"></div>
            <div class="col-md-7">
                <div class="input-group">
                  <div class="input-group-prepend" style=" text-align: left;">
                    <div class="input-group-text" style=" text-align: left; width: 70px">
                        <input type="radio" name="ageType" @click="page.ageFree=''" v-model="method"  value="free" aria-label="..." :disabled="!page.locked">
                    </div>
                  </div>
                  <input id="ageFreetext" type="text" name="ageFree" class="form-control" v-model="page.ageFree" :disabled="method != 'free' || !page.locked">

                </div><!-- /input-group -->
            </div>
        </div>
    </div>
</template>
<script>

export default {
  name: 'Ageeditor',
  data(){
    return {
    };
  },
  props: ['page'],
  components: {

  },
  computed:{
    method(){
      if(this.page.ageFree != null)
        return "free";
      if(this.page.ageTo != null)
        return "fromTo";
      else
        return "from";
    }
  },
  updated(){
    if(this.method != 'free')
      this.page.ageFree = null;
    if(this.method != 'fromTo')
      this.page.ageTo = null;
    if(this.page.ageTo == "")
      this.page.ageTo = null;
    if(this.page.ageFrom == "")
      this.page.ageFrom = null;
  }
}
</script>
