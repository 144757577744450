<template>
    <div>
        <ul>
            <li v-for="(key, index) in keys" v-bind:key="key.id">
                {{key.name}} <span class="oi oi-trash btn-link" @click="deleteKey(key, index)"></span>
            </li>
        </ul>
        <addFido2Key :user="user" @keyAdded="addKey"/>
    </div>
</template>

<script>
    import addFido2Key from './addFido2Key.vue'

    export default {
        name: "fido2Keys.vue",
        components: {
            addFido2Key
        },
        methods: {
            loadKeys() {
                if(this.user.id == null){
                    return;
                }
                var t = this;
                $.post(this.$root.api_root + "fido2/getUsersFido2Keys.php", {
                        "user_id": this.user.id
                    },
                    function (res) {
                        t.$root.checkRespForLoginFail(res);
                        t.keys = JSON.parse(res);
                    });
            },
            deleteKey(key, index){
                if(this.keys.length < 2 && this.user.useTwoFactor){
                    alert("Dieser Benutzer darf sich nur mit Sicherheitschlüssel einlogen. Daher wäre es dumm den letzten Schlüssel zu löschen. Füge zuerst einen anderen Schlüssel hinzu.");
                    return;
                }
                if(!confirm("Schlüssel '" + key.name + "' wirklich löschen?")){
                    return;
                }
                var t = this;
                $.post(this.$root.api_root + "fido2/fido2dropKey.php",{
                    id: key.id
                },function (res) {
                    t.$root.checkRespForLoginFail(res);
                    if(res.indexOf("success") === -1){
                        alert("Fehler: " + res);
                    }
                    t.keys.splice(index);
                });
            },
            addKey(key){
                this.keys.push(key)
            }
        },
        props: ["user"],
        data() {
            return {
                keys: []
            };
        },
        created() {
            this.loadKeys();
        },
        watch:{
            "user":{
                handler(){
                    this.loadKeys();
                }
            }
        }
    }
</script>

<style scoped>

</style>