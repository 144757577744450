export default {
  state: {
    years: null,
  },
  mutations: {
    setHaushaltYears(state, years){
       state.years= years;
    }
  },
  actions: {
    loadHaushaltYears({ commit, state }, force = false){
      var t = this;
      if(state.years != null && !force)
       return;
      $.get(api + "haushalt/getYears.php",{
      },function(res){
        t.checkRespForLoginFail(res);
        commit("setHaushaltYears", JSON.parse(res));
      });
    },
  }
}
