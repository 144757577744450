<template>
        <div >
           <imageEditor :image='page.images.image1' imgName="Bild 1" v-on:deleted="swapImages()" :disabled="!page.locked"></imageEditor>
           <imageEditor v-if="hasImage2" :image='page.images.image2' imgName="Bild 2" :disabled="!page.locked"></imageEditor>
           <button v-if="hasImage2 && issetImage2" v-on:click="swapImages()" type="button" class="btn btn" style="margin-top: 5px;" :disabled="!page.locked">
           <span class="oi oi-random"></span>Bilder tauschen</button>
           <imageEditor v-if="hasRightImage" :image='page.images.rightImage' imgName="Bild rechts" :disabled="!page.locked"></imageEditor>
           <label v-if="hasRightImage"><input type="checkbox" v-model="page.images.bigRightImage"/> Großes rechtes Bild</label>
           <p v-if="page.template == 'semSinglePage' || page.template == 'semDoublePage'">
            <b>Weitere Bilder:</b><br>
            <small>Diese Bilder werden nicht angezeigt, sie können später für Ferienbörsen verwendet werden.</small>
            <b-row>
              <b-col v-for="image, index in page.images.moreImages" v-bind:key="image.imid" class="col-md-6">
                <b-card :img-src="$root.api_root + 'flyerImages/scaled/flyerImage-' + image.imid + '-' + image.key + '.jpg'"  img-top>
                  <b-form-row>
                    <b-col class="col-xl-6 col-12 my-1"><b-input v-model="image.authorFirst" placeholder="z.B. Max" :disabled="!page.locked"/></b-col>
                    <b-col class="col-xl-6 col-12 my-1"><b-input v-model="image.authorLast" placeholder="z.B. Müller" :disabled="!page.locked"/></b-col>
                  </b-form-row>
                  <b-form-row class="my-1">
                    <b-col class="col-md-6">
                      <b-checkbox  v-model="image.canBeUsedOnFacebook" :disabled="!page.locked" inline><img src="../assets/Logo_Facebook.png" title="Bild darf auf Facebook verwendet werden" style="height: 1.2em;"/></b-checkbox>
                      <a :href='$root.api_root + "flyerImages/flyerImage-" + image.imid + "-" + image.key + "." + image.fileType' target="_blank"><span class="oi oi-cloud-download mx-2"></span></a>
                    </b-col>
                    <b-col class="col-md-6">
                      <button type="button" @click='rmImage(index)' class="btn btn-danger oi oi-trash" :disabled="!page.locked"></button>
                    </b-col>
                  </b-form-row>
                </b-card>
              </b-col>
            </b-row>
            <imageUpload v-on:uploaded="addImage" imgName="Weiteres Bild" :disabled="!page.locked" class="my-2"></imageUpload>
          </p>
        </div>
</template>
<script>
import imageEditor from './imageEditor.vue'
import ImageUpload from './imageUpload.vue'
export default {
  name: 'ImagesEditor',
  data(){
    return {
    };
  },
  props: ['page'],
  components: {
    imageEditor,
    ImageUpload
  },
  methods:{
    swapImages(){
      if(this.page.images.image2.imid == null)
        return;
      var tmpImg = this.page.images.image1;
      this.page.images.image1 = this.page.images.image2;
      this.page.images.image2 = tmpImg;
    },
    addImage(imid, key,fileType, authorF, authorL, canBeUsedOnFacebook){
      this.page.images.moreImages.push({
        imid: imid,
        key: key,
        fileType: fileType,
        authorFirst: authorF,
        authorLast: authorL,
        posX: 0,
        posY: 0,
        mirror: false,
        scale: null,
        canBeUsedOnFacebook: canBeUsedOnFacebook
      });
    },
    rmImage(index){
      this.page.images.moreImages.splice(index,1);
    }
  },
  computed:{
    hasImage2(){
      return (this.page.template == 'semSinglePage' || this.page.template == 'semDoublePage') && this.issetImage1;
    },
    hasRightImage(){
      return this.page.template == 'semDoublePage';
    },
    issetImage1(){
      return this.page.images.image1.imid != null;
    },
    issetImage2(){
      return this.page.images.image2.imid != null;
    },
  }
}
</script>
