<template>
  <b-col cols="6" offset="2">
    <h1>Seminarliste</h1>
    <router-link :to="{name: 'advStatistics'}">Statistiken</router-link>
    <b-row>
      <b-col cols="3">
          <b-form-select v-model="year" :options="avYears" />
      </b-col>
    </b-row>
    <br>
    <b-row>
      <b-form-checkbox id="hideOver" v-model="hideOver" class="my-2">Verstecke Beendete</b-form-checkbox>
      <b-table :items="filteredList" :fields="fields" @row-clicked="rowClicked" striped>
        <template v-slot:cell(seminarTitel)="data">
          {{data.value}} <span v-if="data.item.incomplete" class="incomplete"><span class="oi oi-warning"></span> unvollständig</span>
        </template>

        <template v-slot:cell(teilnehmer)="data">
          {{data.value}}
          <span class="oi oi-ban tnMessage" title="Voll" v-if="data.item.tnState == 'full'"></span>
          <span class="oi oi-check tnMessage" title="Genug Teilnehmer" v-if="data.item.tnState == 'ok'"></span>
          <span class="oi oi-check tnMessage nearlyFull" title="Fast voll" v-if="data.item.tnState == 'ok-nearlyFull'"></span>
          <span class="oi oi-warning tnMessage" title="Zu wenig Teilnehmer" v-if="data.item.tnState == 'tooFew'"></span>
        </template>

        <template v-slot:cell(letzteAnmeldung)="data">
          {{new Date(data.value).toLocaleDateString('de-DE')}}
        </template>

        <template v-slot:cell(start)="data">
          {{data.value == "" ? "" : new Date(data.value).toLocaleDateString('de-DE')}}
        </template>
      </b-table>
    </b-row>
  </b-col>
</template>



<script>
var avYears = [];

export default {
  name: 'Seminarlist',
  data(){
    return {
      seminare:[],
      avYears: avYears,
      year: "",
      fields:[
        {key:"seminarTitel", sortable: true},
        {key:"teilnehmer", sortable: true},
        {key:"letzteAnmeldung", sortable: true},
        {key:"start", sortable: true}
      ],
      hideOver: true
    };
  },
  created(){
    var t = this;
    if(avYears.length == 0){
      avYears = [];
      $.get(this.$root.api_root + "seminare/getAvYears.php",{},function(res){
        t.$root.checkRespForLoginFail(res);
        t.avYears = JSON.parse(res);
        t.year = t.avYears[t.avYears.length - 1];
      });
    }
    else{
      t.year = t.avYears[t.avYears.length - 1];
    }
  },
  watch:{
    year(){
      var t = this;

      if(t.year != new Date().getFullYear())
        t.hideOver = false;

      $.get(this.$root.api_root + "seminare/get_seminarliste.php",{
        year: t.year
      },function(res){
        t.$root.checkRespForLoginFail(res);
        t.seminare = JSON.parse(res);
      });
    }
  },
  methods:{
    rowClicked(row){
      this.$router.push({ name: 'seminarInfo', params: { sid: row.sid } })
    }
  },
  computed:{
    filteredList(){
      if(this.hideOver){
        var now = Date.now();
        return this.seminare.filter(sem => sem.start == "" || sem.start > now);
      }
      return this.seminare;
    }
  }


}

</script>

<style scoped>
  >>> tbody {
    cursor:pointer;
  }

  table{
    background: #fff;
  }

  .incomplete{
    float:right;

  }

  .tnMessage{
    float:right;
    margin-right:5px;

  }
  .incomplete span{
    color:#ff9933;
  }

  .nearlyFull{
    color:#ff9933;
  }

</style>

