<template>
  <b-col cols="6" offset="2">
    <h1>{{user.name | capitalize}} bearbeiten</h1>

    <b-form-row class="my-2" v-if="user.id=='new'">
      <b-col cols="2">Nutzername</b-col>
      <b-col cols="10"> <b-input v-model="user.name" /></b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Flyer</b-col>
      <b-col cols="10"> <b-form-checkbox v-model="user.canFlyer">kann Flyer bearbeiten</b-form-checkbox></b-col>
    </b-form-row>
    
    <b-form-row class="my-2">
      <b-col cols="2">Instagram</b-col>
      <b-col cols="10"> <b-form-checkbox v-model="user.canInsta">kann Instaramm Bilder erstellen</b-form-checkbox></b-col>
    </b-form-row>
    
    <b-form-row class="my-2">
      <b-col cols="2">Umfragen</b-col>
      <b-col cols="10"> <b-form-checkbox v-model="user.canPoll">kann alle Umfragen sehen und bearbeiten</b-form-checkbox></b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Seminare und Aktivendatenbank</b-col>
      <b-col cols="10">
        <b-form-checkbox v-model="user.canViewSeminare" @input="updateSem">kann Seminaranmeldungen sehen</b-form-checkbox><br>
        <b-form-checkbox v-model="user.canEditSeminare" :disabled="!user.canViewSeminare">kann Seminaranmeldungen bearbeiten</b-form-checkbox>
        <b-form-checkbox v-model="user.canEditKindergruppen">kann Kindergruppen bearbeiten</b-form-checkbox>
      </b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Lagerverwaltung</b-col>
      <b-col cols="10">
        <b-form-checkbox v-model="user.canViewStock" @input="updateStock">kann Lager sehen</b-form-checkbox><br>
        <b-form-checkbox v-model="user.canEditStock" :disabled="!user.canViewStock">kann Lager bearbeiten</b-form-checkbox>
      </b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Administrator</b-col>
      <b-col cols="10"> <b-form-checkbox v-model="user.isAdmin">ist Administrator</b-form-checkbox></b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Führugszeugnisse</b-col>
      <b-col cols="10">
        <b-form-checkbox v-model="user.canSeeCriminalRecordCertificate">Kann Führugszeugnissstatus sehen</b-form-checkbox>
        <b-form-checkbox v-model="user.canEditCriminalRecordCertificate" v-if="user.canSeeCriminalRecordCertificate">Kann Führugszeugnissstatus bearbeiten</b-form-checkbox>
      </b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Haushalt</b-col>
      <b-col cols="10">
        <b-form-checkbox v-model="user.canCreate" class="my-1">kann eigene Projekte erstellen</b-form-checkbox>
        <b-form-checkbox v-model="user.canViewBill"  class="my-1">kann Rechnungen sehen</b-form-checkbox>
      </b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Förderübersicht</b-col>
      <b-col cols="10">
        <b-form-checkbox v-model="user.canViewFundMonitor" class="my-1">kann Förderübersicht einsehen</b-form-checkbox>
        <b-form-checkbox v-model="user.canEditFundMonitor" v-if="user.canViewFundMonitor" class="my-1">kann Förderübersicht bearbeiten</b-form-checkbox>
      </b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Passwort</b-col>
      <b-col cols="10">
        <b-form-checkbox v-model="user.isPwEditable">kann sein Passwort selber ändern</b-form-checkbox>
        <b-input type="password" name="userAdminPassword" v-model="user.password" placeholder="Passwort unverändert lassen" no-autocomplete autocomplete="off"/>
      </b-col>
    </b-form-row>
    
    <b-form-row class="my-2">
      <b-col cols="2">2-Faktor</b-col>
      <b-col cols="10">
        <b-form-checkbox v-model="user.useTwoFactor" class="my-1">Zweiten faktor beim Login erzwingen</b-form-checkbox>
        <fido2-keys :user="user"></fido2-keys>
      </b-col>
    </b-form-row>

    <saveBtn ref="saveBtn" :saveable="user" api="saveUser.php" @saved="setId" class="mx-1"/>
    <b-button variant="danger" v-if="user.id != 'new'" @click="del" class="mx-1">Löschen</b-button>
  </b-col>
</template>


<script>
import saveBtn from '../saveBtn.vue'
import fido2Keys from "./fido2Keys.vue"

export default {
  name: 'User',
  components:{
    fido2Keys,
    saveBtn,
  },
  data(){
    return {
      user:{
        name:""
      }
    };
  },
  props:[],
  created(){
    this.loadUser(this.$route.params.uid);
  },
  methods:{
    loadUser(id){
      if(id == "new"){
        this.user = {
          id:"new",
          canCreate: false,
          isAdmin: false,
          canViewBill: false,
          isPwEditable: true,
          canFlyer: false,
          canViewStock: false,
          canEditStock: false,
          canViewSeminare: false,
          canEditSeminare: false,
          canEditKindergruppen: false,
          canSeeCriminalRecordCertificate: false,
          canEditCriminalRecordCertificate: false,
          canViewFundMonitor: false,
          canEditFundMonitor: false,
          canInsta: false,
          canPoll: false,
          useTwoFactor: false
        }
        return;
      }

      var t = this;
      $.get(this.$root.api_root + "getUser.php",{
        id: id
      },function(res){
        t.$root.checkRespForLoginFail(res);
        if(res.trim().indexOf("not found") == 0){
          alert("Eintrag nicht gefunden");
          t.funktion = {};
          return;
        }
        t.user = JSON.parse(res);
        t.$refs.saveBtn.reset();
      });
    },
    updateSem(v){
      if(!v)
        this.user.canEditSeminare = false;
    },

    updateStock(v){
      if(!v)
        this.user.canEditStock = false;
    },
    setId(id){
      this.user.id = id *1;
      this.$refs.saveBtn.reset();
      this.$store.dispatch("loadUser",true);
    },
    del(){
      if(!confirm("Wirklich löschen?"))
        return;
      var t = this;
      $.get(this.$root.api_root + "deleteUser.php",{
        id: this.user.id
      },function(res){
        t.$root.checkRespForLoginFail(res);
        t.$router.push({ name: 'users'});
      });
    }
  },

  beforeRouteUpdate (to, from, next) {
    if(! this.$refs.saveBtn.confirmLeave()){
      next(false);
      return;
    }
    this.loadUser(to.params.uid);
    next();
  }

}
</script>


