
<template>
<div>
    <div v-if="selectedResult != null && selectedResultHastSonstiges" class="my-2">
        <h3>Details für {{selectedResult}}</h3>
        <b-table v-if="sonstiges.length > 0" :items="sonstiges" :fields="[{key:'name', sortable:true}, {key:'num', label:'Häufigkeit', sortable:true}]"/>
        <i v-else>Keine Daten vorhanden</i>
    </div>
</div>
</template>


<script>


export default {
  name: 'AdvertisementStatisticsPieDetails',
  data(){
    return {
        selectedResultHastSonstiges: false,
        sonstiges:[]
    }
  },
  props:["selectedResult", "semId"],
  components:{
    
  },
  methods:{

  },
  created(){
  },
  computed:{

  },
  watch:{
    selectedResult(label){
        var t = this;
        this.selectedResultHastSonstiges = ["facebook"].indexOf(label) != -1 || true;
        if(this.selectedResultHastSonstiges){
            $.get(this.$root.api_root + "seminare/getAdvMediumSonstigesStats.php",{
                medium: this.selectedResult,
                semId: this.semId
            },function(res){
                t.$root.checkRespForLoginFail(res);
                t.sonstiges = JSON.parse(res);
            });
        }
    }
  }
}

</script>
<style scoped>
>>> table{
    background:#fff;
}
</style>
