<template>
  <div id="leitungsBox" class="form-group row">
    <div for="name" class="col-md-2 col-form-label">
      Leitung
    </div>

    <div class="col-md-10">
    <draggable v-model="page.leitung" class="list-group" :options="{disabled: !page.locked}">
        <div class="row" style="margin-top: 10px" v-for="leitung in page.leitung">
          <div class='col-md-3'>
              <input class="form-control" type="text" v-model="leitung.vorname" placeholder="z.B. Max" :disabled="!page.locked">
          </div>
          <div class='col-md-3'>
              <input class="form-control" type="text" v-model="leitung.nachname" placeholder="z.B. Mustermann" :disabled="!page.locked">
          </div>
          <div class='col-md-3'>
              <input class="form-control" type="text" v-model="leitung.funktion" placeholder="z.B. Zauberer" :disabled="!page.locked">
          </div>
          <div class='col-md-3'>
              <button type="button" v-on:click='page.leitung.pop(leitung)' class="btn btn-danger delImgBtn" :disabled="!page.locked">Leitung löschen</button>
          </div>
        </div>
      </draggable>
      <button style="margin-top: 10px" type="button" class="btn" v-on:click="addLeitung()" :disabled="!page.locked">Leitung hinzufügen</button>
    </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
export default {
  name: 'Leitungseditor',
  data(){
    return {
    };
  },
  props: ['page'],
  components: {
    draggable
  },
  mounted(){
    if(this.page.leitung == null)
      this.page.leitung = [];
  },
  methods:{
    addLeitung(){
      this.page.leitung.push({vorname: "", nachname:"", funktion:""});
    }
  }
}
</script>
