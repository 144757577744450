<template>
    <div v-if="browserSupportsFido2">
        <b-modal
                ref="nameModal"
                title="FIDO2-Key hinzufügen"
                :ok-disabled="keyName.length === 0"
                @ok="register">
            <b-form-group label="Schlüsselname">
                <b-input v-model="keyName"/>
            </b-form-group>
        </b-modal>
        <b-button @click="$refs.nameModal.show()">2-Fakor Key hinzufügen</b-button>
    </div>
    <b-alert v-else :show="true" variant="warning">
        Dein Browser unterstützt keine FIDO2-Key. Probiere es mal mit einem aktuellen...
    </b-alert>
</template>


<script>
    import {Attestation} from "../fido2/attestation"

    export default {
        name: 'addFido2Key',

        props: {"user": {}},
        data() {
            return {
                browserSupportsFido2: null,
                keyName: "",
                error: ""
            };
        },
        created() {
            this.browserSupportsFido2 = window.PublicKeyCredential;
        },
        methods: {
            register() {
                let attestation = new Attestation(this.$root.api_root);
                attestation.register(this.user.id, this.keyName)
                    .then(this.keyWasAdded)
                    .catch((error) => alert("Es ist ein Fehler aufgetreten: " + error))
            },
            keyWasAdded(keyId){
                this.$emit("keyAdded",{id: keyId, name: this.keyName});
                this.keyName = "";
            }
        },
    }
</script>


