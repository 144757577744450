<template>
<div>
  <div class="row" v-if="!isDoublePage">
      <div class="offset-md-1 col-md-5">
          <h2>Flyer bearbeiten</h2>
          <flyereditor :page="page" :small="true" :result="result"></flyereditor>
      </div>
      <div class="col-md-5">
          <h2>Vorschau</h2>
          <flyerviewer :page="page" :result="result" previewMode="1"></flyerviewer>
      </div>
  </div>
  <div v-if="isDoublePage">
    <div class="row">
      <div class="offset-md-1 col-md-10">
          <h2>Flyer bearbeiten</h2>
          <flyereditor :page="page" :small="false" :result="result"></flyereditor>
      </div>
    </div>
    <div class="row">
      <div class="offset-md-1 col-md-10">
          <h2>Vorschau</h2>
          <flyerviewer :page="page" :result="result" previewMode="1"></flyerviewer>
      </div>
    </div>
  </div>
</div>
</template>


<script>
import Viewer from './show/flyerViewer.vue'
import FlyerEditor from './editor/flyerEditor.vue'


export default {
  name: 'PageEditor',
  data(){
    return {
      page:
        {
        rightOriented: false,
        headline: "",
        subheadline: "",
        text: "",
        shortText: "",
        images: {
          image1: {imid:null},
          image2: {imid:null}
        },
        textIsFinal: false,
        textAgreed: false,
        textSpellingOk: false,

      },
      result: {overflow: ""}
    };
  },
  mounted () {
    var parent = this;
    $.get(this.$root.api_root + "flyer/get_page.php",{
      pid: parent.$route.params.pid
    },function(data){
      parent.$root.checkRespForLoginFail(data);
      var page = JSON.parse(data);
      if(page.options == null || Array.isArray(page.options) )
        page.options = {};
      parent.page = page;
    });
  },
  props: [],
  components: {
    "flyerviewer": Viewer,
    "flyereditor": FlyerEditor
  },
  computed:{
    isDoublePage(){
      return this.page.template == 'semDoublePage' || this.page.template == 'index';
    }
  }
}

</script>

<style>



</style>

