<template>
  <flyerviewer ref='viewer' :page="page" :result="result" :previewMode="false"></flyerviewer>
</template>


<script>
import Viewer from './show/flyerViewer.vue'
import imagesLoaded from 'imagesloaded'
var htmlPdfDone = false;

export default {
  name: 'PageRenderer',
  data(){
    return {
      page:
        {
        rightOriented: false,
        headline: "",
        subheadline: "",
        text: "",
        images: {
          image1: {imid:null},
          image2: {imid:null}
        }
      },
      result: {overflow: ""}
    };
  },
  mounted () {
    var parent = this;
    $.get(this.$root.api_root + "flyer/get_page.php",{
      pid: parent.$route.params.pid,
      readOnly:true
    },function(data){
      parent.$root.checkRespForLoginFail(data);
      parent.page = JSON.parse(data);
    });
  },
  updated(){
    console.log("update");
    window.setTimeout(function(){
        $("*").imagesLoaded( { background: true }, function() {
            console.log('#container background image loaded');
            cbName();//callback of renderserver to 
        });
    },500);
  },
  props: [],
  components: {
    "flyerviewer": Viewer,
  }
}

</script>

