<template>
<div v-if="aktiver.kindergruppen.length > 0">
  <h2>Kindergruppen</h2>
  <ul>
    <li v-for="gruppe in aktiver.kindergruppen"><router-link :to="{ name: 'editKindergruppe', params: { id: gruppe.id }}">{{gruppe.name}}</router-link></li>
  </ul>
</div>
</template>

<script>

export default {
  name: 'Kindergruppen',
  props:["aktiver"],
  data(){
    return {
    };
  },
  created(){

  },
  components:{

  },
  computed:{

  },
  methods:{
  }
}

</script>
