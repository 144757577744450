export default {
  state: {
    rooms: [],
  },
  mutations: {
    setRooms(state, rooms){
       state.rooms = rooms;
    }
  },
  actions: {
    loadRooms({ commit, state }){
      var t = this;
      if(state.rooms.length > 0)
       return;
      $.get(api + "stock/getRooms.php",{
      },function(res){
        t.checkRespForLoginFail(res);
        commit("setRooms", JSON.parse(res));
      });
    }
  }
}
