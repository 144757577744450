<template>
  <div id="map"></div>
</template>



<script>
import locationPicker from "location-picker";
export default {
  name: 'PlacePicker',
  props: {
    "geopos":{default: null},
    "gen_geopos": {default: null},
    "defaultPos":{default: x => { return {x: 9, y:50} } },
    "disabled": {default: false}
  },
  data(){
    return {
      lp: null,
      isMounted: false,
      scriptLoaded: false,
      posIntX: null,
      posIntY: null
    }
  },
  created(){
    var t = this;
   this.$root.loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyCNV5_dPCB_mtVVoOMzcKX37V5K3gRtCl4",function(){
    //console.log();
    t.scriptLoaded = true;
    if(t.isMounted){
      t.createMap();
    }
   });
  },
  mounted(){
    this.isMounted = true;
    if(this.scriptLoaded)
      this.createMap();
  },

  methods:{
    createMap(){
    var pos = this.geopos;

    if(pos == null || pos === false){
      if(this.gen_geopos != null)
        pos = this.gen_geopos;
      else
        pos = this.defaultPos;
    }

      this.lp = new locationPicker('map', {
          setCurrentPosition: false, // You can omit this, defaults to true
          lat:pos.y,
          lng: pos.x
      }, {
          zoom: (pos == null || pos === false) ? 11: 7, // You can set any google map options here, zoom defaults to 1          ,
      });

      this.lp.map.addListener("bounds_changed",this.mapMoved);
      this.setEditable();
    },
    mapMoved(){

      var pos = this.lp.getMarkerPosition();
      if(pos.lat == this.posIntX && pos.lng == this.posIntY)
        return;

      if(this.posIntX == null){
          this.posIntX = pos.lat;
          this.posIntY = pos.lng;
          return;
      }

      this.posIntX = pos.lat;
      this.posIntY = pos.lng;


      this.$emit("update:geopos",{y: pos.lat, x: pos.lng});
    },
    setEditable(){
      this.lp.map.setOptions({draggable: !this.disabled, zoomControl: !this.disabled, scrollwheel: false, disableDoubleClickZoom: !this.disabled});
    }
  },
  watch:{
    geopos(newPos){
      return;
      if(newPos == null || newPos == false)
        return;

      if(newPos.x == this.posIntX && newPos.y == this.posIntY)
        return;

      this.posIntX = newPos.x;
      this.posIntY = newPos.y;
      console.log("poschanged");
      //console.log(this.posIntY == newPos.y);
      this.lp.setLocation(newPos.y, newPos.x);
    },
    disabled(newVal){
      this.setEditable();
    }
  }
}

</script>
<style scoped>
  #map{
    height:300px;
  }
</style>
