<template>
<div>
  <div class="row">
    <div class="col-md-6">
      <input type="text" class="form-control " v-model="search" placeholder="Listenname"/>
    </div>
    <div class="col-md-1">
      <button class="btn btn-success" :disabled="search == ''" @click="addList">Neu</button>
    </div>
  </div><br>
  <div class="row">
    <div class="col-md-6">
    <div id="box">
        <ul>
          <li v-for="list in filteredListed">
            <router-link :to="{ name: 'editList', params: { lid: list.id }}">{{list.name}}</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
</template>


<script>



export default {
  name: 'Lists',
  data(){
    return {
      lists:[],
      search:""
    };
  },
  created(){
    var t = this;
    $.get(this.$root.api_root + "stock/getLists.php",{ },function(data){
        t.$root.checkRespForLoginFail(data);
        t.lists = JSON.parse(data);
    });
  },
  computed:{
    filteredListed() {
      var t = this;
      try{
      return t.lists.filter(function(list){
        return list.name.startsWith(t.search);
      });
      }catch(e){
        console.log(e);
      }
    }
  },
  methods: {
    addList(){
      var t = this;
      $.get(this.$root.api_root + "stock/addList.php",{
        name: this.search
      },function(data){
        t.$root.checkRespForLoginFail(data);
        data = JSON.parse(data);
        t.lists.unshift({
          id: data.id,
          name: t.search,
          isOwn: true
        });
        t.search = "";
      });
    }
  }

}

</script>

<style scoped>
  #box{
      max-height: 150px;
      overflow-y: auto;
      background: #eee;
      padding: 10px;
  }
  ul{
    margin:0;
  }

</style>

