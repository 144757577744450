<template>
  <div id="dateBox" class="form-group row">
      <div for="name" class="col-md-2 col-form-label">Zeitpunkt</div>
      <div class="col-md-10">
          <b-checkbox :disabled="!page.locked" v-model="page.options.showTime" v-if="page.template == 'vpPoster' || page.template == 'handzettelFront'">Uhrzeit anzeigen</b-checkbox>
          <b-form-row class="my-2">
            <b-col cols="6">
                <b-form-group label="Beginn">
                <datepicker v-model="page.dateFrom" :disabled="!page.locked" :selectTime="page.options.showTime"/>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group label="Ende">
                <datepicker v-model="page.dateTo" :disabled="!page.locked" :selectTime="page.options.showTime"/>
                </b-form-group>
            </b-col>
          </b-form-row>
      </div>
  </div>
</template>
<script>

import datepicker from "../../datepicker.vue"

export default {
  name: 'Dateeditor',
  data(){
    return {
    };
  },
  props: ['page'],
  components: {
    datepicker
  },
  mounted(){
    var t = this;

  },
  beforeUpdate(){
   /* if(this.page.dateFrom == "")
      this.page.dateFrom = null;
    if(this.page.dateTo == "")
      this.page.dateTo = null;*/
  }
}
</script>

