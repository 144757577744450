<template>
  <div id="titlepageWrapperBox" v-bind:class="{withBjrLogo: page.options != null && page.options.showBjrLogo}">
    <dragableImage v-model="page.images.image1" fullHeight="true" :hq="true"></dragableImage>
    <div id="welle" v-bind:style="{background: themeColor}">
    </div>

    <img src="./abriss.png" id="abriss"/>
    <div id="contentBox">
      <div class="headline" v-bind:class="{muchText: (page.headline.length > 25)}">{{page.headline}}</div>
      <div class="subheadline">{{page.subheadline}}</div>
      <FlyerTextBox class="mainText" :value="page.text" :rightOriented="page.rightOriented" :fullWidth='true'
          :fullHeight='false' :result="result" />
      <InfoBox :page="page" :rightOriented="true" ></InfoBox>
    </div>
    <img id="najulogo" src="../logo.svg" />
    <div v-if="page.options != null && page.options.showBjrLogo" id="gefoerdertText">Gefördert durch {{page.options.showBjrLogo != 'StMUV' ? 'den' : 'das'}}:</div>
    <img v-if="page.options != null && page.options.showBjrLogo == true" id="bjrLogo" src="./bjr.png" />
    <img v-if="page.options != null && page.options.showBjrLogo == true" id="stmasLogo" src="./StMAS.png" />
    <img v-if="page.options != null && page.options.showBjrLogo == 'StMUV'" id="StMUV" src="./StMUV.jpg" />
    <div id="contactBox">
      Infos und Anmeldung unter:<br>
      <b>{{page.options.altWebsite != null && page.options.altWebsite != "" ? page.options.altWebsite : "www.naju-bayern.de"}}</b><br>
      Tel.: {{page.options.altPhoneNumber != null && page.options.altPhoneNumber != "" ? page.options.altPhoneNumber : "09174/4775-7651"}}
    </div>
    <imgRightsBox :images="page.images" :rightOriented="false"></imgRightsBox>
  </div>
</template>


<script>
import DragableImage from '../draggableFlyerImage.vue'
import InfoBox from '../infoBox.vue'
import FlyerTextBox from '../flyerTextBox.vue'
import imgRightsBox from '../imgRightsBox.vue'

export default {
  name: 'vpPoster',
  data () {
    return {
    }
  },
  props:["page","themeColor", "result"],
  components: {
    'dragableImage': DragableImage,
    InfoBox,
    FlyerTextBox,
    imgRightsBox
  },
}
</script>

<style scoped>

#titlepageWrapperBox{
  width: 216mm;
  height: 303mm;
  font-size: 16pt;
  line-height:1.4;
}
#welle{
  position: absolute;
  z-index: 2;
  bottom: 0;
  right: 0;
  width: 100%;
  background: #aa0000;
  height: 25%;
  -webkit-mask-image: url(../welle.svg);
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: auto 100%;
}

#abriss{
  width:100%;
  position:absolute;
  z-index:2;
  top: 110mm;
}

#titlepageWrapperBox >>> .flyerImage{
  height:120mm;
  position:absolute;
  top:0;
}

#contentBox{
  position:absolute;
  top: 123mm;
  z-index:3;
  left: 20mm;
  padding-right:20mm;
  height:440px;
}

.headline{
  font-size: 30pt;
  font-weight:bold;
  margin-bottom: 5px;
}

.subheadline{
  font-weight:bold;
  margin-top:-10px;
  font-size: 18pt;
  margin-bottom: 5px;
}


#najulogo{
  position: absolute;
  z-index:5;
  width: 55mm;
  bottom: 13mm;
  left:20mm;
  top: auto;
}

#gefoerdertText{
position: absolute;
bottom: 85mm;
right: 20mm;
width:60mm;
font-size:16px;
}

#bjrLogo{
  position: absolute;
  width: 20mm;
  bottom: 75mm;
  right: 20mm;
}


#stmasLogo{
  position: absolute;
  width: 60mm;
  bottom: 65mm;
  right: 20mm;
}

#StMUV{
  position: absolute;
  width: 60mm;
  bottom: 71mm;
  right: 20mm;
}

#contactBox{
  bottom:11mm;
  right:20mm;
  z-index:5;
  position: absolute;
  text-align:right;
}


#titlepageWrapperBox >>> #infoTable{
  margin-top:inherit;
  position:absolute;
  font-size:inherit;
  bottom:0mm;
  left:0;
}

.withBjrLogo >>> #infoTable{
  max-width:110mm;
}

#titlepageWrapperBox >>> #infoTable .left{
  font-weight: bold;
}

#titlepageWrapperBox >>> #infoTable .right{
  padding-left:20px;
}

#contentBox >>> #textBox{
  font-size:inherit;
  padding:0;
  margin:0;
}

#titlepageWrapperBox >>> #imgRightsBox{
  z-index:5;
}

#titlepageWrapperBox >>> #imgRightsBox.left{
  right:20mm;
}

</style>
