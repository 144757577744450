<template>
  <div id="titlepageWrapperBox">
    <dragableImage v-model="page.images.image1" fullHeight="true" :hq="true"></dragableImage>
    <div id="welle" v-bind:style="{background: themeColor}">
    </div>
    <div id="headlinesBox">
      <div class="headline" v-bind:class="{muchText: (page.headline.length > 25)}">{{page.headline}}</div>
      <div class="subheadline">{{page.subheadline}}</div>
    </div>
    <img id="najulogo" src="./logoOhneSchrift.svg" />
  </div>
</template>


<script>
import DragableImage from './draggableFlyerImage.vue'
export default {
  name: 'Titlepage',
  data () {
    return {
    }
  },
  props:["page","themeColor"],
  components: {
    'dragableImage': DragableImage,
  },
}
</script>

<style>

#titlepageWrapperBox{
  width: 154mm;
  height: 111mm;
}
#welle{
  position: absolute;
  z-index: 2;
  bottom: 0;
  right: 0;
  width: 100%;
  background: #aa0000;
  height: 45%;
  -webkit-mask-image: url(./welle.svg);
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: auto 100%;
}

#headlinesBox{
/*border: 2px solid black;*/
  position:absolute;
  z-index: 3;
  left: 60mm;
  top: 85mm;
  width: 85mm;
  color: black;
  text-align: center;
}

#headlinesBox .headline{
  font-size: 27px;
  margin-bottom:-4px;
}

#headlinesBox .subheadline{
  font-size: 18px;
  line-height: 110%;
}

#headlinesBox .muchText{
  font-size:24px;
}

#najulogo{
  position: absolute;
  z-index:5;
  width: 40mm;
  left: 10mm;
  top:88mm;
}

</style>
