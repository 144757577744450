
<template>
    <VueApexCharts width="500" type="bar" :options="pieChartOptions" :series="chartSeries"></VueApexCharts>
</template>


<script>
import VueApexCharts from 'vue-apexcharts'


export default {
  name: 'AdvertisementStatistics',
  data(){
    return {
    }
  },
  props:["stats", "groupBy"],
  components:{
    VueApexCharts
  },
  methods:{
    key(e){
        if(this.groupBy == "year")
            return e.y;
        else if(this.groupBy == "month")
            return e.y + "-" + e.m;
        else if(this.groupBy == "sem")
            return e.semId;
    }
  },
  created(){
  },
  computed:{
    pieChartOptions(){
        return {
            chart: {
            id: 'vuechart-example',
            stacked: true,
            stackType: '100%'
            },
            labels:Object.values(this.secondGroup)
        };
    },
    secondGroup(){
        var ret = {};
        this.stats.map(e => {
            var name = "";
            if(this.groupBy == "year")
                name = e.y;
            else if(this.groupBy == "month")
                name = e.m + "." + e.y;
            else if(this.groupBy == "sem")
                name = e.semName;
        
            ret[this.key(e)] = name
        });
        
        return ret;
    },
    chartSeries(){
        var grouped = {};
        
        this.stats.forEach(e => {
            var key = e.name;
            if(grouped[key] == null)
                grouped[key] = {};
            grouped[key][this.key(e)] = e;
        });
        
        
        return Object.keys(grouped).map(name => {
            var group = grouped[name]
            
            return {
                name: name,
                data: Object.keys(this.secondGroup).map( k => group[k] == null ? 0 : group[k].num*1)
            }
        
        });
        
    }
  },
}

</script>
