<template>
  <div id="chart">
    <apexchart type="line" height=350 :options="chartOptions" :series="series" />
  </div>
</template>


<script>

import apexchart from 'vue-apexcharts'

export default {
  name: 'SeminarEnrollmentStatistic',
  components: {
    apexchart
  },
  data(){
    return {
       chartOptions: {
          chart: {
            id: 'vuechart-example'
          },
          xaxis: {
            type: 'datetime',
          },
          title: {
            text: "Teilnehmer " + this.seminar.title,
          }
        },
    };
  },
  props:["seminar"],
  computed:{
    series(){
      return [{
          name: "Teilnehmer " + this.seminar.title,
          data: this.seminar.anmeldungen
            .filter(x => !x.hidden)
            .map(x => [x.anmeldedatum, 1]).
            sort((a,b) => a[0] - b[0]).
            map((x,index) => [x[0],index + 1])
        }];
    }
  }
}

</script>

<style scoped>


</style>


