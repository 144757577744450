<template>
  <b-button variant="success" @click="save" :disabled="disabled || savePending || saved">{{savePending ? "Wird gespeichert" : (saved ? "Gespeichert" : "Speichern")}}</b-button>
</template>


<script>
export default {
  name: 'SaveBtn',
  data(){
    return {
      savePending: false,
      saved: this.initialSaved,
    };
  },
  props:{
    saveable:{},
    api: {type: String},
    initialSaved: {type:Boolean, default: true},
    disabled: {type: Boolean, default: false},
    validation:{type: Function, default: function(){return true;}},
    isSaved:{type: Boolean, default: true},
  },
  methods:{
    save(){
      var t = this;
      if(!t.validation())
        return;
      t.savePending = true;
      var url = this.$root.api_root + t.api;
      if(token != "" && token != null){
        url += "?token=" + token;
      }
      $.post(url,{
        data: JSON.stringify(this.saveable),
      },function(res){
        t.$root.checkRespForLoginFail(res);
        t.savePending = false;
        t.saved = true;
        t.$emit("saved",res);
        t.$emit("update:isSaved",t.saved);
      });
    },
    reset(){
      this.$nextTick(function(){
          this.saved = true;
          this.$emit("update:isSaved",this.saved);
      });
    },
    confirmLeave(){
      return this.saved || confirm("Willst du wirklich gehen ohne zu Speichern?");
    }
  },
  watch:{
    saveable:{
      deep:true,
      handler(){
        this.saved = false;
        this.$emit("update:isSaved",this.saved);
      }
    }
  }
}

</script>
