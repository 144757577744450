<template>
  <div>
    <div id="flyerViewer" lang="de" v-bind:class="{bordered: previewMode}" v-bind:style="{'--themeColor': themeColor}">
    <div id="printBorder" v-if="previewMode"></div>
        <semSinglePage :page="page" v-if="page.template == 'semSinglePage' || page.template == 'singleTextpage'"
          :themeColor="themeColor"
          :result="result"></semSinglePage>
          <semDoublePage :page="page" v-if="page.template == 'semDoublePage'"
          :themeColor="themeColor"
          :result="result"></semDoublePage>
        <titlepage :page="page" v-if="page.template == 'titlepage'" :themeColor="themeColor"></titlepage>
        <indexpage :page="page" v-if="page.template == 'index'"
          :themeColor="themeColor"></indexpage>
        <lastPage :page="page" v-if="page.template == 'lastPage' || page.template == 'lastPage-new-generic' || page.template == 'lastPage-new-sem' || page.template == 'lastPage-new-multi'" :themeColor="themeColor"></lastPage>
        <poster :page="page" v-if="page.template == 'vpPoster'" :themeColor="themeColor" :result="result"/>
        <handzettelFront :page="page" v-if="page.template == 'handzettelFront'" :themeColor="themeColor" :result="result"/>
        <handzettelBack :page="page" v-if="page.template == 'handzettelBack'" :themeColor="themeColor" :result="result"/>
    </div><br>
    <button v-on:click="page.rightOriented = !page.rightOriented;" v-if="previewMode">Flip Orientation</button>
  </div>
</template>


<script>

import semSinglePage from './semSinglePage.vue'
import semDoublePage from './semDoublePage.vue'
import Titlepage from './titlepage.vue'
import indexpage from './indexPage.vue'
import lastPage from './semLastPage.vue'
import poster from './poster/vpPoster.vue'
import handzettelFront from './handzettel/handzettelFront.vue'
import handzettelBack from './handzettel/handzettelBack.vue'

export default {
  name: 'FlyerViewer',
  data () {
    return {
    }
  },
  props:["page", "previewMode", "result"],
  components: {
    semSinglePage,
    semDoublePage,
    'titlepage': Titlepage,
    indexpage,
    lastPage,
    poster,
    handzettelFront,
    handzettelBack
  },
  computed:{
    themeColor() {
      switch(this.page.flyerColor){
        case 'jugend': return "#f39315";
        case 'kinder': return "#5ebef9";
        case 'multi': return "#d6a21c";
      }
      return "#a6bd0c";
    }
  }
}
</script>

<style>
@page{
margin-left: 0px;
margin-right: 0px;
margin-top: 0px;
margin-bottom: 0px;
size: landscape;

}

#flyerViewer{
    display:inline-block;
    position:relative;
    font-family: 'Source Sans Pro';
    background: #fff;
}

#flyerViewer.bordered{
  border: 1px solid #000000;
}

@media print{
    #flyerPage{
        border: none;
    }
}

body{
    margin:0;
}

#printBorder{
  position:absolute;
  border:1px solid gray;

  left: 3mm;
  top:3mm;
  bottom:3mm;
  right:3mm;
  z-index:3;
  pointer-events:none;
}







</style>
