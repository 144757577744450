<template>
  <b-col cols="6" offset="2">
    <h1>{{seminar.title | capitalize}} bearbeiten</h1>
     <b-form-row class="my-2">
      <b-col cols="2">Zeitraum</b-col>
      <b-col cols="5"> <datepicker v-model="seminar.start"></datepicker></b-col>
      <b-col cols="5"> <datepicker v-model="seminar.end"></datepicker></b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Arbeitstitel</b-col>
      <b-col cols="10"><workingTitlePicker id="workingTitle" v-model="seminar.workingTitle" placeholder="z.B. kanutour" /></b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Ort</b-col>
      <b-col cols="10"> <b-input v-model="seminar.ort" /></b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Förderart</b-col>
      <b-col cols="10">
        <b-form-radio-group id="fundtype-radio" v-model="seminar.fundType" name="fundtype-radio">
          <b-form-radio value="j">JBM</b-form-radio>
          <b-form-radio value="a">AEJ</b-form-radio>
          <b-form-radio value="x">Keine BJR-Förderung</b-form-radio>
        </b-form-radio-group>
      </b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Teilnehmerzahl</b-col>
      <b-col cols="5"> <b-input v-model="seminar.minTn" placeholder="Mindestens" type="number"/></b-col>
      <b-col cols="5"> <b-input v-model="seminar.maxTn" placeholder="Maximal" type="number"/></b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Verknüpfte Benutzer</b-col>
      <b-col cols="10">
      Verknüpfte Benutzer dürfen dieses Seminar sehen, auch wenn sie eigentlich keine Seminaranmeldungen sehen dürfen. Außerdem können sie
      Benachrichtigungen bei neuen Anmeldungen aktivieren.
      <Owners :owners="seminar.owner" />
      </b-col>
    </b-form-row>

    <saveBtn ref="saveBtn" :saveable="seminar" api="seminare/saveSeminar.php"/>

  </b-col>
</template>

<script>
import Datepicker from '../datepicker.vue';
import Owners from './ownerEditor.vue'
import saveBtn from '../saveBtn.vue'
import workingTitlePicker from "../workingTitlePicker.vue"

export default {
  name: 'EditSeminar',
  data(){
    var t = this;
    return {
      seminar:{
        title:"",
        start: null,
        end:null,
        year:null,
        anmeldungen:[],
        owner:[],
        workingTitle:null
      }
    }
  },
  components:{
    Datepicker,
    Owners,
    saveBtn,
    workingTitlePicker
  },
  created(){
    this.loadSeminar(this.$route.params.sid);
  },
  methods: {
    loadSeminar(sid){
      var t = this;
      $.get(this.$root.api_root + "seminare/getSeminar.php",{
        sid:sid,
      },function(res){
        t.$root.checkRespForLoginFail(res);
        if(res == "not found"){
          t.data = null;
          alert("Seminar nicht gefunden");
          return;
        }
        t.seminar = JSON.parse(res);
        t.$refs.saveBtn.reset();
        t.$store.dispatch("loadUser",true);
      });
    },
  },
  beforeRouteUpdate(to, from, next){
    this.loadSeminar(to.params.sid);
    next();
  },
}
</script>

