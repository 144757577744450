<template>
<table class="table table-bordered">
  <tr><td>Name</td><td v-for="choiceName in choiceNames">{{choiceName}}</td></tr>
  <tr v-for="reply, index in replys">
    <td>{{names[index]}}</td>
    <td v-for="choice in [...choices, {text:null}]">
        <span v-if="reply == choice.text || (Array.isArray(reply) && reply.indexOf(choice.text) != -1)" class="oi-check oi"></span>
        
    </td>
  </tr>
</table>
</template>

<script>

import { mapState } from 'vuex'

export default {

  name: 'choiceTables',
  data(){
    return{
    }
  },
  props:{
    choices: {},
    choiceNames:{}, 
    replys: {},
    names:{}
  },
  components:{
  },
  computed:{
  
    
  },
  methods:{
    
  },
  
}
</script>
