<template>
  <div>
      <div class="form-group row">
          <div for="name" class="col-md-4 col-form-label">
            <select class="form-control" v-model="room">
              <option value="0">Alle Räume</option>
              <option v-for="(room, roomId) in rooms" v-bind:value="roomId">
                {{ room }}
              </option>
              <option value="no">Ohne Raum</option>
            </select>
          </div>
          <div for="name" class="col-md-3 col-form-label">
            <input ref="place" class="form-control" type="text" placeholder="Regalnr" v-model="place"/>
          </div>
          <div for="name" class="col-md-5 col-form-label">
            <input class="form-control" type="text" placeholder="Name" v-model="startingWith" />
          </div>
      </div>
      <ResultList :found="found"/>
  </div>
</template>


<script>


var data = {
      room:0,
      place: "",
      startingWith: "",
      found: []
    };


import { mapState } from 'vuex'
import ResultList from "./searchItemShowList.vue"

export default {
  name: 'SearchItem',
  data(){
    return data;
  },
  created(){
    this.$store.dispatch("loadRooms");
  },
  components:{
    ResultList
  },
  mounted(){
    var t = this;
    $(this.$refs.place).autocomplete({
        minLength: 0,
        source: function( request, response ) {
            $.ajax( {
                url: t.$root.api_root + "stock/searchPlace.php",
                data: {
                    startingWith: request.term,
                    room: t.room
                },
                success: function( data ) {
                    t.$root.checkRespForLoginFail(data);
                    data = JSON.parse(data);
                    response( data );
                }
            } );
        },
        select:function(event,ui){
            console.log(ui.item.iid);
            t.place=ui.item.label;
        }
    });
    this.search();
  },
  methods:{
    search(){
      var t = this;
      $.get(t.$root.api_root + "stock/searchItem.php",{
        room: t.room,
        place: t.place,
        startingWith: t.startingWith,
        max:50
      },function(data){
        t.$root.checkRespForLoginFail(data);
        data = JSON.parse(data);
        t.found = data;
      });
    }
  },
  watch:{
    place(){
      this.search();
    },
    startingWith(){
      this.search();
    },

    room(){
      this.place = '';
      this.search()
    }
  },
  computed:{
    ...mapState({
      rooms: state => state.stock.rooms
    })
  }
}

</script>

<style scoped>



</style>

