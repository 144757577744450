<template>

  <b-form-row class="my-2">
    <b-col cols="2">Empfänger</b-col>
    <b-col cols="10">
      <div class="my-2"><template v-for="r, index in  recipiants"><template v-if="index != 0">, </template>{{r.name}}<template v-if="!disabled"> (<span class="btn-link" @click="rmAktiven(index)">x</span>)</template></template></div>
      <div v-if="pendingGroup != null">{{pendingGroup}} wird hinzugefügt ...</div>
      <b-alert variant="warning" :show="noEmail.length != 0">
        <b>Achtung</b> Folgende {{noEmail.length}} Empfänger haben keine (korrekte) E-Mail-Adresse angegeben:
        <ul>
          <li v-for="a in noEmail">
            <router-link :to="{ name: 'viewAktiven', params: { aid: a.id }}">{{a.vorname}} {{a.nachname}}</router-link>
          </li>
        </ul>
        <b-button variant="info" @click="checkRecipiants">Erneut prüfen</b-button>
      </b-alert>

      <b-alert variant="warning" :show="noMoreEmails.length != 0">
        <b>Achrung</b> Folgende Empfänger haben angegeben, nie weider E-Mails von der NAJU empfangen zu wollen. Sie werden die Nachricht nicht erhalten. Du kannst nichts dagegen tun.
        <ul>
          <li v-for="a in noMoreEmails">
            <router-link :to="{ name: 'viewAktiven', params: { aid: a.id }}">{{a.vorname}} {{a.nachname}}</router-link>
          </li>
        </ul>
      </b-alert>
      <Seachfield placeholder="Aktiven hinzufügen" @found="addAktiven" :resetOnFound="true" :findGroups="true" :findSems="true" :disabled="pendingGroup != null || disabled"/>
    </b-col>
  </b-form-row>
</template>

<script>
import Seachfield from "./search.vue"
export default {
  name: 'EmailRecipiants',
  data(){
    return {
      pendingGroup:null,
      noEmail: [],
      noMoreEmails: [],
    }
  },
  props:["recipiants", "disabled", "noGebdat"],
  components:{
    Seachfield
  },
  watch:{
    recipiants(){
      console.log("rec changed");
      this.checkRecipiants();
    }
  },
  mounted(){
    this.checkRecipiants();
  },
  methods:{
    checkRecipiants(){
      if(this.recipiants.length == 0){
        this.noEmail = [];
        this.noMoreEmails = [];
        this.$emit("update:noGebdat",[]);
        return;
      }
      var t = this;
       $.post(this.$root.api_root + "seminare/checkHasEmail.php",{
        ids: JSON.stringify(this.ids(this.recipiants))
        },function(res){
          t.$root.checkRespForLoginFail(res);
          res = JSON.parse(res);
          t.$set(t,"noEmail", res.noEmail);
          t.$set(t,"noMoreEmails", res.noMoreEmails);
          t.$emit("update:noGebdat", res.noGebdat);
        });
    },
    addAktiven(a){
      var t = this;
      if(a.type=="name"){
        if(t.ids(t.recipiants).includes(a.id))
          return;
        t.recipiants.push({
          id: a.id,
          name: a.name,
        });
      }
      else if(a.type == "group"){
        this.pendingGroup = a.name;
        $.get(this.$root.api_root + "seminare/getFunktion.php",{
        gid: a.id
        },function(res){
          t.$root.checkRespForLoginFail(res);
          var funktion = JSON.parse(res);
          funktion.member.forEach(m => {
            if(t.ids(t.recipiants).includes(m.id))
              return;
            t.recipiants.push({
              id: m.id,
              name: m.vorname + " " + m.nachname,
            });
          });
          t.pendingGroup = null;
        });
      }else if(a.type == "seminar"){
        this.pendingGroup = a.name;
        $.get(this.$root.api_root + "seminare/getSeminar.php",{
          sid: a.id
        },function(res){
          t.$root.checkRespForLoginFail(res);
          var sem = JSON.parse(res);
          sem.anmeldungen.filter(anmeld => !anmeld.hidden)
          .forEach(anmeld => {
            if(t.ids(t.recipiants).includes(anmeld.aktiver))
                return;
            t.recipiants.push({
              id: anmeld.aktiver,
              name: anmeld.name,
            });
          });
          t.pendingGroup = null;
        });
      }
    },
    rmAktiven(i){
      this.recipiants.splice(i,1);
    },
    ids(array) {
      return array.map(e => e.id);
    }
  }

}

</script>
