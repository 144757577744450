<template>
  <b-col cols="10" offset=2 class="printFullscreen">
    <div class="row">
      <div class="col-md-8 col-md-push-1 printFullscreen">

        <div class="form-group row">
          <div for="name" class="col-md-2 col-form-label hideOnPrint">Name</div>
          <div class="col-md-8">
            <input type="text" class="form-control hideOnPrint" v-model="list.name" :disabled="!list.editable">
            <h1 class="printOnly">{{list.name}}</h1>
          </div>
        </div>

        <lockManager ref="lockManager" :id="list.id" entity="StockList" :locked.sync="locked" @reload="reloadList" v-if="list.editable"/>

        <div class="form-group row">
          <div for="name" class="col-md-2 col-form-label hideOnPrint">Sachen</div>
          <div class="col-md-10 printFullscreen">
            <ListTable :items="list.items" :disabled="!list.editable || !locked"/>
          </div>
        </div>

        <div class="form-group row hideOnPrint" v-if="list.editable">
          <div for="name" class="col-md-2 col-form-label">Neue Sache</div>
          <div class="col-md-2">
            <input type="number" ref="newNum" class="form-control" v-model="newNum" />
          </div>
          <div class="col-md-3">
            <itemPicker class="form-control" v-model="newItem" :enteredText.sync="newFreetext"  @enter="addItem" placeholder="Sache" :clearOnBlur="false"/>
          </div>
          <div class="col-md-4">
            <input type="text" class="form-control" v-model="newComment" @keydown.enter="addItem" placeholder="Kommentar"/>
          </div>
          <div class="col-md-1">
            <button class="btn btn-success" :disabled="newItem == null && newFreetext == ''" @click="addItem">+</button>
          </div>
        </div>

        <listOwners :list="list" class="hideOnPrint"/>

        <div class="form-group row hideOnPrint" v-if="list.editable">
          <div for="name" class="col-md-2 col-form-label">Liste löschen</div>
          <div class="col-md-10">
            <button class="btn btn-danger" @click="deleteList">Liste löschen</button>
          </div>
        </div>

      </div>
    </div>
  </b-col>
</template>


<script>

import listOwners from "./editListOwners.vue"
import itemPicker from "./itemPicker.vue"
import lockManager from "../lockManager.vue"
import ListTable from "./editListTable.vue"

export default {
  name: 'EditList',
  data(){
    return {
      list:{
        id:null,
        name: "",
        items: [],
        owners:[],
        editable: false
      },
      newItem: null,
      newFreetext: "",
      newNum: 1,
      newComment: "",
      searchUser: "",
      isSaved:true,
      timeout:null,
      sortBy: null,
      locked: false,
    };
  },
  components:{
    itemPicker,
    listOwners,
    lockManager,
    ListTable
  },
  methods:{
    reloadList(){
      if(this.list.id != null)
        this.loadList(this.list.id)
    },
    loadList(id){
      var t = this;
      $.get(this.$root.api_root + "stock/getList.php",{
        lid: id
      },function(data){
        t.$root.checkRespForLoginFail(data);
        t.list = JSON.parse(data);
        t.$nextTick(function () {
          clearTimeout(t.timeout);// no saving needed as we caused it
          t.isSaved = true
        });
      });
    },
    addItem(){
      var t = this;
      if(this.newItem == null && this.newFreetext == "")
        return;

      if(t.newNum == null || t.newNum == "")
        t.newNum = 1;

      $.get(this.$root.api_root + "stock/addListItem.php",{
          item: this.newItem != null ? this.newItem.id : "",
          freetext: this.newFreetext,
          num: this.newNum,
          comment: this.newComment,
          list: this.list.id
        },function(data){
        t.$root.checkRespForLoginFail(data);

        data = JSON.parse(data);

        if(data.error != null && data.error == "alreaddy on list"){
          alert(t.newItem.name + " bereits auf Liste enthalten");
          return;
        }

        if(data.error != null && data.error == "parent on List"){
          alert(t.newItem.name + " ist Teil von " + data.parent + " und damit bereits auf der Liste.");
          return;
        }

        if(data.error != null && data.error == "parent is atomic"){
          alert(t.newItem.name + " is Teil von " + data.parent + " was nicht zerpflückt werden darf! Du musst also " + data.parent + " komplett einpacken.");
          return;
        }


        t.list.items.push({
          id: data.id,
          name: t.newItem != null ? t.newItem.name : t.newFreetext,
          comment: t.newComment,
          num: t.newNum,
          avNum: data.avNum,
          iid: t.newItem != null ? t.newItem.id : "",
          place: data.place
        });

        t.newNum = "";
        t.newComment = "";
        t.newItem = null;
        $(t.$refs.newNum).focus();

      });
    },

    edit(){
      this.isSaved = false;
      clearTimeout(this.timeout);
      var t = this;
      this.timeout = setTimeout(function(){
        t.save();
      },3000);
    },
    save(then = function(){}){
      if(this.isSaved || !this.locked){
        then();
        return;
      }
      var t = this;
      $.get(this.$root.api_root + "stock/saveList.php",{
        list: t.list.id,
        name: t.list.name
      },function(data){
        t.$root.checkRespForLoginFail(data);
        t.isSaved = true;
        then();
      });
    },
    deleteList(){
      var t = this;
      if(!(confirm("Wirklich löschen") && confirm("Wirklich wirklich löschen")))
        return;
      $.get(this.$root.api_root + "stock/deleteList.php",{
        list: t.list.id
      },function(data){
        t.$root.checkRespForLoginFail(data);
        t.$router.push("/");
      });
    },
  },
  created(){
    this.loadList(this.$route.params.lid);
  },
  beforeRouteUpdate(to, from, next){
    var t = this;
    this.save(function(){
      t.loadList(to.params.lid);
      next();
    });
  },
  beforeRouteLeave (to, from, next) {
    var t = this;
    this.save(function(){
      if(t.$refs.lockManager != null)
        t.$refs.lockManager.unlock().then(next);
      else
        next();
    });
  },
  watch:{
    "list.name"(){
      this.edit();
    },
    sortBy(){
      this.sort();
    }
  }

}

</script>

<style scoped>
@media print{
  >>> .hideOnPrint{
    display: none;
  }
  .printFullscreen{
    width:100%;
    margin:0;
    max-width:100%;
    flex:100%;
  }
}
@media screen{
  >>> .printOnly{
    display: none;
  }
}


</style>

