<template>
  <b-col class="col-lg-8 col-xl-6 col-12 offset-lg-2">
    <b-alert v-if="authError" :show="true" variant="danger"><b>Fehler:</b> Du bist nicht berechtigt an dieser Umfrage teil zu nehmen. Entweder der Link ist defekt oder du musst dich wieder einlogen</b-alert>
    <b-alert v-else-if="!poll.isOpen" :show="true" variant="warning">Diese Umfrage ist leider bereits beendet.</b-alert>
    <b-alert v-else-if="saved" :show="true" variant="success"><b>Gespeichert:</b> Vielen Dank für deine Teilnahme!</b-alert>
    <template v-else>
        <h1>{{poll.name}}</h1>
        {{poll.description}}
        <b-form ref="myForm" @submit="checkForm" class="needs-validation" novalidate>
            <div v-if="poll.nameRequired" class="nameField">
                <h5>Dein Name</h5>
                <b-form-group invalid-feedback="Bitte gebe einen Namen an!">
                    <b-form-input v-model="name"  required/>
                 </b-form-group>
            </div>
            <showQuestion v-for="question in poll.questions" :question="question" :results="results" :disabled="saving || saved"/>
            <b-button variant="success" type="submit" :disabled="saving || saved">{{saving ? "Wird gespeichert" : (saved ? "Gespeichert" : "Absenden")}}</b-button>
        </b-form>
    </template>
  </b-col>
</template>

<script>

import { mapState } from 'vuex'
import showQuestion from "./showQuestion.vue"


export default {

  name: 'runPoll',
  data(){
    return{
      poll: {
        name: "",
        questions:[],
        key: null,
        isOpen: true,
      },
      authError: false,
      results:{},
      name: "",
      key: null,
      saving: false,
      saved: false
    }
  },
  components:{
    showQuestion,
  },
  created(){
    if(this.$route.params.id != null){
      this.loadPoll(this.$route.params.id, this.$route.query.key);
    }
  },
  methods:{
    loadPoll(id, key){
      var t = this;
      this.key = key;

      $.get(this.$root.api_root + "poll/getPoll.php",{
        id: id,
        key: key
      },function(res){
        t.$root.checkRespForLoginFail(res);
        if(res.trim() == "not allowed!"){
            t.authError = true;
            return;
        }
        t.authError = false;
        t.poll = JSON.parse(res);
      });
    },
    save(){
        this.saving = true;
        var t = this;
        $.post(this.$root.api_root + "poll/savePollReply.php",{
            poll: this.poll.id,
            key: this.key,
            data: JSON.stringify(this.results),
            name: this.name
        },function(res){
            t.$root.checkRespForLoginFail(res);
            t.saving  = false;
            t.saved = true;
        });
    },
    checkForm(){
        event.preventDefault();
        event.target.classList.add('was-validated');
        var valid = this.$refs.myForm.checkValidity();
        if(valid){
            this.save();
        }
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.loadPoll(to.params.id, to.query.key);
    next();
  },
  
}
</script>


<style scoped>
    .nameField{
        padding: 10px;
        background: #fff;
        margin-bottom: 10px;
    }
</style>
