<template>
  <div class="tableWrapper">
    <table class='indexTable table table-striped' ref="indexTable">
        <thead>
        <tr><th scope='col'>Seite</th><th scope='col'>Seminar</th><th scope='col'>Bezirk</th></tr>
        </thead>
        <tbody>
        <tr v-for="page in pages"><td>{{page.pageNr}}</td><td>{{page.title}}</td><td v-if="page.bezirk != null">{{page.bezirk.toUpperCase()}}</td></tr>
        </tbody>
    </table>
  </div>
</template>


<script>

export default {
  name: 'IndexTable',
  data () {
    return {
    }
  },
  props:["pages"]
}
</script>

<style>


.tableWrapper{
  width: 80%;
  margin:auto;
}

.indexTable td{
  padding: 2px !important;
  font-size: 12px;
}
</style>
