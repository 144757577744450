<template>
  <div class="my-2">
    <b-form-checkbox v-model="myUser.noBgImage" @input="bgImageChanged = true">Kein Hintergrundbild anzeigen</b-form-checkbox>
    <b-input type="email" v-model="myUser.email" class="my-2" placeholder="E-Mail Adresse"/>
    <b-form-checkbox v-model="myUser.notificationsEnabled" :disabled="myUser.email == '' || myUser.email == null">Benchrichtigungen zu meinen Funktionen & Gruppen erhalten</b-form-checkbox>
    <saveBtn ref="saveBtn" :saveable="myUser" api="saveUserSettings.php" @saved="reload" />
  </div>
</template>



<script>
import { mapState } from 'vuex'
import saveBtn from './saveBtn.vue'

export default {
  name: 'UserSettings',
  data(){
    return {
      myUser:{},
      bgImageChanged: false,
    };
  },
  props:[],
  components:{
    saveBtn
  },
  methods:{
    reload(){
      if(this.bgImageChanged)
        window.location.reload(false);
    }
  },
  mounted(){
  },
  created(){
    this.$store.dispatch("loadUser");
    this.myUser = this.user;
  },
  computed:{
    ...mapState({
      user: state => state.base.user,
    })
  },
  watch:{
    user(){
      this.myUser = this.user;
      this.$refs.saveBtn.reset();
    }
  }
}

</script>

<style scoped>

</style>


