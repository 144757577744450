<template>
  <div>
  <b-button-group>
      <b-button v-for="col in selectableCols" :key="col.key" variant="primary" :pressed.sync="col.checked">{{col.label}}</b-button>
    </b-button-group>

    <slot v-bind:cols="filteredCols"></slot>
  </div>
</template>


<script>



export default {
  name: 'ColSelectTable',
  data(){
    return {
      fixedColls: []
    };
  },
  props:["items", "cols"],
  watch:{
    cols(){
      this.fixColls();
    }
  },
  created(){
    this.fixColls();
  },
  updated(){

  },
  computed:{
    selectableCols(){
      return this.cols.filter(function(each){
        return each.selectable == null || each.selectable == true;
      });
    },

    filteredCols(){
      return this.cols.filter(function(each){
        return each.checked;
      });
    }
  },
  methods:{
    fixColls(){
      var t = this;
      console.log("fix");
      this.cols.forEach(function(each, index){
        if(each.checked == null)
        t.$set(each,"checked",false);
      });
    }
  }

}

</script>

<style scoped>


</style>


