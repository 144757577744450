<template>
  <div>
    <a :href="$root.full_api_root + 'flyer/flyer2rceEvent.php?fid=' + flyer.id" target="_blank" @click="$refs.modal.show()"><b-button>Export für LBV</b-button></a>
    <b-modal ref="modal" title="Auf RCE Event übertragen" ok-only>
        <b>So geht es jetzt weiter:</b>
        <ul>
            <li><a href="https://www.rce-event.de/login" size="lg" target="_blank">www.rce-event.de/login</a> öffnen</li>
            <li>einloggen</li>
            <li>Im Menü auf Extra / Exel - Import</li>
            <li>Datei auswählen + Hochladen + Importieren</li>
            <li>Enddaten korrigieren (RCE-Event-Import kann keine mehrtägigen Veranstaltungen)</li>
            <li>Zielgruppe (Kinder / Jugend) angeben</li>
            <li>Wenn möglich Adresse angeben + Ort auf Karte richtig setzten</li>
            <li>Bei ausländ. Veranstaltungen Land korrigieren</li>
        </ul>
    </b-modal>
  </div>
</template>
<script>

export default {
  name: 'Flyer2Rce',
  data(){
    return {
    
    };
  },
  props: ["flyer"],
  components: {

  },
  methods:{
  },
  computed:{
  }
}
</script>

