<template>
  <b-row>
    <b-col cols="6">
      <ul>
        <li v-for="(funktion, index) in funktionen" :key="funktion.id">
        <router-link :to="{ name: 'viewFunktion', params: { fid: funktion.id }}">{{funktion.name}}</router-link> <span class="oi oi-trash btn-link" @click="rmFunktion(index)" v-if="aktiver.editable"/></li>
      </ul>
    </b-col>
    <b-col cols="6">
      <b-dropdown text="Hinzufügen" class="m-md-2" v-if="aktiver.editable">
        <b-dropdown-item v-for="funktion in freeFunction" :key="funktion.id" @click="aktiver.gruppen.push(funktion.id)">{{funktion.name}}</b-dropdown-item>
      </b-dropdown>
    </b-col>
  </b-row>
</template>

<script>

export default {
  name: 'AktivenFunktionEditor',
  props:["aktiver"],
  data(){
    return {
    };
  },
  created(){
    this.$store.dispatch('loadFunktionen');
  },
  computed:{
    funktionen(){
      if(this.aktiver.gruppen == null || this.$store.state.seminare.funktionen == null)
        return [];
      return this.aktiver.gruppen.map(gid => {
        return this.$store.state.seminare.funktionen[gid];
      });
    },
    freeFunction(){
      if(this.aktiver.gruppen == null || this.$store.state.seminare.funktionen == null)
        return [];
      return Object.values(this.$store.state.seminare.funktionen)
        .filter(x => this.aktiver.gruppen.indexOf(x.id) == -1)
        .sort((a,b) => a.name.localeCompare(b.name));
    }
  },
  methods:{
    rmFunktion(pos){
      this.aktiver.gruppen.splice(pos,1);
    }
  }
}

</script>
