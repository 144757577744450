<template>
  <b-col class="col-lg-8 col-xl-6 col-12 offset-lg-2">
    <h1>Auswertung von {{poll.name}}</h1>
    {{poll.replys.length}} Teilnahmen<br>
    <questionRes v-for="question in poll.questions" :question="question" :replys="poll.replys"/>
    <a :href="$root.api_root + 'poll/exportPollResult.php?id=' + poll.id"><b-button variant="info">Ergebnisse exportieren</b-button></a>
  </b-col>
</template>

<script>

import { mapState } from 'vuex'
import questionRes from "./questionRes.vue"


export default {

  name: 'pollResults',
  data(){
    return{
      poll: {
        name: "",
        questions:[],
        key: null,
        replys:[],
      },
    }
  },
  components:{
    questionRes,
  },
  created(){
    if(this.$route.params.id != null){
      this.loadPoll(this.$route.params.id);
    }
  },
  methods:{
    loadPoll(id){
      var t = this;

      $.get(this.$root.api_root + "poll/getPoll.php",{
        id: id,
        incReplys: "true"
      },function(res){
        t.$root.checkRespForLoginFail(res);
        t.poll = JSON.parse(res);
      });
    },
  },
  beforeRouteUpdate (to, from, next) {
    this.loadPoll(to.params.id, to.query.key);
    next();
  },
  
}
</script>


<style scoped>
</style>
