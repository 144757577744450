<template>
  <b-col class="col-lg-8 col-xl-8 col-12 offset-lg-2">
    <h1>Fereinbörsen für {{page.headlineForIndex}}</h1>
    <h2>Zusätzliche Daten</h2>
    <h2>Ferienbörsen</h2>
    <router-link :to="{ name: 'editFerienboerse', params: { id: 'new' }}"><b-button class="my-1">Neue Ferienbörse anlegen</b-button></router-link>
    <b-row>
      <b-col class="col-6 col-xl-4" v-for="boerse in boersen" v-bind:key="boerse.id">
        <b-card bg-variant="" no-body :border-variant="boerse.isDone ? 'success' : ''" class="my-2">
            <b-card-header >
            {{boerse.name}}
            <b-badge variant="success" v-if="boerse.isDone" class="float-right mt-1">Erledigt</b-badge>
            <b-badge variant="" v-else-if="boerse.fitsForPage" class="float-right mt-1">Empfohlen</b-badge>
            <router-link :to="{ name: 'editFerienboerse', params: { id: boerse.id }}"><span class="oi oi-pencil mx-1"></span></router-link>
            </b-card-header>
            <b-card-body>
              <b-card-text>
                <div  v-html="boerse.description"></div>
                <b-button class="my-2" :variant="boerse.fitsForPage ? 'success' : ''" v-if="!boerse.isDone && !boerse.isMarkedAsDoesNotFit" @click="$refs.filler.run(boerse)">Eintragen</b-button>
                <entryEditor :boerse="boerse" :page="page"/>
              </b-card-text>
            </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <fillFerienboerse ref="filler" :page="page"/>

  </b-col>
</template>



<script>
import fillFerienboerse from "./fillFerienboerse.vue"
import entryEditor from "./ferienboerseEntryEditor.vue"
import { mapState } from 'vuex'


export default {

  name: 'Fereinboersen',
  data(){
    return {
      page: {},
      boersen:[]
    };
  },
  props: [],
  components: {
    fillFerienboerse,
    entryEditor
  },
  created(){
    this.loadPage(this.$route.params.pid);
    this.$store.dispatch("loadUser");
  },

  methods:{
    sortBoersen(){
      this.boersen.sort(function(a, b){
        function score(boerse){
          if(boerse.fitsForPage && !boerse.isDone)
            return 2;
          if(boerse.fitsForPage)
            return 1;
          return 0;
        }

        return score(b) - score(a);
      });
    },

    loadPage(id){
      var t = this;
      $.get(this.$root.api_root + "flyer/get_page.php",{
        pid: id
      },function(data){
        t.$root.checkRespForLoginFail(data);
        t.page = JSON.parse(data);
      });

      $.get(this.$root.api_root + "flyer/getBoersenForPage.php",{
        pid: id
      },function(data){
        t.$root.checkRespForLoginFail(data);
        t.boersen = JSON.parse(data);
        t.sortBoersen();
      });
    },
  },
  beforeRouteUpdate (to, from, next) {
    this.loadPage(to.params.pid);
    next();
  },
  computed:{
    ...mapState({
      user: state => state.base.user,
    })
  },
}

</script>
