export default {
  state: {
    funktionen: null,
    funktionenLoading: false,
    emailRecipiants: []
  },
  mutations: {
    setFunktionen(state, funktionen){
      state.funktionen = funktionen;
      state.funktionenLoading = false;
    },
    setFunktionenLoading(state){
      state.funktionenLoading = true;
    },
    setEmailRecipiants(state, recipiants){
      state.emailRecipiants = recipiants;
    }
  },
  actions: {
    loadFunktionen({ commit, state }, force = false){
      var t = this;
      if((state.funktionen != null && !force) || state.funktionenLoading)
          return; //allreaddy loaded
        commit("setFunktionenLoading");
      $.get(api + "seminare/getFunktionen.php",{
      },function(res){
        t.checkRespForLoginFail(res);
        commit("setFunktionen", JSON.parse(res));
      });
    }
  }
}
