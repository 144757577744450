<template>
    <datetime v-model="date"  :placeholder="placeholder" class="input-group" input-class="form-control" :format="format" :auto="true" :disabled="disabled" :type="selectTime ? 'datetime' : 'date'" value-zone="local">
        <div slot="after" class="input-group-append">
            <b-button id="clearBtn" @click="clear"><span class="oi oi-delete"></span></b-button>
        </div>
    </datetime>
</template>

<script>

import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css'

export default {

  name: 'Datepicker',
  props:{
    "value":{},
    "placeholder":{default: ""},
    disabled:{default: false},
    selectTime: {default: false}
  },
  data(){
    return {
        date: "",
    };
  },
  components:{
    datetime: Datetime
  },
  computed:{
    format(){
        if(this.selectTime)
            return "dd.LL.y HH:mm";
        return "dd.LL.y";
    }
  },
  created(){
    this.valueChanged(this.value);
  },
  watch:{
    value(v){
        this.valueChanged(v);
    },
    date(iso){
        console.log("new iso:" + iso);
        if(iso == ""){
            this.$emit("input", null);
            return;
        }
        var t = Date.parse(iso);
        if(t != this.value)
            this.$emit("input", t);
    }
  },
  methods:{
    valueChanged(v){
        if(v == null || v == ""){
            this.date = "";
            return;
        }
        
        var iso = this.moment(v).toISOString();
        if(this.date != iso)
        this.date = iso;
        console.log("set ios:" + iso);
    },
    clear(){
        this.$emit("input", null);
    }
  }
}
</script>

<style scoped>
#clearBtn{
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

</style>
