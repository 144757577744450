<template>
  <div>
    <b-form-group label="Gruppierung">
        <b-form-select v-model="groupBy" v-if="semId == null">
            <b-form-select-option value="">Keine</b-form-select-option>
            <b-form-select-option value="year">Jahr</b-form-select-option>
            <b-form-select-option value="month">Monat</b-form-select-option>
            <b-form-select-option value="sem">Seminar</b-form-select-option>
        </b-form-select>
    </b-form-group>
    <template v-if="statsLoaded">
        <pieStat v-if="groupBy == ''" :stats="stats" :semId="semId"/>
        <barStat v-else :groupBy="groupBy" :stats="stats"/>
    </template>
  </div>
</template>


<script>

import pieStat from "./pie.vue"
import barStat from "./bar.vue"

export default {
  name: 'AdvertisementStatistics',
  data(){
    return {
      stats: [],
      groupBy: "",
      statsLoaded: false
    }
  },
  props:{
    semId:{default:null}
  },
  components:{
    pieStat,
    barStat
  },
  methods:{
    loadStatistics(){
      var t = this;
      t.statsLoaded = false;
      $.get(this.$root.api_root + "seminare/getAdvMediumStats.php",{
        group: this.groupBy,
        semId: this.semId
      },function(res){
        t.$root.checkRespForLoginFail(res);
        t.stats = JSON.parse(res);
        t.statsLoaded = true;
      });
    },
  },
  created(){
    this.loadStatistics();
  },
  computed:{
  },
  watch:{
    groupBy(){
        this.loadStatistics();
    }
  }
}

</script>
