<template>
  <input ref="search" v-model="searchText" type="text" @blur="blur()"  @keyup.enter="enter" @input="selectedText=''" :disabled="disabled" />
</template>


<script>


export default {
  name: 'ItemPicker',
  data(){
    return {
        searchText:"",
        selectedText:""
    };
  },
  model: {
    prop: 'value',
    event: 'input'
  },

  props: {
    "value":{

    },
    disabled:{
      default: false
    },
    "ignId":{
      default: 0
    },
    "ignChildren":{
      defualt: 0
    },
    "clearOnBlur":{
      type: Boolean,
      default:true
    },
    enteredText:{
      type:String,
      defualt: ""
    }
  },
  created(){

  },
  mounted() {
    var t = this;
        $(this.$refs.search).autocomplete({
            source: function( request, response ) {
                $.ajax( {
                    url: t.$root.api_root + "stock/searchItem.php",
                    data: {
                        startingWith: request.term,
                        ign: t.ignId,
                        ignChildren: t.ignChildren,
                        flat: "true"
                    },
                    success: function( data ) {
                        t.$root.checkRespForLoginFail(data);
                        data = JSON.parse(data);
                        response( data );
                    }
                } );
            },
            select:function(event,ui){
                console.log(ui.item.iid);
                t.selectedText = ui.item.name;
                t.$emit("input",{id: ui.item.iid, name: ui.item.name});
            }
        });
  },
  methods:{
    blur(){
      if(this.clearOnBlur)
        this.searchText = this.selectedText;

      if(this.selectedText == "")
         this.$emit("input",null);
    },
    enter(){
      this.$emit( "enter");
    }
  },
  computed:{

  },
  watch:{
    value(v){
      var t = this;
      if(v == null){
        if(this.enteredText == ""){
          this.searchText = "";
          this.selectedText = "";
        }
        else{
          this.selectedText = this.searchText = this.enteredText;
        }
      }
      else{
        t.searchText = v.name;
        t.selectedText = t.searchText;
      }
    },
    searchText(){
      this.$emit("update:enteredText",this.searchText);
    }
  }
}

</script>

<style scoped>



</style>

