<template>
  <div id="editor">
    <VueEditor ref="veditor" :value="value" @input="setVal" :disabled="disabled" useCustomImageHandler   @imageAdded="handleImageAdded" :editorToolbar="editorToolbar"/>
    <b-modal title="Foto aufnehmen" ref="camModal" @shown="startStream" @hidden="stopStream" @ok="snap">
      <video id="video" width="100%" autoplay></video>
      <canvas id="canvas" width="640" height="480" style="display:none;"></canvas>
    </b-modal>
  </div>
</template>


<script>
import { VueEditor } from 'vue2-editor'

export default {
  name: 'ItemEditor',
  data(){
    return {
      editorToolbar:[
            [{ 'header': 1 }, { 'header': 2 }],
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['image',"photo", "link"]
          ],
          videoStream:null,
          cursorPos:null
    };
  },
  props:["disabled", "value"],
  components:{
    VueEditor
  },
  methods:{
    handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
      var t = this;
      this.compressImage(file,(compressedFile, compressedImage)=>{
        t.uploadImage(compressedFile, file.name, function(attachementId){
          Editor.insertEmbed(cursorLocation, 'image', t.$root.api_root + "stock/getImage.php?id=" + attachementId);
          resetUploader();
        });
      });

    },

    uploadImage(img, filename, cb){
      var t = this;
      var formData = new FormData();
      formData.append('attachment', img, filename);
      formData.append('inlineImage',true);
      var t = this;
      $.ajax({
        url: this.$root.api_root + "stock/uploadImage.php",
        data: formData,
        success: function(resp){
          t.$root.checkRespForLoginFail(resp);

          resp = JSON.parse(resp);
          if(!resp.success){
            alert(resp.error);
            return;
          }

          console.log("done");
          cb(resp.attachementId);

        },
        type: 'post',
        dataType: 'text', // what to expect back from the server
        cache: false,
        contentType: false,
        processData: false
      });
    },

    setVal(v){
      this.$emit("input",v);
    },
    compressImage(file, cb){
      var fr=new FileReader();
      fr.onload = function(e) {
        const img = new Image();
        img.onload = () => {

            const width = 600;
            const scaleFactor = width / img.width;
            const height = img.height * scaleFactor;
            const elem = document.createElement('canvas');
            elem.width = width;
            elem.height = height;
            const ctx = elem.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);
            //var compressedImage = ctx.canvas.toDataURL("image/jpeg", 0.8);
            ctx.canvas.toBlob(blob => {
               cb(blob,ctx.canvas.toDataURL("image/jpeg", 0.8));
            },"image/jpeg", 0.8);
            //cb(compressedImage);
        };

        img.src = this.result;
      };
      fr.readAsDataURL(file);
    },
    startStream(){
      var t = this;
      var video = document.getElementById('video');

      // Get access to the camera!
      if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          // Not adding `{ audio: true }` since we only want video now
          navigator.mediaDevices.getUserMedia({ video: true }).then(function(stream) {
              //video.src = window.URL.createObjectURL(stream);
              video.srcObject = stream;
              video.play();
              t.videoStream = stream;
          });
      }
    },
    stopStream(){
      this.videoStream.getTracks()[0].stop();
    },
    snap(){
      var t = this;
      var canvas = document.getElementById('canvas');
      var context = canvas.getContext('2d');
      var video = document.getElementById('video');

      context.drawImage(video, 0, 0, 640, 480);
      //var img = canvas.toDataURL();
      var quill = this.$refs.veditor.quill;

      context.canvas.toBlob(blob => {
      //quill.insertEmbed(this.cursorPos, 'image', img);
        t.uploadImage(blob, "cammera", function(attachementId){
            quill.insertEmbed(t.cursorPos, 'image', t.$root.api_root + "stock/getImage.php?id=" + attachementId);
        });
      });
    }
  },
  mounted(){
    var t = this;
    $(".ql-photo").click(() => {
      var sel = t.$refs.veditor.quill.getSelection();
      if(sel)
        t.cursorPos = sel.index;
      else
        t.cursorPos = null;
      t.$refs.camModal.show();
    });
  }

}

</script>

<style scoped>
 >>> #quill-container{
  height: auto;
}
>>> .ql-editor{
  height: auto;
}

>>> .quillWrapper{
  background: #fff;
}

>>> .ql-photo{
  background: url("../assets/camera-slr-2x.png") no-repeat center !important;

}
</style>
