<template>
  <aDetails ref="details" :aktiver="aktiver" label="Adresse" headline="Adressen" :enterDate.sync="aktiver.addressChanged" semanmeldField="adresse" :enteredValue="enteredAddress"
    :takeoverClicked="takeoverClicked" @add="add">
    <template slot="addRow" slot-scope="data">
      <td colspan="2">
        <b-row>
          <b-col cols="5"><b-input placeholder="Strasse" v-model="addStrasse"/></b-col>
          <b-col cols="2"><b-input placeholder="Plz" v-model="addPlz" /></b-col>
          <b-col cols="5"><b-input placeholder="Ort" v-model="addOrt" /></b-col>
        </b-row>
      </td>
    </template>
  </aDetails>

</template>


<script>

import aDetails from './viewAktivenDetails.vue'

export default {
  name: 'ViewAktivenDetailsAddress',
  components:{
    aDetails
  },
  data(){
    return {
      addStrasse: "",
      addPlz: "",
      addOrt: ""
    };
  },
  props:["aktiver", "hasDifferent"],
  computed:{
    enteredAddress(){
      if(this.aktiver.enteredAddress == null)
        return "";
      return this.aktiver.enteredAddress.strasse + ", " + this.aktiver.enteredAddress.plz + " " + this.aktiver.enteredAddress.ort;
    }
  },
  methods:{
    takeoverClicked(row){
      console.log(row);
      if(row.item.anmeld == null){
        this.aktiver.strasse = this.aktiver.enteredAddress.strasse;
        this.aktiver.ort = this.aktiver.enteredAddress.ort;
        this.aktiver.plz = this.aktiver.enteredAddress.plz;
        return; //it is the entered value, so there is no need to change something
      }
      this.aktiver.enteredAddress.strasse = row.item.anmeld.strasse;
      this.aktiver.enteredAddress.ort = row.item.anmeld.gen_ort;
      this.aktiver.enteredAddress.plz = row.item.anmeld.plz;

      this.aktiver.strasse = row.item.anmeld.strasse;
      this.aktiver.ort = row.item.anmeld.gen_ort;
      this.aktiver.plz = row.item.anmeld.plz;
    },
    add(){
      this.aktiver.enteredAddress.strasse = this.addStrasse;
      this.aktiver.enteredAddress.plz = this.addPlz;
      this.aktiver.enteredAddress.ort = this.addOrt;

      this.aktiver.strasse = this.addStrasse;
      this.aktiver.plz = this.addPlz;
      this.aktiver.ort = this.addOrt;
    },
    show(){
      this.$refs.details.show();
    }
  }
}

</script>

<style scoped>


</style>


