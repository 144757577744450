<template>
  <b-col cols="6" offset=2>
    <h1>Anmeldung bearbeiten</h1>
    <b-form-row class="my-2">
      <b-col cols="2">Seminar</b-col>
<!--       <b-col cols="10"><b-input v-model="anmeld.seminar.name"/></b-col> -->
           <b-col cols="8"><searchField v-model="anmeld.seminar.id" :selectedLabel.sync="anmeld.seminar.name" :findNames="false" :findSems="true"/></b-col>
           <b-col cols="2"><b-button :disabled="anmeld.seminar.id == null" @click="gotoSem">Zum Seminar</b-button></b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Name</b-col>
      <b-col cols="5"><b-input v-model="anmeld.vorname" placeholder="Vorname"/></b-col>
      <b-col cols="5"><b-input v-model="anmeld.nachname" placeholder="Nachname"/></b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Geschlecht</b-col>
      <b-col cols="10">
        <b-form-radio-group id="sex-input" v-model="anmeld.sex" name="sex-input" required>
          <b-form-radio value="m">Männlich</b-form-radio>
          <b-form-radio value="f">Weiblich</b-form-radio>
          <b-form-radio value="d">Divers</b-form-radio>
        </b-form-radio-group>
      </b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Adresse</b-col>
      <b-col cols="10"><b-input v-model="anmeld.strasse" placeholder="Straße"/></b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="3" offset="2"><b-input v-model="anmeld.plz" placeholder="PLZ"/></b-col>
      <b-col cols="7" ><b-input v-model="anmeld.ort" placeholder="Ort"/></b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Telefon</b-col>
      <b-col cols="10"><b-input v-model="anmeld.telefon" placeholder="Telefon"/></b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">E-Mail</b-col>
      <b-col cols="10"><b-input v-model="anmeld.email" placeholder="E-Mail Adresse"/></b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Geburtstag</b-col>
      <b-col cols="10"><datepicker v-model="anmeld.gen_gebdatum" placeholder="Geburtstag"/></b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Kommentar</b-col>
      <b-col cols="10"><b-textarea v-model="anmeld.kommentar" placeholder="Kommentar" rows="4"/></b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Optionen</b-col>
      <b-col cols="10">
        <b-form-checkbox
          v-model="anmeld.mitglied">
          Mitglied
        </b-form-checkbox><br>
        <b-form-checkbox
          v-model="anmeld.fahrgemeinschaften">
          Fahrgemeinschaften
        </b-form-checkbox><br>
        <b-form-checkbox
          v-model="anmeld.hidden">
          Versteckt
        </b-form-checkbox><br>
      </b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Bezahlt</b-col>
      <b-col cols="10"><bezahltBtn :anmeldung="anmeld"/> (Wird sofort angewendet. Auch ohne Speichern)</b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Verpflegung</b-col>
      <b-col cols="10"><b-input v-model="anmeld.verpflegung" placeholder="Verpflegung"/></b-col>
    </b-form-row>
    <b-form-row class="my-2">
      <b-col cols="2" offset="2">
        <saveBtn ref="saveBtn" :saveable="anmeld" api="seminare/saveSeminaranmeldung.php" :validation="validate"/>
      </b-col>
    </b-form-row>
  </b-col>
</template>


<script>
import saveBtn from '../saveBtn.vue'
import searchField from './search.vue'
import bezahltBtn from './bezahltBtn.vue'
import datepicker from "../datepicker.vue"

export default {
  name: 'EditSeminaranmeldung',
  data(){
    return {
      anmeld:{
        seminar:{
          name:""
        }
      }
    }
  },
  components:{
    saveBtn,
    searchField,
    bezahltBtn,
    datepicker
  },
  methods:{
    loadSeminaranmeld(id){
      var t = this;
      $.get(this.$root.api_root + "seminare/getSeminaranmeldung.php",{
        id: id
      },function(res){
        t.$root.checkRespForLoginFail(res);
        if(res.trim().indexOf("not found") == 0){
          alert("Eintrag nicht gefunden");
          t.anmeld = {};
          t.$refs.saveBtn.reset();
          return;
        }
        t.anmeld = JSON.parse(res);
        t.$refs.saveBtn.reset();
      });
    },
    validate(){
      if(this.anmeld.seminar.id == null){
        alert("Bitte wähle ein Seminar aus!");
        return false;
      }
      if(this.anmeld.vorname == null || this.anmeld.vorname == "" || this.anmeld.nachname == null || this.anmeld.nachname == ""){
        alert("Bitte geb einen Namen ein!");
        return false;
      }
      return true;
    },
    gotoSem(){
      this.$router.push({ name: 'seminarInfo', params: { sid: this.anmeld.seminar.id } })
    }
  },
  created(){
    this.loadSeminaranmeld(this.$route.params.id);
  },
  computed:{

  },
  mounted(){
    var t = this;

  },
  beforeDestroy(){

  },
  beforeRouteUpdate (to, from, next) {
    if(! this.$refs.saveBtn.confirmLeave()){
      next(false);
      return;
    }
    this.loadSeminaranmeld(to.params.id);
    next();
  },
  beforeRouteLeave (to, from, next) {
    if(! this.$refs.saveBtn.confirmLeave()){
      next(false);
      return;
    }
    next();
  },
}

</script>

<style scoped>
table{
  background: #fff;
}

</style>
