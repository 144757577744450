<template>
    <b-col class="col-12 col-md-6 col-xl-4 offset-md-2" id="startBox">
        <h2>Hallo {{user.name | capitalize}}</h2>
        <Searchfield v-if="user.canViewSeminare || user.canFlyer" @found="goTo" :findNames="user.canViewSeminare"
                     :findGroups="user.canViewSeminare" :findSems="user.canViewSeminare" :findFlyer="user.canFlyer"
                     placeholder="Suchen"/>
        <div v-if="user.canViewSeminare || user.isFunktionOwner" class="my-4">
            <h4>Aktive und Seminare</h4>
            <router-link :to="{ name: 'seminarlist'}" v-if="user.canViewSeminare">Seminarliste<br></router-link>
            <router-link :to="{ name: 'funktionen'}" v-if="user.canViewSeminare">Funktionen</router-link>
            <br>
            <router-link :to="{ name: 'advSearch'}" v-if="user.canViewSeminare">Erweiterte Suche<br></router-link>
            <router-link :to="{ name: 'emails'}" v-if="user.canEditSeminare">E-Mails<br></router-link>
            <router-link :to="{ name: 'newAktiven'}" v-if="user.canEditSeminare">Eintrag anlegen<br></router-link>
            <router-link :to="{ name: 'aktivenDebugger'}" v-if="user.canEditSeminare">Fehlersuche<br></router-link>
            <router-link :to="{ name: 'aktiveLogs'}" v-if="user.canEditSeminare">Änderungen verfolgen<br></router-link>
            <template v-if="user.canSeeCriminalRecordCertificate">
                <router-link :to="{name: 'criminalRecordCertificates'}">Führungszeugnisse</router-link>
                <br></template>
        </div>
        <router-link :to="{ name: 'polls'}" v-if="user.canPoll">Umfragen<br></router-link>

        <div v-if="user.isFunktionOwner" class="my-2">
            <b>Von dir geleitete Funktionen:</b>
            <ul>
                <li v-for="f in user.ownedFunctions">
                    <router-link :to="{name: 'viewFunktion', params: {fid: f.id}}">{{f.name}}</router-link>
                </li>
            </ul>
        </div>


        <div v-if="user.ownSeminare.length > 0" class="my-2">
            <b>Deine Seminare:</b>
            <ul>
                <li v-for="s in user.ownSeminare">
                    <router-link :to="{name: 'seminarInfo', params: {sid: s.id}}">{{s.name}}</router-link>
                </li>
            </ul>
        </div>

        <router-link :to="{name: 'kindergruppen'}">Kindergruppen</router-link>

        <div v-if="user.canFlyer || user.canInsta" class="my-3">
            <h4>Öffentlichkeitsarbeit</h4>
            <router-link v-if="user.canFlyer" :to="{name: 'editFlyers'}">Flyer bearbeiten<br></router-link>
            <router-link v-if="user.canInsta" :to="{name: 'insta'}">Instagram Post erstellen</router-link>
        </div>

        <div v-if="user.canViewStock" class="my-3">
            <h4>Lagerverwaltung</h4>
            <router-link :to="{path: 'stock/'}">Lagerverwaltung</router-link>
        </div>

        <div v-if="user.isAdmin" class="my-3">
            <h4>Administration</h4>
            <router-link :to="{ name: 'users'}" v-if="user.isAdmin">Nutzer verwalten<br></router-link>
        </div>

        <div v-if="user.canViewFundMonitor" class="my-3">
            <h4>Fördertopfmonitor</h4>
            <router-link :to="{ name: 'fundOverview'}">Förderübersicht<br></router-link>
        </div>

        <div v-if="user.ownProjects.length > 0 || user.readProjects.length > 0" class="my-3">
            <h4>Haushalt</h4>
            <div class="my-2" v-if="user.ownProjects.length > 0">
                <b>Deine Aufstellungen:</b><br>
                <ul>
                    <li v-for="p in user.ownProjects">
                        <router-link :to="{ name: 'haushaltTable', params:{pid: p.id}}">{{p.name}}</router-link>
                        <br>
                        <a :href="'?page=group&project=' + p.id">(bearbeiten)</a>
                    </li>
                </ul>
            </div>

            <div class="my-2" v-if="user.readProjects.length > 0">
                <b>Andere Aufstellungen:</b><br>
                <ul>
                    <li v-for="p in user.readProjects">
                        <router-link :to="{ name: 'haushaltTable', params:{pid: p.id}}">{{p.name}}</router-link>
                        <br>
                    </li>
                </ul>
            </div>

        </div>

        <div v-if="user.isPwEditable" class="my-2">
            <h4>Einstellungen</h4>
            <userSettings/>
            <changePw/>
            <fido2-keys :user="user"></fido2-keys>
        </div>
    </b-col>
</template>


<script>
    import {mapState} from 'vuex'
    import Searchfield from "./seminare/search.vue"

    import userSettings from "./userSettings.vue"
    import changePw from "./changePw.vue"
    import fido2Keys from "./admin/fido2Keys.vue"

    export default {
        name: 'StartPage',
        data() {
            return {};
        },
        props: [],
        components: {
            Searchfield,
            changePw,
            userSettings,
            fido2Keys
        },
        methods: {
            goTo(found) {
                if (found.type == "name")
                    this.$router.push({name: "viewAktiven", params: {aid: found.id}});
                else if (found.type == "group")
                    this.$router.push({name: "viewFunktion", params: {fid: found.id}});
                else if (found.type == "seminar")
                    this.$router.push({name: "seminarInfo", params: {sid: found.id}});
                else if (found.type == "flyer")
                    this.$router.push({name: "editFlyer", params: {fid: found.id}});
            }
        },
        mounted() {
        },
        created() {
            this.$store.dispatch("loadUser");
        },
        computed: {
            ...mapState({
                user: state => state.base.user,
            })
        }
    }

</script>

<style scoped>
    #startBox {
        margin-top: 30px;
        padding-top: 20px;
        padding-bottom: 20px;
        background: #fff;
        border-radius: 4px;
    }
</style>
