<template>
  <b-alert :show="!locked" variant="warning">
    Diese Seite wird gerade von {{lockedBy | capitalize}} bearbeitet. Du kannst sie daher nicht bearbeiten!
  </b-alert>
</template>

<script>
var idCtr = 0;
export default {
  name: 'Lockmanager',
  props:["entity","id","locked"],
  data(){
    return {
      lockInterval:null,
      lockedBy:"",
      loadTime:0
    };
  },
  created(){
    this.lockInterval = setInterval(this.lock, 10000);
    this.lock();
  },
  mounted(){

  },
  beforeDestroy(){
    clearInterval(this.lockInterval);
  },

  methods:{
    lock(){
      this.lockInt(this.id);
    },
    lockInt(id){
      if(id == null)
        return;
      var t = this;
      $.get(this.$root.api_root + "getLock.php",{
        id: id,
        entity: this.entity,
        action:"lock"
      },function(data){
        t.$root.checkRespForLoginFail(data);
        if(data.indexOf("success") != -1)
          t.$emit("update:locked",true);
        else{
          t.$emit("update:locked",false);
          t.lockedBy = data.replace("failedalready locked by","").trim();
        }
      });
    },
    unlock(){
      return this.unlockInt(this.id);
    },
    unlockInt(id){
      if(id == null)
        return;
      var t = this;
      return new Promise(function(resolve, reject) {
        $.get(t.$root.api_root + "getLock.php",{
          id: id,
          entity: t.entity,
          action:"unlock"
        },function(data){
          t.$root.checkRespForLoginFail(data);
          resolve();
        });
      });
    },
  },
  watch:{
    id(newId,oldId){
      this.loadTime = Date.now();
      this.unlockInt(oldId);
      this.lockInt(newId);
    },
    locked(){
      var elapsedSinceLoad = Date.now() - this.loadTime;
      if(elapsedSinceLoad < 2000)
        return;
      this.$emit("reload");
    }
  }
}
</script>
