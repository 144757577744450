<template>
  <div>
    <b-alert variant="danger" :show="tooBig">
      <b>Dateianhänge zu groß!</b> Du darfst maximal 5MB verschicken. (Aktuell sind es {{Math.floor(sizeSum/100)/10}} MB)
    </b-alert>
    <ul>
      <li v-for="a,index in attachments">{{a.name}} ({{a.size}}  KB) <span class="btn-link" @click="rmAttachment(index)" v-if="!disabled">(x)</span></li>
    </ul>
    <Upload api="seminare/uploadAttachment.php" @uploaded="addFile" :pending.sync="uploadPending" :disabled="disabled"/>
  </div>
</template>



<script>
import Upload from "../upload.vue"

export default {
  name: 'EmailAttachments',
  props:["attachments", "isValid", "disabled"],
  data(){
    return {
      uploadPending: false
    };
  },
  components:{
    Upload
  },
  methods:{
    addFile(upload){
        console.log(upload.file);
       this.attachments.push({
        id: upload.id,
        name: upload.file.name,
        size: Math.floor(upload.file.size / 1000)
       });
    },
    rmAttachment(index){
      this.attachments.splice(index,1);
    },
    setIsValid(){
      this.$emit("update:isValid", (!this.uploadPending && !this.tooBig));
    }
  },
  computed:{
    sizeSum(){
      return this.attachments.reduce((c,e) => {
        return e.size + c;
      },0);
    },
    tooBig(){
      return this.sizeSum > 5000;
    }
  },
  watch:{
    tooBig(){
      this.setIsValid();
    },
    uploadPending(){
      this.setIsValid();
    }
  },
}

</script>
