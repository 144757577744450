<template>
  <div class="uploadBox">
      <label class="btn btn-default btn-info" :disabled="disabled">
          {{imgName}} auswählen <input ref="fileImput" type="file" name="image" style="display: none;" :disabled="disabled">
      </label>
      <div id="progressBar" ref="progressBar"></div>
  </div>
</template>
<script>
export default {
  name: 'ImageUpload',
  data(){
    return {
    };
  },
  props: {
    "imgName":{
      default: 'Bild'
     },
     disabled:{}
  },
  components: {

  },
  mounted(){
    var s = this;
    $(s.$refs.progressBar).hide();
    $(this.$refs.fileImput).change(function(){
		$(this).simpleUpload(s.$root.api_root + "flyer/imageUpload.php", {
            allowedExts: ["jpg", "jpeg"],
            allowedTypes: ["image/pjpeg", "image/jpeg"],
            start: function(file){
              //upload started
              //$('#filename').html(file.name);
              //$('#progress').html("");
              $(s.$refs.progressBar).width(0);
              $(s.$refs.progressBar).show();
            },

            progress: function(progress){
              //received progress
              //$('#progress').html("Progress: " + Math.round(progress) + "%");
              $(s.$refs.progressBar).width(progress + "%");
            },

            success: function(res){
              //upload successful
                      if(!res.success){
                          alert("upload fehlgeschlagen");
                          return;
                      }

                      var authorParts  = res.author.split(" ");
                      console.log(authorParts);

                      var authorFirst = "";
                      var authorLast = res.author;
                      if(authorParts.length == 2){
                        authorFirst = authorParts[0];
                        authorLast = authorParts[1];
                      }

                      s.$emit("uploaded",res.imageId, res.key, res.fileType, authorFirst, authorLast, res.canBeUsedOnFacebook);
                      $(s.$refs.progressBar).hide();
            },

            error: function(error){
              //upload failed
              alert("Bild konnte nicht hochgeladen werden!<br>" + error.name + ": " + error.message);
            }

          });

        });

  }
}
</script>

<style>

#progressBar {
	background-color: #3E6FAD;
	width: 0px;
	height: 30px;
	margin-top: 10px;
	margin-bottom: 10px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	-o-border-radius: 5px;
	border-radius: 5px;
	-moz-transition: .25s ease-out;
	-webkit-transition: .25s ease-out;
	-o-transition: .25s ease-out;
	transition: .25s ease-out;
}

</style>
