<template>
  <b-col cols="10" offset=2>
    <div class="row">
      <div class="col-md-8 col-md-push-2">

        <div class="form-group row">
            <div for="name" class="col-md-2 col-form-label">Name</div>
            <div class="col-md-10">
                <input class="form-control" type="text" v-model="item.name" :disabled="!editable"  placeholder="z.B. Spülkiste">
            </div>
        </div>

        <template v-if="!item.isDeleted">
        <lockManager ref="lockManager" :id="item.id" entity="StockItem" :locked.sync="locked" @reload="reload"/>
        <div class="form-group row">
            <div for="name" class="col-md-2 col-form-label">Alternativbegriffe (durch , getrennt)</div>
            <div class="col-md-10">
                <input class="form-control" type="text" v-model="item.synonyms" :disabled="!editable" placeholder="z.B. Putzkiste, Reinigungskiste">
            </div>
        </div>

        <div class="form-group row">
            <div for="name" class="col-md-2 col-form-label">Anzahl</div>
            <div class="col-md-10">
                <input class="form-control" type="number" :disabled="!editable" v-model="item.num">
            </div>
        </div>

        <div class="form-group row">
            <div for="name" class="col-md-2 col-form-label">Beschreibung</div>
            <div class="col-md-10" id="descEditBox">
                <descEditor v-model="item.description" :disabled="!editable" />
            </div>
        </div>

        <div class="form-group row">
            <div for="age" class="col-md-2 col-form-label">Ort</div>
            <div class="col-md-7">
                <div class="input-group" style="width:100%">
                  <div class="input-group-prepend">
                    <div class="input-group-text" style="width: 70px; text-align: left;">
                        <input type="radio" name="posType" @click="item.containing= null" :disabled="!editable" v-model="posType" value="place">
                    </div>
                  </div>
                  <select class="form-control" style="width:100px;" v-model="item.room" :disabled="posType != 'place' || !editable">
                      <option value="null">Raum wählen</option>
                      <option v-for="(room, roomId) in rooms" v-bind:value="roomId">
                        {{ room }}
                      </option>
                  </select>
                  <input id="ageFrom" name="ageFrom2" style="width:50%;" type="text" class="form-control" :disabled="posType != 'place' || !editable" v-model="item.place" />

                </div><!-- /input-group -->
            </div>
        </div>

        <div class="form-group row">
            <div for="age" class="col-md-2 col-form-label"></div>
            <div class="col-md-7">
                <div class="input-group" style="width:100%">
                  <div class="input-group-prepend">
                    <div class="input-group-text" style="text-align: left;">
                        <input type="radio" name="posType" @click="item.room = null; item.place = '';" v-model="posType" :disabled="!editable" value="contained"> &nbsp;Enthalten in
                    </div>
                  </div>

                  <itemPicker v-model="item.containing" @input="save()" :disabled="posType != 'contained' || !editable" class="form-control" :ignId="item.id" :ignChildren="1"></itemPicker>
                </div><!-- /input-group -->

            </div>
            <div class="col-md-1">
              <router-link :to="{ name: 'editItem', params: { iid: item.containing.id }}" v-if="item.containing != null">
                <span class="oi oi-link-intact btn-link"></span>
              </router-link>
            </div>
        </div>
        <div class="form-group row">
            <div for="name" class="col-md-2 col-form-label">Enthält</div>
            <div class="col-md-10">
                <ul>
                  <li v-for="i in item.contained">
                    <router-link :to="{ name: 'editItem', params: { iid: i.id }}">{{i.num}} {{i.name}}</router-link>
                  </li>
                </ul>
                <addItem @newItem="addItem" :containing="item.id" v-if="editable"></addItem>
            </div>
        </div>

        <div class="form-group row">
            <div for="name" class="col-md-2 col-form-label">Zerteilbar?</div>
            <div class="col-md-10">
                <label><input type="checkbox" :disabled="!editable" v-model="item.isAtomic"/> Diese Kiste / Sache darf nicht zerpflückt werden</label>
            </div>
        </div>

        <div class="form-group row">
            <div for="name" class="col-md-2 col-form-label">Todo</div>
            <div class="col-md-10">
                <b-textarea v-model="item.todo" placeholder='z.B. "Reparieren" oder "Bitte nachkaufen" ' :disabled="!editable"/>
            </div>
        </div>

        <div class="form-group row">
            <div for="name" class="col-md-2 col-form-label"></div>
            <div class="col-md-10">
                <router-link :to="{ name: 'printItem', params: { iid: item.id }}"><b-button class="my-2">Inhaltsliste drucken</b-button></router-link>
                <router-link :to="{ name: 'printItems', query: { iid: item.id }}"><b-button class="my-2">Beschriftung drucken</b-button></router-link>
                <button v-if="editable" class="btn btn-danger" v-b-modal.delModal>Löschen</button> ({{ isSaved ? "Gespeichert" : "Es wird automatisch gespeichert"}})
            </div>
        </div>

      </template>
      <b-alert show v-else variant="warning">
        Diese Sache wurde gelöscht.<br>
      </b-alert>
      </div>

        <b-modal ref="delModal" id="delModal" title="Sache löschen" cancel-only>
            <p>Möchtest du "{{item.name}}" wirklich löschen?</p>
            <div v-if="item.contained != null && item.contained.length != 0">
              <button class="btn btn-danger my-2" @click="deleteItem(false)">Löschen und alles Enthaltene behalten</button>
              <button class="btn btn-danger def my-2" @click="deleteItem(true)">Löschen und auch alles Enthaltene löschen</button>
            </div>
            <div v-else>
              <button class="btn btn-danger def" @click="deleteItem(false)">Wirklich löschen</button>
            </div>
        </b-modal>
    </div>
  </b-col>
</template>


<script>

import itemPicker from "./itemPicker.vue"
import addItem from "./addItem.vue"
import descEditor from "./descEditor.vue"
import { mapState } from 'vuex'
import lockManager from "../lockManager.vue"

export default {
  name: 'ItemEditor',
  data(){
    return {
      item:{
        containing: null
      },
      synText: "",
      posType: "place",
      timeout: null,
      isSaved: true,
      locked:false,
    };
  },
  components:{
    itemPicker,
    addItem,
    descEditor,
    lockManager
  },
  props: [],
  created(){
    var t = this;

     this.loadItem(this.$route.params.iid);
     this.$store.dispatch("loadUser");
     this.$store.dispatch("loadRooms");

//
  },
  mounted(){
    $(this.$refs.delModal).on('shown.bs.modal', function () {
       $('.def').trigger('focus');
    });
  },
  beforeRouteUpdate(to, from, next){
    var t = this;
    this.save(function(){
      console.log("reload: " + to.params.iid);
      t.loadItem(to.params.iid);
      next();
    });
  },
  beforeRouteLeave (to, from, next) {
    var t = this;
    this.save(function(){
      if(t.$refs.lockManager == null){
        next();
        return;
      }
      t.$refs.lockManager.unlock().then(function(){
        next();
      });
    });
  },
  beforeUpdate() {
    if(this.item.containing != null){
      this.posType = "contained";
    }
  },
  computed:{
    editable(){
      if(!this.locked)
        return false;
      if(this.user == null)
        return false;
      return this.user.canEditStock;
    },
    ...mapState({
      user: state => state.base.user,
      rooms: state => state.stock.rooms
    })
  },
  methods:{
    addItem(i){
      this.item.contained.push(i);
    },

    loadItem(id){
      var t = this;
      $.get(this.$root.api_root + "stock/getItem.php",{
        iid: id
      },function(data){

        t.$root.checkRespForLoginFail(data);
        data = JSON.parse(data);

        t.item = data;
        if(t.item.containing != null)
          t.posType = "contained";
        else
          t.posType = "place";
        t.$nextTick(function () {
          clearTimeout(t.timeout);// no saving needed as we caused it
          t.isSaved = true
        });
      });
    },

    save(then = function(){}){
      if(!this.locked){
        then();
        return;
      }

      if(this.isSaved){
        then();
        return;
      }
      var t = this;
      console.log("saving");
      $.post(this.$root.api_root + "stock/saveItem.php",{
        item: JSON.stringify(this.item)
      },function(data){
        t.$root.checkRespForLoginFail(data);
        clearTimeout(this.timeout);
        t.isSaved = true
        then();
      }).fail(function(err){
        alert("Konnte nicht speichern!");
      });

    },
    deleteItem(incContained){
      var t = this;
      $.get(this.$root.api_root + "stock/deleteItem.php",{
        iid: t.item.id,
        recursive: incContained
      },function(data){
        t.$root.checkRespForLoginFail(data);
        t.$refs.delModal.hide();
        if(t.item.containing != null){
          t.$router.push({ name: 'editItem', params: { iid: t.item.containing.id }})
        }
        else{
          t.$router.push({ name: 'stockStartPage'})
        }
      });
    },
    reload(){
      if(this.item.id != null)
        this.loadItem(this.item.id);
    }

  },
  watch:{
    item:{
      deep: true,
      handler(){
        this.isSaved = false;
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function(t){
          t.save();
        },5000,this);
      }
    }
  }
}

</script>

<style scoped>
#descEditBox >>> img{
  max-width: 300px;
}


</style>

