<template>
  <aDetails ref="details" :aktiver="aktiver" semanmeldField="gen_gebdatum" label="Geburtsdatum" :enteredValue.sync="aktiver.geburtstagEntered" :enterDate.sync="aktiver.geburtstagChanged" headline="Geburtstag" v-on:update:hasDifferent="updateHasDifferent" :selectedValue.sync="aktiver.geburtstag" @add="add">
    <template slot-scope="data">
        {{new Date(data.value).toLocaleDateString("de-DE")}}
    </template>
    <template slot="addRow" slot-scope="data">
      <td colspan="2">
        <datepicker v-model="dateInput" placeholder="Manuelle Eingabe"/>
      </td>
    </template>
  </aDetails>

</template>


<script>

import aDetails from './viewAktivenDetails.vue'
import datepicker from "../datepicker.vue"

export default {
  name: 'ViewAktivenDetailsGeburtstag',
  mounted(){

  },
  beforeDestroy(){

  },
  components:{
    aDetails,
    datepicker
  },
  data(){
    return {
      dateInput:null
    };
  },
  props:["aktiver", "hasDifferent"],
  methods:{
    add(){
      this.aktiver.geburtstag = this.aktiver.geburtstagEntered = this.dateInput;

    },
    updateHasDifferent(v){
      this.$emit("update:hasDifferent",v);
    },
    show(){
      this.$refs.details.show();
    }
  }
}

</script>

<style scoped>


</style>


