<template>
  <b-tab title="Weitere">
    <b-form-group>
    <b-table :items="available" striped :fields="[
        {key:'checkbox', label: ''},
        {key:'name'},
        {key:'start'},
        {key:'numAnmeld', label: '# Anmeldungen'},
    ]">
        <template v-slot:cell(checkbox)="data">
            <b-form-checkbox v-model="page.options.otherSems" :value="data.item.id" class="cbWithNoText"/>
        </template>
        
        <template v-slot:cell(start)="data">
            {{moment(data.value).format("DD.MM.Y")}}
        </template>
    </b-table>
    </b-form-group>
  </b-tab>
</template>
<script>


export default {
  name: 'OtherSemEditor',
  data(){
    return {
        available:[]
    };
  },
  props: ['page'],
  components: {
  
  },
  created(){
    if(this.page.options.otherSems == null){
        this.$set(this.page.options, "otherSems", []);
    }
  
    var t = this;
    $.get(this.$root.api_root + "flyer/getUpcomingPages.php",{
      },function(res){
        t.$root.checkRespForLoginFail(res);
        t.available = JSON.parse(res);
    });
  }
}
</script>

<style scoped>
table{
   background: #fff;
}

>>> .cbWithNoText label{
    display:inline;
}
</style>

