<template>
  <div class="question">
    <h5>{{question.name}}</h5>
    <p>{{question.desc}}</p>
    
    <choiceRes v-if="question.type=='simpleChoice' || question.type=='multipleChoice' || question.type=='simpleChoiceSelect'" :choices="question.choices" :replys="myReplys" :type="question.type" :names="names"/>
    <freeTextRes v-else :replys="myReplys" />
    
  </div>
</template>

<script>

import { mapState } from 'vuex'
import choiceRes from "./choiceRes.vue"
import freeTextRes from "./freeTextRes.vue"

export default {

  name: 'questionResults',
  data(){
    return{
    }
  },
  props:{
    "question":{}, 
    replys: {}
  },
  components:{
    choiceRes,
    freeTextRes
  },
  created(){
  },
  computed:{
    myReplys(){
        return this.replys.map(r => {
            return r.reply[this.question.id];
        });
    },
    names(){
        return this.replys.map(r => {
            return r.name;
        });
    }
  },
  methods:{
    
  },
  
}
</script>

<style scoped>
.question{
    background: #fff;
    padding: 10px;
    margin-bottom: 10px;
}

</style>
