<template>
  <draggableImage v-model="value" :disabled="disabled" :rightOriented="rightOriented" :url="url" v-bind:class="{ fullHeight: fullHeight, first: isFirst, second: !isFirst, right: rightOriented }"/>
</template>


<script>

function myXOR(a,b) {
  return ( a ? 1 : 0 ) ^ ( b ? 1 : 0 );
}

import draggableImage from "../../draggableImage.vue"
export default {
  name: 'DragableFlyerImage',
  data(){
    return {
      "boxWidth": 0
    };
  },
  components:{
    draggableImage
  },
  props: {
    value:{}, 
    fullHeight:{}, 
    isFirst: {}, 
    rightOriented:{},
    disabled:{},
    hq:{default: false}
    
  },
  computed: {
    url() {
      var ret = this.$root.api_root + "flyerImages/scaled/flyerImage-" + this.value.imid + "-" + this.value.key;
      
      if(this.hq)
        ret += "_hq";
        
      if(myXOR(this.value.mirror, this.rightOriented))
        ret += "_m";
      
      return ret + ".jpg";
    },
  },
}

</script>

<style scoped>

.flyerImage.first{
    width: 85%;
}


.flyerImage.right{
  right: 0px;
}

.flyerImage.fullHeight{
  height:100%;
  width: 100%;
}



</style>

