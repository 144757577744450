import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import BootstrapVue from 'bootstrap-vue'
import moment from 'moment-timezone'
import VueCookies from 'vue-cookies'
import Vue2Filters from 'vue2-filters'
import baseStore from "./baseStorage.js"

import defaultView from "./defaultView.vue"
import stripedView from "./stripedView.vue"
import startPage from "./start.vue"

import SeminarInfo from './seminare/seminarInfo.vue';
import EditSeminar from './seminare/editSeminar.vue';
import Seminarlist from './seminare/seminarlist.vue';
import AktiveLogs from './seminare/aktiveLogs.vue';
import NewAktiven from './seminare/newAktiven.vue';
import seminareStore from './seminare/seminareStorage.js';
import ViewAktiven from './seminare/viewAktiven.vue';
import AktivenDebugger from './seminare/aktivenDebugger.vue';
import Funktionen from './seminare/funktionen.vue';
import ViewFunktion from './seminare/viewFunktion.vue';
import EditFunktion from './seminare/editFunktion.vue';
import EditSeminaranmeldung from './seminare/editSeminaranmeldung.vue';
import Email from './seminare/email.vue';
import Emails from './seminare/emails.vue';
import advSearch from './seminare/advSearch.vue';
import editKindergruppe from './kindergruppen/editKindergruppe.vue'
import kindergruppen from './kindergruppen/kindergruppen.vue'
import criminalRecordCertificates from './seminare/criminalRecordCertificates.vue'
import advStatistics from "./seminare/advStatistics/advStatisticsPage.vue"

//Stock
import StockItemEditor from './stock/itemEditor.vue';
import StockPrintItems from './stock/printLabels.vue';
import StockPrintItem from './stock/printLabel.vue';
import StockStartPage from './stock/start.vue';
import StockEditList from './stock/editList.vue';
import stockStore from "./stock/stockStorage.js"

//flyer
import FlyerEditor from './flyer/flyerEditor.vue'
import PageEditor from './flyer/pageEditor.vue';
import PageRenderer from './flyer/pageRenderer.vue';
import FlyersEditor from './flyer/flyersEditor.vue';
import ferienboersen from './flyer/ferienboersen/ferienboersen.vue';
import editFerienboerse from './flyer/ferienboersen/editFerienboerse.vue';
import instaPoster from "./instaPoster/instaPoster.vue"

import User from "./admin/user.vue";
import Users from "./admin/users.vue";

//fundmonitor
import fundOverview from "./fundmonitor/fundOverview.vue"
import editFeederbowel from "./fundmonitor/editFeederbowel.vue"
import editFeederbowelYear from "./fundmonitor/editFeederbowelYear.vue"
import editFundEntry from "./fundmonitor/editFundEntry.vue"
import showFeederbowlYear from "./fundmonitor/showFeederbowlYear.vue"
import searchFundEntries from "./fundmonitor/searchFundEntries.vue"
import showGuesses from "./fundmonitor/showGuesses.vue"
import fundStorage from "./fundmonitor/fundStorage.js"

//haushalt
import haushaltTable from "./haushalt/table.vue"
import haushaltStorage from "./haushalt/haushaltStorage.js"

//poll
import editPoll from "./poll/editPoll.vue"
import polls from "./poll/polls.vue"
import runPoll from "./poll/runPoll.vue"
import pollRes from "./poll/pollRes.vue"

require("../public/jquerry.min.js");

require("../public/bootstrap.min.css");
require("../node_modules/bootstrap-vue/dist/bootstrap-vue.css");
require("../public/open-iconic-bootstrap.css");
require("../public/fonts.css");


const routes = [
  { path: '/', component: defaultView, children:[
    { path: '/', component: startPage, name:"start" },

    { path: '/seminarInfo/:sid', component: SeminarInfo, name:"seminarInfo" },
    { path: '/editSeminar/:sid', component: EditSeminar, name:"editSeminar" },
    { path: '/seminarlist', component: Seminarlist, name:"seminarlist" },
    { path: '/aktiveLogs', component: AktiveLogs, name:"aktiveLogs" },
    { path: '/aktiveLogs/:aid', component: AktiveLogs, name:"aktiverLogs" },
    { path: '/newAktiven/', component: NewAktiven, name:"newAktiven" },
    { path: '/viewAktiven/:aid', component: ViewAktiven, name:"viewAktiven" },
    { path: '/aktivenDebugger/', component: AktivenDebugger, name:"aktivenDebugger" },
    { path: '/funktionen/', component: Funktionen, name:"funktionen" },
    { path: '/viewFunktion/:fid', component: ViewFunktion, name:"viewFunktion" },
    { path: '/editFunktion/:fid', component: EditFunktion, name:"editFunktion" },
    { path: '/editSeminaranmeldung/:id', component: EditSeminaranmeldung, name:"editSeminaranmeldung" },
    { path: '/email/:id?', component: Email, name:"email" },
    { path: '/emails/', component: Emails, name:"emails" },
    { path: '/advSearch', component: advSearch, name:"advSearch" },
    { path: '/kindergruppe/:id', component: editKindergruppe, name:"editKindergruppe" },
    { path: '/kindergruppen/', component: kindergruppen, name:"kindergruppen" },
    { path: '/criminalRecordCertificates/', component: criminalRecordCertificates, name:"criminalRecordCertificates" },
    { path: '/advStatistics/', component: advStatistics, name:"advStatistics" },


    { path: '/stock/editItem/:iid', component: StockItemEditor, name:"editItem" },
    { path: '/stock/printItems/', component: StockPrintItems, name:"printItems" },
    { path: '/stock/printItem/:iid', component: StockPrintItem, name:"printItem" },


    { path: '/stock/editList/:lid', component: StockEditList, name:"editList" },
    { path: '/stock/', component: StockStartPage, name:"stockStartPage" },

    { path: '/flyer/editPage/:pid', component: PageEditor, name:"editPage" },
    { path: '/flyer/editFlyer/:fid', component: FlyerEditor, name: "editFlyer" },
    { path: '/flyer/', component: FlyersEditor, name: "editFlyers" },
    { path: '/flyer/ferienboersen/:pid', component: ferienboersen, name: "ferienboersen" },
    { path: '/flyer/editFerienboerse/:id', component: editFerienboerse, name: "editFerienboerse" },

    { path: '/insta/', component: instaPoster, name: "insta" },

    { path: '/admin/users/', component: Users, name: "users" },
    { path: '/admin/user/:uid', component: User, name: "user"},

    { path: '/fundmon/', component: fundOverview, name: "fundOverview"},
    { path: '/fundmon/editFeederbowel/:id?', component: editFeederbowel, name: "editFeederbowel"},
    { path: '/fundmon/editFeederbowelYear/:id?', component: editFeederbowelYear, name: "editFeederbowelYear"},
    { path: '/fundmon/editFundEntry/:id?', component: editFundEntry, name: "editFundEntry"},
    { path: '/fundmon/showFeederbowlYear/:id?', component: showFeederbowlYear, name: "showFeederbowlYear"},
    { path: '/fundmon/searchFundEntries/', component: searchFundEntries, name: "searchFundEntries"},
    { path: '/fundmon/showGuesses/', component: showGuesses, name: "showGuesses"},
    
    { path: '/polls/', component: polls, name: "polls"},
    { path: '/polls/editPoll/:id', component: editPoll, name: "editPoll"},

    { path: "/haushalt/table/:pid", component: haushaltTable, name: "haushaltTable"},
  ] },
  
  { path: '/', component: defaultView, props:{noLoginRequired: true}, children:[
    { path: '/polls/:id', component: runPoll, name: "runPoll"},
    { path: '/polls/res/:id', component: pollRes, name: "pollRes"},
  ]},
  
  { path: '/', component: stripedView, children:[
      { path: '/flyer/renderPage/:pid', component: PageRenderer, name:"renderPage" },
  ]},

];


const router = new VueRouter({
  routes // short for `routes: routes`
});


Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(Vue2Filters)
Vue.use(Vuex)
Vue.use(VueCookies)

require('bootstrap-vue/dist/bootstrap-vue.css');

function checkRespForLoginFail(resp){
  if(resp.indexOf("not loged in") == 0){
    //window.location = "?page=login&path=" + encodeURIComponent(window.location.pathname + window.location.search + window.location.hash);
    store.dispatch("logedOut");
    return false;
  }
  return true;
}


window.api = process.env.VUE_APP_API_ROOT;

const store = new Vuex.Store({
  modules:{
    seminare: seminareStore,
    base: baseStore,
    stock: stockStore,
    fund: fundStorage,
    haushalt: haushaltStorage,
  },
  state: {

  },
  mutations: {
    increment (state) {
      state.count++
    }
  },
  actions:{
  }
});

store.checkRespForLoginFail = checkRespForLoginFail;


moment.tz.setDefault("Europe/Berlin");
Vue.prototype.moment = moment;


new Vue({
  router,
  data:{
    api_root: api,
    full_api_root: process.env.VUE_APP_FULL_API_ROOT,
    loadedScripts:[],
    loadedStyles:[]
  },
  store,
  methods:{
    checkRespForLoginFail: checkRespForLoginFail,
    loadScript(url, cb){
      if(this.loadedScripts.includes(url)){
          cb();
          return;
      }

      this.loadedScripts.push(url);
      $.getScript(url,cb);
    },

    loadStyle(url){
      if(this.loadedStyles.includes(url))
          return;
      $('head').append( $('<link rel="stylesheet" type="text/css" />').attr('href', url) );
      this.loadedStyles.push(url);
    }
  },
  created(){
    $.ajaxSetup({
        xhrFields: {
          withCredentials: true
        },
        cache: true,
    //    crossDomain: true
    });
  }
}).$mount('#app');

