<template>
  <b-col class="col-lg-8 col-xl-8 col-12 offset-lg-2" v-if="user.canInsta">
    <h1>Instagram-Post erstellen</h1>
    <b-row>
      <imgEditor v-for="imageEditor in imageEditors" :callback.sync="imageEditor"/>
    </b-row>
    <b-button variant="info" @click="addImage">Weiteres Bild</b-button>
    <!--<b-textarea v-model="text" rows="6" class="my-2" placeholder="Post Text"/>-->
    <b-button @click="post" variant="success" class="mx-1">Bilder Generieren</b-button>
    <template v-if="images.length > 0">
      <h2>Bilder speichern:</h2>
      <p>
        Um die Bilder herunter zu laden klicke mit der Rechten Maus und wähle Bild speichern.
      </p>
      <img :src="i" v-for="i in images" width="100px"/>
    </template>
  </b-col>

</template>


<script>
import { mapState } from 'vuex'
import  imgEditor from "./instaImgEditor.vue"
export default {
  name: 'InstaPoster',
  data(){
    return {
      imageEditors:[{cb:null}],
      text:"",
      images:[]
    };
  },
  props:[],
  components:{
    imgEditor
  },
  created(){
    this.$store.dispatch("loadUser");
  },
  methods:{
    post(){
      var t = this;
      Promise.all(this.imageEditors.map(e => e.cb())).then(images => {
        console.log(images);
        t.images = images;
      });
    },
    addImage(){
      this.imageEditors.push({cb:null});
    }
  },
  computed:{
    ...mapState({
      user: state => state.base.user,
    })
  }
}
</script>
