<template>
  <b-col cols="6" offset=2>
    <h1>
      {{funktion.name}}
      <router-link :to="{ name: 'editFunktion', params: {fid: funktion.id}}" v-if="user.canEditSeminare">(bearbeiten)</router-link>
    </h1>
    <Seachfield placeholder="Aktiven hinzufügen" @found="addAktiven" :resetOnFound="true" v-if="user.canEditSeminare"/>
    {{funktion.member.length}} Mitglieder<br>
    <b-table striped :items="funktion.member" :fields="fields">
      <template v-slot:cell(name)="data">
        <router-link :to="{ name: 'viewAktiven', params: { aid: data.item.id }}">{{data.value}}</router-link>
      </template>
      <template v-slot:cell(checkbox)="data">
        <b-form-checkbox v-model="data.item.checked"></b-form-checkbox>
      </template>
    </b-table>
    <b-button variant="primary" @click="setAll(true)" :disabled="allSelected">Alle auswählen</b-button>
    <b-button variant="primary" @click="setAll(false)" :disabled="!anySelected">Keine auswählen</b-button>
    <b-button variant="danger" @click="rmAktiveClicked" :disabled="! anySelected">Austragen</b-button>
    <b-button variant="success" @click="email" :disabled="!anySelected" v-if="user.canEditSeminare || isOwn">E-Mail</b-button>
  </b-col>
</template>


<script>
import Seachfield from "./search.vue"
import { mapState } from 'vuex'

export default {
  name: 'ViewFunktion',
  data(){
    return {
      funktion:{
        name:"",
        member: []
      },
      fields:[
       {key:"checkbox", label: "Auswahl", thStyle:"width: 50px", 'class': 'text-center'},
       {key:"name", label: "Name", sortable:true},
       {key:"email", label: "E-Mail Adresse"},
      ]
    }
  },
  components:{
    Seachfield
  },
  methods:{
    loadFunktion(id){
      var t = this;
      $.get(this.$root.api_root + "seminare/getFunktion.php",{
        gid: id
      },function(res){
        t.$root.checkRespForLoginFail(res);
        if(res.trim().indexOf("not found") == 0){
          alert("Eintrag nicht gefunden");
          t.funktion = {};
          return;
        }
        t.funktion = JSON.parse(res);
        t.funktion.member = t.funktion.member.map(m => {
          return {
            id: m.id,
            name: m.vorname + " " + m.nachname,
            email: m.email,
            checked:false
          }
        });
      });
    },
    addAktiven(params){
      console.log(params);
      if(this.ids.includes(params.id))
        return;
      var t = this;
      $.get(this.$root.api_root + "seminare/get_jugenddbGruppe_addAktiven.php",
      {
          aid: params.id,
          gid: t.funktion.id
      },
      function(res){
        t.$root.checkRespForLoginFail(res);
        var addInfo = JSON.parse(res);
        t.funktion.member.push({
          id: addInfo.id,
          name: addInfo.vorname + " " + addInfo.nachname,
          email: addInfo.email,
          checked: false
        });
      });
    },
    rmAktiveClicked(){
      if(!confirm(this.selected.length + " Aktive wirklich austragen?"))
        return;
        var t = this;

        $.get(this.$root.api_root + "seminare/get_jugenddbGruppe_rmAktiven.php",
        {
            gid: t.funktion.id,
            aktive: JSON.stringify(t.selectedIds)
        },
        function(resp){
            t.$root.checkRespForLoginFail(resp);
            if(resp.indexOf("success") != -1){
                for(var i = 0; i < t.funktion.member.length; ){
                  if(t.selectedIds.includes(t.funktion.member[i].id))
                    t.funktion.member.splice(i,1)
                  else i++;
                }
            }
        });
    },
    setAll(v){
      this.funktion.member.forEach(m => {
        this.$set(m, "checked", v);
      });
    },
    email(){
      this.$store.commit("setEmailRecipiants",this.selected.map(a => {return {
        name: a.name,
        id:a.id,
        reason:{type: "group", id: this.funktion.id}
      }}));
      this.$router.push({ name: 'email'});
    }
  },
  created(){
    this.loadFunktion(this.$route.params.fid);
    this.$store.dispatch("loadUser");
  },
  computed:{
    ids(){
      return this.funktion.member.map(m => m.id);
    },
    anySelected(){
      return this.selected.length > 0;
    },
    allSelected(){
      return this.selected.length == this.funktion.member.length;
    },
    selected(){
      return this.funktion.member.filter(m => m.checked);
    },
    selectedIds(){
      return this.selected.map(m => m.id);
    },
    ...mapState({
      user: state => state.base.user,
    }),
    isOwn(){
      var ownedIDs = this.user.ownedFunctions.map(x => x.id);
      return ownedIDs.indexOf(this.funktion.id) != -1;
    }
  }
}

</script>

<style scoped>
table{
  background: #fff;
}

</style>
