<template>
  <b-col class="col-lg-8 col-12 offset-lg-2" >
    <h1>Förderübersicht</h1>
    <router-link :to="{ name: 'editFundEntry', params: {  }}"><b-button class="my-2" variant="success">Neue Buchung</b-button></router-link>
    <router-link :to="{ name: 'editFeederbowel', params: {  }}"><b-button class="my-2" variant="success">Neuer Fördertopf</b-button></router-link>
    <router-link :to="{ name: 'searchFundEntries', params: {  }}"><b-button class="my-2" variant="info">Buchung suchen</b-button></router-link>
    <router-link :to="{ name: 'showGuesses', params: {  }}"><b-button class="my-2" variant="info">Schätzungen</b-button></router-link>
    <b-row>
      <template v-for="pot in feederbowls">
        <b-col class="col-6 col-md-4 col-xl-3">
          <router-link :to="{ name: 'showFeederbowlYear', params: { id: pot.yearId}}">
            <h4>{{pot.name}}</h4>
          </router-link>
          <potDiagramm :amount="pot.amount" :spent="pot.spent" :should="pot.expect"/>
        </b-col>
      </template>
    </b-row>

  </b-col>
</template>

<script>

import { mapState } from 'vuex'

import potDiagramm from "./potDiagramm/potDiagramm.vue"

export default {

  name: 'FundOverview',
  data(){
    return{
      feederbowls:[]
    }
  },
  components:{
    potDiagramm
  },
  created(){
    var t = this;
    $.get(this.$root.api_root + "fundmonitor/getOverview.php",{
      },function(res){
        t.$root.checkRespForLoginFail(res);
        t.feederbowls = JSON.parse(res);
    });
  }
}
</script>
<style scoped>
h4{
    margin-top:20px;
}
</style>
