<template>
  <div id="imgRightsBox" v-bind:class="{ right:  rightOriented, left: !rightOriented}">
    <span v-if='images.image1.imid != null && images.image2.imid == null'>Bild: {{formatImageAuthor(images.image1)}}</span>
    <span v-if='images.image1.imid != null && images.image2.imid != null'>Bilder: {{formatImageAuthor(images.image1)}}, {{formatImageAuthor(images.image2)}}</span>
  </div>
</template>


<script>
export default {
  name: 'ImageRightsBox',
  data(){
    return {};
  },
  props: ['images', 'rightOriented'],
  methods:{
    formatImageAuthor(image){
      var ret = "";
      if(image.authorFirst != "")
        ret += image.authorFirst.substr(0,1) + ". ";

      return ret + image.authorLast;
    }
  }
}

</script>

<style>

#imgRightsBox{
  font-size: 10px;
  position: absolute;
  bottom: 5mm;
  color: #666;
}

#imgRightsBox.left{
  right: 45px;
}

#imgRightsBox.right{
  left: 45px;
}

</style>

