<template>
  <div>
    <imageSelector ref="imageSelector" :page="page"/>
    <b-modal ref="fillModal" :title="'Auf ' + boerse.name + ' übertragen'" size="lg">
      <b-alert variant="info" :show="state=='connecting'">Verbindung wird hergestellt...</b-alert>
      <b-alert variant="danger" :show="state=='conError'">
        <b>Verbindungsfehler</b>
        <p class="my-1">Um Daten auf Ferienbörsen übertragen zu können, muss auf deienm PC ein spezielles Programm laufen. Dieses konnte nicht erreicht werden.</p>
        <p class="my-1">Im Jugenbüro startest du es unter <code>R:/programme/FormFiller/formFiller.exe</code></p>
        <p class="my-1">Falls das Programm bereits läuft musst du noch eine Sicherheitsfunktion von chrome deaktivieren. Gebe dazu <code>chrome://flags/#allow-insecure-localhost</code> im Browser ein und gehe auf <code>enable</code>.</p>
      </b-alert>
      <b-alert variant="danger" :show="state=='versionError'">
        <b>FormFiller veraltet</b>
        <p class="my-1">Um Daten auf Ferienbörsen übertragen zu können, muss auf deienm PC ein spezielles Programm (FormFiller) laufen. Dieses konnte zwar erreicht werden, ist aber <b>veraltet</b>. Bitte lade eine neue Version herunter.</p>
      </b-alert>
      <template v-if="state=='connected'">
        <b-checkbox v-model="editScript">FormFiller Script bearbeiten</b-checkbox>
        <fillCodeEditor :boerse="boerse" :page="page" v-if="editScript"/>
        <b-button class="my-2" @click="fill">Übertragen</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import fillCodeEditor from "./fillFerienboerseFillCodeEditor.vue"
import imageSelector from "./imageSelector.vue"

export default {
  name: 'Fereinboersen',
  data(){
    return {
      boerse:{},
      ws: null,
      state:"connecting",
      editScript:false
    };
  },
  props: ["page"],
  components: {
    fillCodeEditor,
    imageSelector
  },
  methods:{
    run(boerse){
      this.state="connecting";
      this.boerse = boerse;
      this.$refs.fillModal.show()
      this.connect()
    },
    connect(){
      if(this.ws != null){
        this.ws.close();
      }

      var t = this;
      this.ws = new WebSocket("wss://localhost:8765");
      this.ws.onerror=function(event){
        t.state="conError";
      }
      this.ws.onmessage=function(event){
        var msg = JSON.parse(event.data)
        if(msg.version != null){
          if(msg.version >= 1){//set min version here
            t.state="connected";
          }
          else{
            t.state="versionError";
          }
        }
        if(msg.error != null){
          alert(msg.error);
        }
      }
    },
    fill(){
      var t = this;

      var getFillScript = null;
      eval("getFillScript = async function(page, moment, selectImage){" + this.boerse.jsCode + "}");

      getFillScript(this.page, this.moment, t.$refs.imageSelector.selectImage).then(fillScript => {
        console.log(fillScript);
        this.ws.send(JSON.stringify({"type":"runPlan", "plan": fillScript, "api": t.$root.full_api_root}));
      });

    }
  }
}
</script>
