<template>
  <div id="wrapperBox">
        <flyerImageBox v-model="page.images" :rightOriented="page.rightOriented" v-if="page.template == 'semSinglePage' || page.template == 'semDoublePage'" :disabled="!page.locked"></flyerImageBox>
        <headline :value="page.headline" :rightOriented="page.rightOriented" :color="themeColor" :addTitle="addTitle"></headline>
        <subheadline :value="page.subheadline" :rightOriented="page.rightOriented" :color="themeColor"></subheadline>
        <mainText class="mainText" :value="page.text" :rightOriented="page.rightOriented" :fullWidth='page.template == "singleTextpage"'
          :fullHeight='page.template == "singleTextpage"' :result="result"></mainText>
        <infobox :page="page" :rightOriented="page.rightOriented" v-if="page.template == 'semSinglePage' || page.template == 'semDoublePage'"></infobox>
        <imgRightsBox :images="page.images" :rightOriented="page.rightOriented"></imgRightsBox>
        <pageNrBox :nr="page.pageNr" :rightOriented="page.rightOriented"></pageNrBox>

  </div>
</template>


<script>
import FlyerImageBox from './flyerImageBox.vue'
import HeadlineBox from './headlineBox.vue'
import SubheadlineBox from './subheadlineBox.vue'
import FlyerTextBox from './flyerTextBox.vue'
import InfoBox from './infoBox.vue'
import imgRightsBox from './imgRightsBox.vue'
import pageNrBox from './pageNrBox.vue'

export default {
  name: 'SemSinglePage',
  data () {
    return {
    }
  },
  props:["page", "themeColor", "result"],
  computed:{
    addTitle(){
        switch(this.page.flyerColor){
          case "jugend": return "Jugend";
          case "kinder": return "Kinder";
          default: return "";
        }
    }
  },
  components: {
    'flyerImageBox': FlyerImageBox,
    'headline': HeadlineBox,
    'subheadline': SubheadlineBox,
    'mainText': FlyerTextBox,
    "infobox": InfoBox,
    imgRightsBox,
    pageNrBox
  },
}
</script>

<style>

#wrapperBox{
    width: 154mm;
    height: 111mm;
    position: relative;
}


</style>
