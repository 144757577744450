<template>
  <b-row>
    <b-col cols="6">
      <b-form-group label="Von"><b-input type="number" placeholder="0" v-model="value.ageFrom" :disabled="disabled"/></b-form-group>
    </b-col>
    <b-col cols="6">
      <b-form-group label="Bis"><b-input type="number" placeholder="uralt" v-model="value.ageTo" :disabled="disabled"/></b-form-group>
    </b-col>
  </b-row>
</template>

<script>

export default {
  name: 'LocationFilter',
  components:{

  },
  props:["value", "disabled"],
  data(){
    return {
    }
  },
  methods:{


  },
  created(){
    if(typeof(this.value) != "object" || this.value == null || !this.value.hasOwnProperty("ageFrom") || !this.value.hasOwnProperty("ageTo")){
      this.$emit("input",JSON.parse('{"ageFrom":null, "ageTo": null}'));
    }
  },
  mounted(){
    var t = this;

  },

}

</script>

<style scoped>

</style>
