
<template>
    <div>
        <VueApexCharts width="500" type="pie" :options="pieChartOptions" :series="chartSeries"></VueApexCharts>
        <pieDetails :selectedResult="selectedResult" :semId="semId" />
    </div>
</template>


<script>
import VueApexCharts from 'vue-apexcharts'
import pieDetails from "./pieDetails.vue"

export default {
  name: 'AdvertisementStatisticsPie',
  data(){
    return {
        selectedResult: null,
    }
  },
  props:["stats","semId"],
  components:{
    VueApexCharts,
    pieDetails
  },
  methods:{

  },
  created(){
  },
  computed:{
    labels(){
        return this.stats.map(r => r.name == null ? "Keine Antwort" : r.name)
    },
    pieChartOptions(){
        var t = this;
        return {
            chart: {
            id: 'vuechart-example',
            events:{
                 dataPointSelection: function(event, chartContext, config) {
                    var label = t.labels[config.dataPointIndex];
                    t.$set(t, "selectedResult", label);
                 }
            }
            },
            labels: t.labels,
        };
    },
    chartSeries(){
        return this.stats.map(r => r.num * 1)
    }
  },
}

</script>
