<template>
  <div id="headlineBox" v-bind:class="{ right:  rightOriented, left: !rightOriented}" v-bind:style="{background: color}">
    <span>{{value}}</span>
    <div class="addTitle">{{addTitle}}</div>
  </div>
</template>


<script>
export default {
  name: 'FlyerHeadlineBox',
  data(){
    return {};
  },
  props: ['value', 'rightOriented', 'color', "addTitle"],
}

</script>

<style>

#headlineBox{
    position: absolute;
    top: 0;
    height: 16mm;
    width: calc(154mm - 45px - 45mm);
   /* background: #f39315;*/
    color: white;
    overflow-y: hidden;
    left: 45mm;
}



#headlineBox span{
    position: absolute;
    top: 12.1px;
    left: 16mm;
    font-weight: bold;
    font-size:46px;
}

#headlineBox.right{
    left: 45px;
    width: 105mm;
    clip-path: polygon(0% 0, 100% 00%, 96.5% 100%, 0 100%);
}

#headlineBox.right span{
    left: 4mm;
}

.addTitle {
  position: absolute;
  right: 5mm;
  top: 3mm;
  font-size:23px;
  font-family: "A little sunshine"
}



</style>

