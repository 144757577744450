<template>
  <b-col class="col-lg-8 col-xl-6 col-12 offset-lg-2">
    <h1>Umfrage bearbeiten</h1>
      <b-form-row class="my-2">
        <b-col cols="2">Name</b-col>
        <b-col cols="10"> <b-input v-model="poll.name" /></b-col>
      </b-form-row>
      
      <b-form-row class="my-2">
        <b-col cols="2">Einleitungstext</b-col>
        <b-col cols="10"> <b-textarea v-model="poll.description" rows="4"/></b-col>
      </b-form-row>
      
      <b-form-row class="my-2">
        <b-col cols="2">Name angeben?</b-col>
        <b-col cols="10"> <b-form-checkbox v-model="poll.nameRequired" rows="4">Teilnehmer sollen ihren Namen angeben</b-form-checkbox></b-col>
      </b-form-row>

      <b-form-row class="my-2">
        <b-col cols="2">Fragen</b-col>
        <b-col cols="10"> 
            <draggable v-model="poll.questions" class="list-group">
                <showQuestion v-for="question, index in poll.questions" v-bind:key="question.id" :question="question" :disabled="true" :edit="true" @delete="deleteQuestion(index)"/>
            </draggable>
            <small><b>Tipp:</b> Fragen können per Drag'n Dropp umsortiert werden</small><br>
            <b-button variant="success" class="my-2" @click="addQuestion()">Frage hinzufügen</b-button>
        </b-col>
      </b-form-row>
      
      <b-form-row class="my-2">
        <b-col cols="2">Teilnahme bis zum</b-col>
        <b-col cols="10"><datepicker v-model="poll.openUntil" /></b-col>
      </b-form-row>
      
      <b-form-row class="my-2" v-if="poll.key != null">
        <b-col cols="2">Link</b-col>
        <b-col cols="10">Gebe folgenden Link zur Teilnahme an der Umfrage weiter:<br><a :href="link">{{link}}</a></b-col>
      </b-form-row>
      
      <b-form-row class="my-2">
        <b-col cols="2"></b-col>
        <b-col cols="10">
            <saveBtn ref="saveBtn" :saveable="poll" api="poll/savePoll.php" @saved="setId"/>
            <router-link v-if="poll.key != null" :to="{ name: 'pollRes', params:{id:poll.id}}">
                <b-button variant="info" class="mx-1">Zu den Ergebnissen</b-button>
            </router-link>
        </b-col>
      </b-form-row>
  </b-col>
</template>

<script>

import { mapState } from 'vuex'
import saveBtn from '../saveBtn.vue'
import showQuestion from "./showQuestion.vue"
import draggable from 'vuedraggable'
import datepicker from '../datepicker.vue'


export default {

  name: 'editPoll',
  data(){
    return{
      poll: {
        name: "",
        questions:[],
        key: null,
      }
    }
  },
  components:{
    saveBtn,
    showQuestion,
    draggable,
    datepicker
  },
  created(){
    if(this.$route.params.id != null){
      this.loadPoll(this.$route.params.id);
    }
  },
  methods:{
    loadPoll(id){
      var t = this;

      if(id == null || id == "new"){
        t.poll = {id: "new", name: "", key:null, questions:[], nameRequired: false};
        if(t.$refs.saveBtn != null){
          t.$refs.saveBtn.reset();
        }
        return;
      }

      $.get(this.$root.api_root + "poll/getPoll.php",{
        id: id
      },function(res){
        t.$root.checkRespForLoginFail(res);
        t.poll = JSON.parse(res);
        if(t.$refs.saveBtn != null){
          t.$refs.saveBtn.reset();
        }
      });
    },
    setId(resp){

      resp *= 1;
      if(resp != this.poll.id){
        this.$router.push({ name: 'editPoll', params: { id: resp } })
      }

    },
    addQuestion(){
        var maxId = 0;
        this.poll.questions.forEach(q => {
            if(q.id > maxId){
                maxId = q.id;
            }
        });
    
        this.poll.questions.push({id: maxId+1, type: null, name: "", desc: ""});
    },
    deleteQuestion(index){
        if(!confirm("Willst du die Frage wirklich löschen?"))
            return;
        this.poll.questions.splice(index, 1);
    }
  },
  computed:{
    link(){
        return window.location.protocol + '//' + window.location.host + window.location.pathname + "#/polls/" + this.poll.id + "?key=" + this.poll.key;
    }
  },
  beforeRouteUpdate (to, from, next) {
    if(! this.$refs.saveBtn.confirmLeave()){
      next(false);
      return;
    }
    this.loadPoll(to.params.id);
    next();
  }
}
</script>


<style scoped>
 >>> #quill-container{
  height: auto;
}
>>> .ql-editor{
  height: auto;
}

>>> .quillWrapper{
  background: #fff;
}
</style>
