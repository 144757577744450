<template>
  <b-col cols="6" offset=2>
    <h1>Kinder- und Jugendgruppen</h1>
    <b-form-row class="my-2">
      <b-col cols=4><b-input name="searchKindergruppen" v-model="searchText" placeholder="Suchen"/></b-col>
      <b-col cols=2><b-form-select v-model="filterBezirk" :options="bezirke"></b-form-select></b-col>
      <b-col cols=2><b-button variant="success" v-if="editable" @click="add">Neue Gruppe</b-button></b-col>
    </b-form-row>
    <b-table :items="filtered" :fields="cols" striped>
      <template v-slot:cell(name)="data">
      <router-link :to="{name: 'editKindergruppe', params: {id: data.item.id}}">{{data.value}}</router-link>
      </template>
    </b-table>
  </b-col>
</template>

<script>

import { mapState } from 'vuex'

export default {

  name: 'Kindergruppen',
  data(){
    return{
      searchText:"",
      gruppen:[],
      cols:[
        {key:"name", sortable:true},
        {key: "bezirk", sortable:true}
      ],
      filterBezirk:null
    }
  },
  methods:{
    add(){
      this.$router.push({ name: 'editKindergruppe', params: { id: 'new' } })
    }
  },
  watch:{
  },
  created(){
    var t = this;
    this.$store.dispatch("loadUser");

    $.get(this.$root.api_root + "kindergruppen/getKindergruppen.php",{
      },function(res){
        t.$root.checkRespForLoginFail(res);
        t.gruppen = JSON.parse(res);
    });
  },
  computed:{
    filtered(){
      return this.gruppen.filter(g => {
        if(this.filterBezirk != null && this.filterBezirk != g.bezirk)
          return false;
        if(this.searchText != "" && g.name.toLowerCase().indexOf(this.searchText.toLowerCase()) == -1)
          return false;

        return true;
      });
    },
    bezirke(){
      var bezs = Array.from(new Set(this.gruppen.map(g => g.bezirk))).filter(x => x != null);
      return [
        {value: null, text: "Alle Bezirke"},
        ... bezs
      ];
    },
    ...mapState({
      editable: state => state.base.user.canEditKindergruppen,
    })
  },

}
</script>

<style scoped>
  table{
    background: #fff;
  }

</style>
