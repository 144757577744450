<template>
  <b-col class="col-lg-8 col-xl-6 col-12 offset-lg-2">
    <h1>Umfragen</h1>
    <b-table :items="polls" :fields="[
        {key: 'name'},
        {key: 'numReplys', label:'# Teilnahmen'},
    ]">
    <template v-slot:cell(name)="data">
         <router-link :to="{ name: 'editPoll', params:{id:data.item.id}}">
            <template v-if="data.item.isOpen == '1'"><b>{{data.value}}</b></template>
            <template v-else>{{data.value}}</template>
         </router-link>
    </template>
    </b-table>
    <router-link :to="{ name: 'editPoll', params:{id:'new'}}">
        <b-button variant="success" class="my-1">Neue Umfrage</b-button>
    </router-link>
    
  </b-col>
</template>

<script>

import { mapState } from 'vuex'


export default {

  name: 'polls',
  data(){
    return{
      polls: []
    }
  },
  components:{
  },
  created(){
    var t = this;
    $.get(this.$root.api_root + "poll/getPolls.php",{
        },function(res){
        t.$root.checkRespForLoginFail(res);
        t.polls = JSON.parse(res);
    });
  },
  methods:{
  
  },
  
}
</script>


<style scoped>
table{
    background: #fff;
}
 
</style>
