<template>
  <div class="row" style="margin-top: 10px">
    <div class='col-md-6' v-if="image.imid == null">
      <imageUpload v-on:uploaded="setImage" :imgName="imgName" :disabled="disabled"></imageUpload>
    </div>
    <template v-else v-bind:class="{'has-error': (image.authorFirst + image.authorLast == '')}">
          <div class='col-md-3'>
            <input class="form-control" type="text" v-model="image.authorFirst" placeholder="z.B. Max" :disabled="disabled" >
          </div>
          <div class='col-md-3' >
            <input class="form-control" type="text" v-model="image.authorLast" placeholder="z.B. Mustermann" :disabled="disabled" >
          </div>
          <div class='col-md-5'>
              <b-checkbox type="checkbox" class="form-check-input mirrorCb mx-2" inline v-model="image.mirror" :disabled="disabled">spiegeln</b-checkbox>
              <b-checkbox type="checkbox" class="form-check-input mx-2" inline v-model="image.canBeUsedOnFacebook" :disabled="disabled"><img src="../assets/Logo_Facebook.png" title="Bild darf auf Facebook verwendet werden" style="height: 1.2em;" /></b-checkbox>
              <a :href='$root.api_root + "flyerImages/flyerImage-" + image.imid + "-" + image.key + "." + image.fileType' target="_blank"><span class="oi oi-cloud-download mx-2"></span></a>
          </div>
          <div class='col-md-1'>
            <button type="button" v-on:click='delImage()' class="btn btn-danger delImgBtn oi oi-trash" :disabled="disabled"></button>
          </div>
    </template>
  </div>
</template>
<script>
import ImageUpload from './imageUpload.vue'
export default {
  name: 'ImageEditor',
  data(){
    return {
    };
  },
  props: {'image':{},
      'disabled':{},
     imgName: {
      default: 'Bild'
     }},
  components: {
    "imageUpload":ImageUpload
  },
  methods:{
    delImage(){
      this.image.imid = null
      this.$emit('deleted');
    },
    setImage(imid, key, fileType, authorF, authorL, canBeUsedOnFacebook){
      this.$set(this.image, "imid",imid);
      this.$set(this.image, "key",key);
      this.$set(this.image, "fileType",fileType);
      this.$set(this.image, "authorFirst",authorF);
      this.$set(this.image, "authorLast", authorL);
      this.$set(this.image, "posX",0);
      this.$set(this.image, "posY",0);
      this.$set(this.image, "mirror",false);
      this.$set(this.image, "scale",null);
      this.$set(this.image, "canBeUsedOnFacebook",canBeUsedOnFacebook);
    }
  }
}
</script>
