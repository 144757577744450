<template>
  <b-col cols="10">
    <b-table :items="gruppe.leiter" :fields="fields">
      <template v-slot:cell(name)="data">
        <template v-if="gruppe.editable > 1">
          <router-link :to="{name:'viewAktiven', params: { aid: data.item.aid }}">{{data.value}}</router-link>
        </template>
        <template v-else>
          {{data.value}}
        </template>
      </template>

      <template v-slot:cell(type)="data">
         <b-form-select v-model="data.item.type" :options="typeOptions" :disabled="gruppe.editable < 2"></b-form-select>
      </template>

      <template v-slot:cell(del)="data">
        <b-button variant="danger" @click="rmLeitung(data.item)" v-if="gruppe.editable > 1"><span class="oi oi-trash"></span></b-button>
      </template>

    </b-table>
    <searchfield :resetOnFound="true" @found="addLeitung" placeholder="Leitung hinzufügen" v-if="gruppe.editable > 1"/>
  </b-col>
</template>


<script>
import searchfield from '../seminare/search.vue'
import { mapState } from 'vuex'

export default {
  name: 'EditKindergruppenLeitung',
  props: ["gruppe"],
  data(){
    return {
      fields:[
        {key:"name", sortable:true},
        {key:"type", label:"Funktion"},
        {key:"del", label:"Löschen"}
      ],
      typeOptions:[
        { value: "leitung", text: 'Leitung' },
        { value: "betreuer", text: 'Co  Betreuer' },
        { value: "interessiert", text: 'Interresiert' },
      ]
    }
  },
  components:{
    searchfield
  },
  methods:{
    addLeitung(l){
      if(this.gruppe.leiter.find(e => e.aid == l.id) != null)
        return;
      this.gruppe.leiter.push({id:null, aid:l.id, name:l.name, type:"leitung"});
    },
    rmLeitung(item){
      var index = this.gruppe.leiter.findIndex(l => l.aid == item.aid);
      this.gruppe.leiter.splice(index,1);
    }
  },
  computed:{
  }
}

</script>

<style scoped>
table{
  background: #fff;
}

</style>
