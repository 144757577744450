<template>
<div>
  <div class="d-print-none">
    <h1>Etikettendruck</h1>
  </div>

  <div class="addItems d-print-none">
    <h4>Alle Dinge aus einem Ort hinzufügen</h4>
    <b-row class="my-2">
      <b-col cols="4">
        <select class="form-control" v-model="addRoom" @input="addPlace = ''">
          <option v-for="(room, roomId) in rooms" v-bind:value="roomId">
            {{ room }}
          </option>
        </select>
      </b-col>
      <b-col cols="4">
        <input ref="place" class="form-control" type="text" :disabled="addRoom == null" placeholder="Regalnr" v-model="addPlace"/>
      </b-col>
      <b-col cols="4">
        <b-button variant="success" :disabled="addRoom == null" @click="addByRoom"><span class="oi oi-plus"></span></b-button>
      </b-col>
    </b-row>
    <h4>Einzelne Sachen hinzufügen</h4>
    <itemPicker class="form-control my-2" v-model="addItem"  @input="addSingleItem" placeholder="Sache auswählen" :clearOnBlur="false"/>

  </div>


  <b-row v-for="(item,index) in items">
    <b-col cols="8" class="label">
      <h3>{{item.name}}</h3>
      {{roomName(item.room)}} {{item.place == null ? "" : item.place}}
      <template v-if="item.isAtomic"><br>Darf nicht zerpflückt werden</template>
      <span class="d-print-none"><br><b-button class="oi oi-trash" @click="rmItem(index)"></b-button></span>
    </b-col>
    <b-col cols="4">
      <qrcode :value="'aktiv.naju-bayern.de/najulytics/?page=vue#/stock/editItem/' + item.id" :options="{ width: 200 }"></qrcode>
    </b-col>
  </b-row>
</div>
</template>


<script>

import { mapState } from 'vuex'
import VueQrcode from '@chenfengyuan/vue-qrcode';
import itemPicker from "./itemPicker.vue"

export default {
  name: 'LabelsPrinter',
  data(){
    return {
      items:[],
      addRoom: null,
      addPlace: "",
      addItem:null
    };
  },
  props:[],
  components:{
    qrcode: VueQrcode,
    itemPicker
  },
  methods:{
    roomName(roomId){
      if(roomId == null)
        return "";
      if(this.rooms == null)
        return "";
      return this.rooms[roomId];
    },
    addByRoom(){
      var t = this;
      $.get(this.$root.api_root + "stock/getItemsForPrint.php",{
          room: this.addRoom,
          place: this.addPlace
        },function(data){
          t.$root.checkRespForLoginFail(data);
          //Array.prototype.push.apply(t.items,JSON.parse(data));
          t.$set(t,"items", t.items.concat(JSON.parse(data)));
      });
    },
    addSingleItem(item){
      if(item == null)
        return;
      this.addSingleItemById(item.id);
    },
    addSingleItemById(itemId){
      var t = this;
      $.get(this.$root.api_root + "stock/getItemsForPrint.php",{
          itemId: itemId
        },function(data){
          t.$root.checkRespForLoginFail(data);
          //Array.prototype.push.apply(t.items,JSON.parse(data));
          t.$set(t,"items", t.items.concat(JSON.parse(data)));
          t.$set(t,"addItem", null);
      });
    },
    rmItem(index){
      console.log(index);
      this.items.splice(index,1);
    }
  },

  created(){
    var t = this;
    this.$store.dispatch("loadRooms");
    if(this.$route.query.iid != null){
      this.addSingleItemById(this.$route.query.iid);
    }
  },
  mounted(){
    var t = this;
    $(this.$refs.place).autocomplete({
        minLength: 0,
        source: function( request, response ) {
            $.ajax( {
                url: t.$root.api_root + "stock/searchPlace.php",
                data: {
                    startingWith: request.term,
                    room: t.room
                },
                success: function( data ) {
                    t.$root.checkRespForLoginFail(data);
                    data = JSON.parse(data);
                    response( data );
                }
            } );
        },
        select:function(event,ui){
            console.log(ui.item.iid);
            t.addPlace=ui.item.label;
        }
    });
  },

   computed:{
    placeText(){
      if(this.item.containing != null)
        return "Gehört in " + this.item.containing.name;
      if(this.rooms == null || this.item.room == null)
        return this.place;
      return this.rooms[this.item.room] + " " + this.item.place;
    },

    ...mapState({
      rooms: state => state.stock.rooms
    })
  },

}

</script>

<style scoped>
.label h3{
 font-size: 1.6em;
}
.label{
  font-size: 1.2em;
  margin-bottom: 10px;
}

.addItems{
  max-width: 800px;
}

</style>
