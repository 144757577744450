<template>
  <div id="subheadline" v-bind:class="{ right:  rightOriented}"  v-bind:style="{color: color}">{{value}}</div>
</template>


<script>
export default {
  name: 'FlyerImageBox',
  data(){
    return {};
  },
  props: ['value', 'rightOriented', "color"],
}

</script>

<style>

#subheadline{
    position: absolute;
    top: 16mm;
    font-weight: bold;
    font-size: 23px;
    left: 70mm;
}

#subheadline.right{
    left: 25mm;
}



</style>

