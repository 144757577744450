<template>
  <div>
    <ul>
      <li v-for="owner, index in owners">{{owner.name}} <span class="oi oi-trash btn-link" @click="owners.splice(index,1)"></span></li>
    </ul>
    <b-dropdown text="Hinzufügen" class="m-md-2">
      <b-dropdown-item v-for="owner in freeUsers" :key="owner.id" @click="owners.push(owner)">{{owner.name}}</b-dropdown-item>
    </b-dropdown>
  </div>

</template>


<script>

export default {
  name: 'OwnerEditor',
  data(){
    return {
      avUsers:[],
    }
  },
  props:["owners"],
  created(){
    var t = this;
    $.get(this.$root.api_root + "getUsers.php",{},function(res){
        t.$root.checkRespForLoginFail(res);
        var res = JSON.parse(res);
        t.avUsers = res.map(u => {
          return {
            id: u.id,
            name: u.name
          }
        });
    });
  },

  computed:{
    ownerIds(){
      return this.owners.map(x => x.id);
    },
    freeUsers(){
      return this.avUsers.filter(x => this.ownerIds.indexOf(x.id) == -1);
    }
  },

}

</script>
