<template>
  <b-col class="col-lg-8 col-xl-6 col-12 offset-lg-2">
    <h1>Werbestatistiken</h1>
    <advStatistics />
  </b-col>
</template>


<script>

import advStatistics from "./advStatistics.vue"

export default {
  name: 'AdvertisementStatisticsPage',
  data(){
    return {
    }
  },
  components:{ 
    advStatistics
  },
}

</script>
