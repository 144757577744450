<template>
  <div class="uploadBox">
    <div id="progressBar"></div>
    <b-progress :value="progress" :max="100" animated v-if="progress < 100"/>
    <label class="btn btn-default btn-info" v-bind:class="{disabled: disabled || progress < 100}">
          Anhang hochladen <input ref="fileImput" type="file" name="attachment" style="display: none;" :disabled="disabled  || progress < 100" multiple>
    </label>
  </div>
</template>



<script>
export default {
  name: 'Upload',
  data(){
    return {
      progress: 100,
    };
  },
  props: {
    api: {type: String},
    disabled: {tpye: Boolean, default: false},
    pending: {type:Boolean, default: false}
  },
  methods:{
  },
  mounted(){
      var upload = null;
      var t = this;
      $(this.$refs.fileImput).change(function(){
        $(this).simpleUpload(t.$root.api_root + t.api, {
            allowedExts: ["jpg", "jpeg","pdf","zip","png"],
            allowedTypes: ["image/pjpeg", "image/jpeg","application/pdf","image/png","application/zip"],
            start: function(file){
              this.progress = 0;
            },

            init(){
              upload = this;
            },

            progress: function(progress){
              this.progress = progress;
              t.progress = upload.files.reduce( ( p, c ) => p + c.progress, 0 ) / upload.files.length;
              if(t.progress == 100 && t.pending)
                t.$emit("update:pending",false);
              else if(t.progress != 100 && !t.pending)
                t.$emit("update:pending",true);
            },

            success: function(res){
              //upload successful
              if(!res.success){
                  alert("Upload fehlgeschlagen");
                  return;
              }
              t.$emit("uploaded",{
                  id: res.attachementId,
                  file: this.upload.file
                });
            },

            error: function(error){
              //upload failed
              alert("Bild konnte nicht hochgeladen werden!<br>" + error.name + ": " + error.message);
            }
        });
      });
  },
  beforeDestroy(){
      $(this.$refs.fileImput).off("change");
  }
}

</script>
