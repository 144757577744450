<template>
    <div ref="flyerImage" class='flyerImage first'
      v-bind:style="{
        'background-image': 'url(' + url + ')',
        'background-position': cPosX + 'px ' + value.posY + 'px',
        'background-size': scale + '% '
      }"
      >
      </div>
</template>


<script>

function myXOR(a,b) {
  return ( a ? 1 : 0 ) ^ ( b ? 1 : 0 );
}


export default {
  name: 'DragableImage',
  data(){
    return {
      "boxWidth": 0
    };
  },
  components:{
  },
  props: ['value', "rightOriented","disabled", "url"],
  computed: {

    scale(){
      if(this.value.scale == null)
        return 100;
      return this.value.scale;
    },
    imgWidth() {
      return this.boxWidth * this.scale / 100.0;
    },
    cPosX(){
      if(this.rightOriented)
        return this.boxWidth - this.imgWidth - this.value.posX;
      return this.value.posX;
    }
  },
  updated(){
    if(this.value.scale == null){
        this.$set(this.value, "scale",100* $(this.$refs.flyerImage).height() / $(this.$refs.flyerImage).width());
      }
  },
  mounted(){
    this.boxWidth = $(this.$refs.flyerImage).width();
    var obj = this;

    $(this.$refs.flyerImage).on("mousedown",function(){
      if(obj.disabled)
        return;
      $(this).addClass("dragging");
    });


    $(this.$refs.flyerImage).on("mouseup",function(){
        if(obj.disabled)
            return;
        $(this).removeClass("dragging");
    });

    $(this.$refs.flyerImage).mouseout(function(){
        if(obj.disabled)
          return;
        $(this).removeClass("dragging");
    });

    $(this.$refs.flyerImage).on("mousemove",function(evt){
        if(obj.disabled)
          return;
        if(!$(this).hasClass("dragging"))
            return;


        var poss = $(this).css('backgroundPosition').split(" ");
        if(obj.rightOriented)
          obj.value.posX -= evt.originalEvent.movementX;
        else
          obj.value.posX += evt.originalEvent.movementX;
        obj.value.posY += evt.originalEvent.movementY;
    });

    $(this.$refs.flyerImage).bind("wheel", function(evt){
        if(obj.disabled)
            return;
        var delta = evt.originalEvent.deltaY;

        delta /= 10;

        if(evt.originalEvent.ctrlKey){
            obj.value.scale += delta;
        }
        else if(evt.originalEvent.shiftKey){
            if(obj.rightOriented)
              obj.value.posX -= delta;
            else
              obj.value.posX += delta;
        }
        else{
            obj.value.posY += delta;
        }
        return false;
    });
  }
}

</script>

<style>

.flyerImage{
    position: absolute;
    width: 100%;
    height: 50%;
    border-bottom: 1px solid #999999;
    background-size: 100%;
    background-position: 0px 0px;
    background-repeat: no-repeat;
}

.flyerImage.dragging{
    cursor: pointer;
}


</style>

