<template>
  <b-modal ref="modal" title="Seminare auf Website übertragen" size="lg" :okDisabled="!formValid || running" :ok-title="done ? 'Schließen' : 'Übertragen'"
    :cancel-title="canceled ? 'Wird abgebrochen' : 'Abbrechen'" @close="close" :no-close-on-esc="running" :no-close-on-backdrop="running" @cancel="cancel"
    @ok="run">
    <p class="my-2">Mit diesem Werkzeug kannst du die Seminare aus dem Flyer auf die naju-bayern.de Website übertragen.</p>
    <p class="my-1"><b>Folgende Seminare werden übertragen:</b></p>

    <b-table :items="sems" :fields="cols" striped>
    </b-table>
    <p class="my-2">Falls ein Seminar bereits auf der Website existiert, wird es <b>nicht</b> überschrieben. Wenn du es überschreiben willst, musst du es zuerst löschen.</p>

    <p class="my-2">
      Damit Najulytics auf die Website zugreifen kann, musst du hier einen Code eingeben. Du Findest ihn auf der Website unter Seminare / Import.<br>

          <b-form-group label="Code für naju-bayern.de:">
              <b-input v-model="wscode" placeholder="Code von Seminare / Import"/>
          </b-form-group>
    </p>
    <b-alert :show="done">
      <b>Fertig!</b> Die Übertragung wurde erfolgreich beendet!
    </b-alert>

    <b-alert :show="loginFailed" variant="danger">
      <b>Fehler!</b> Login auf der Website fehlgeschlagen. Bitte prüfe den Code!
    </b-alert>
  </b-modal>
</template>
<script>

export default {
  name: 'Page2Wordpress',
  data(){
    return {
      wscode:"",
      sems:[],
      cols:[
        {key: "title", label:"Titel"},
        {key: "state", label: "Status"}
      ],
      running:false,
      canceled:false,
      done: false,
      loginFailed:false
      };
  },
  props: [],
  components: {

  },
  methods:{
    show(sems){
      this.sems = sems.map(s => {return {
        id: s.id,
        title: s.title,
        state:"ausstehend"
      }});
      this.done = false;
      this.running = false;
      this.canceled = false;
      this.$refs.modal.show();
    },
    close(bvModalEvt){
      if(this.running)
        bvModalEvt.preventDefault();
    },
    cancel(bvModalEvt){
      if(this.running){
        bvModalEvt.preventDefault();
        this.canceled = true;
      }
    },
    run(bvModalEvt){
      if(this.done)
        return;
      bvModalEvt.preventDefault();
      this.running = true;
      this.runNext();
    },
    runNext(){
      var t = this;
      var pending = this.sems.findIndex(s => s.state == "ausstehend");
      if(pending == -1){
        this.done = true;
        this.running = false;
        return;
      }
      if(this.canceled){
        this.running == false;
        this.$refs.modal.hide();
        return;
      }
      t.sems[pending].state = "wird übertragen ...";
      $.post(this.$root.api_root + "flyer/page2wordpress.php",{
        code:t.wscode,
        pid: t.sems[pending].id
      },function(res){
        t.$root.checkRespForLoginFail(res);
        if(res.indexOf("exists") != -1){
          t.sems[pending].state = "Existiert bereits";
          t.loginFailed = false;
        }else if(res.indexOf("login failed") != -1){
          t.sems[pending].state = "ausstehend";
          t.running = false;
          t.loginFailed = true;
          return;
        }else{
          t.sems[pending].state = "fertig";
          t.loginFailed = false;
        }
          t.runNext();
      });
    }
  },
  computed:{
    formValid(){
      if(this.wscode == "")
        return false;
      return  true;
    }
  }
}
</script>
