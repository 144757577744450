<template>
  <b-col class="col-lg-8 col-xl-6 col-12 offset-lg-2">
    <h1>Fördertopf-Jahr bearbeiten</h1>
      <b-form-row class="my-2">
        <b-col cols="2">Fördertopf</b-col>
        <b-col cols="10"><router-link :to="{ name: 'editFeederbowel', params: { id: bowlY.feederbowl.id }}">{{bowlY.feederbowl.name}}</router-link></b-col>
      </b-form-row>
      <b-form-row class="my-2">
        <b-col cols="2">Name</b-col>
        <b-col cols="10"> <b-input v-model="bowlY.name" /></b-col>
      </b-form-row>
      <b-form-row class="my-2">
        <b-col cols="2">Zeitraum</b-col>
        <b-col cols="5"> <datepicker v-model="bowlY.start"/></b-col>
        <b-col cols="5"> <datepicker v-model="bowlY.end"/></b-col>
      </b-form-row>
      <b-form-row class="my-2">
        <b-col cols="2">Geld</b-col>
        <b-col cols="5">
          <b-input-group prepend="Fördersumme" append="€" class="" label="asdf">
            <b-input v-model="bowlY.amount" type="number" step=".01"/>
          </b-input-group>
        </b-col>
        <b-col cols="5">
          <b-input-group prepend="Förderanteil" append="%" class="" label="asdf">
            <b-form-input v-model="bowlY.percentage" type="number" min="0.00" max="100"/>
          </b-input-group>
        </b-col>
      </b-form-row>
      <b-form-row class="my-2">
        <b-col cols="2">Infos</b-col>
        <b-col cols="10"> <vue-editor v-model="bowlY.description" :editorToolbar="[
            [{ 'header': 1 }, { 'header': 2 }],
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['link']
          ]"></vue-editor></b-col>
      </b-form-row>
      <b-form-row class="my-2">
        <b-col cols="2">Unterfördertöpfe</b-col>
        <b-col cols="10">
          <ul>
            <li v-for="subfeederbowl in bowlY.subfeederbowls">{{subfeederbowl.name}}</li>
          </ul>
          <b-button>Neuer Unterfördertopf</b-button>
        </b-col>
      </b-form-row>
      <b-form-row class="my-2">
        <b-col cols="2"></b-col>
        <b-col cols="10"><saveBtn ref="saveBtn" :saveable="bowlY" api="fundmonitor/saveFeederbowlYear.php" @saved="setId"/></b-col>
      </b-form-row>
  </b-col>
</template>

<script>

import { mapState } from 'vuex'
import saveBtn from '../saveBtn.vue'
import { VueEditor } from 'vue2-editor'
import datepicker from "../datepicker.vue"


export default {

  name: 'editFeederbowelYear',
  data(){
    return{
      bowlY: {
        name: "",
        feederbowl:{id:null,name:""}
      }
    }
  },
  components:{
    saveBtn,
    VueEditor,
    datepicker
  },
  created(){
    this.loadBowl(this.$route.params.id, this.$route.query);
  },
  methods:{
    loadBowl(id, query){
      var t = this;

      console.log(id);
      if(id == null){
        t.bowlY = {id: null, name: "", description: "", feederbowl:{id: query.feederbowlId, name: query.feederbowlName}, amount: 0, percentage: 80};
        if(t.$refs.saveBtn != null){
          t.$refs.saveBtn.reset();
        }
        return;
      }

      $.get(this.$root.api_root + "fundmonitor/getFeederbowlYear.php",{
        id: id
      },function(res){
        t.$root.checkRespForLoginFail(res);
        t.bowlY = JSON.parse(res);
        t.$refs.saveBtn.reset();
      });
    },
    setId(resp){
      var respD = JSON.parse(resp);
      if(respD == null || !respD.success){
        alert(resp);
        return;
      }
      if(respD.id != this.bowlY.id){
        this.$router.push({ name: 'editFeederbowelYear', params: { id: respD.id } })
      }

    }
  },
  beforeRouteUpdate (to, from, next) {
    if(! this.$refs.saveBtn.confirmLeave()){
      next(false);
      return;
    }
    this.loadBowl(to.params.id, to.query);
    next();
  }
}
</script>


<style scoped>
 >>> #quill-container{
  height: auto;
}
>>> .ql-editor{
  height: auto;
}

>>> .quillWrapper{
  background: #fff;
}
</style>
