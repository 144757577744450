<template>
  <b-col cols="6" offset=2>
    <h1>Funktion bearbeiten</a></h1>
    <b-form-row class="my-2">
      <b-col cols="2">Name</b-col>
      <b-col cols="10"> <b-input v-model="funktion.name" /></b-col>
    </b-form-row>
    <b-form-row class="my-2">
      <b-col cols="2">Ein- & Austragen</b-col>
      <b-col cols="10">
        <b-form-checkbox v-model="funktion.membersCanSubscribe">Nutzer können sich eintragen (Wird automatisch auf naju-bayern.de unter Newsletter angeboten)</b-form-checkbox><br>
        <b-form-checkbox v-model="funktion.membersCanUnsubscribe">Nutzer können sich austragen (Link wird jeder E-Mail angefügt)</b-form-checkbox>
      </b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Führungszeugnisse</b-col>
      <b-col cols="10">
      <b-form-checkbox v-model="funktion.membersNeedCriminalRecordCertificate">Mitglieder dieser Funktion brauchen ein Führungszeugniss</b-form-checkbox>
      </b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Betreuer</b-col>
      <b-col cols="10">
      Betreuer können die Funktion ansehen und E-Mails an ihre Mitglieder schicken, ohne Rechte für Seminaranmeldungen zu haben. Außerdem erhalten sie eine E-Mail wenn sich Aktive eintragen.
      <Owners :owners="funktion.owners" />
      </b-col>
    </b-form-row>

    <saveBtn ref="saveBtn" :saveable="funktion" api="seminare/saveFunktion.php" @saved="setId"/>
    <b-button variant="danger" @click="del" class="mx-1">Funktion Löschen</b-button>
  </b-col>

</template>


<script>
import saveBtn from '../saveBtn.vue'
import Owners from './ownerEditor.vue'

export default {
  name: 'ViewFunktion',
  data(){
    return {
      funktion:{
        name:"",
        member: [],
        membersCanSubscribe: false,
        membersCanUnsubscribe: true,
        membersNeedCriminalRecordCertificate: false,
        owners: []
      },
    }
  },
  components:{
    saveBtn,
    Owners
  },
  methods:{
    loadFunktion(id){
      var t = this;
      if(id=="new"){
        t.funktion = {
          id:"new",
          name:"",
          member: [],
          membersCanSubscribe:false,
          membersCanUnsubscribe: true,
          membersNeedCriminalRecordCertificate: false,
          owners:[]
        };
        if(this.$refs.saveBtn != null)
          this.$refs.saveBtn.reset();
        return;
      }
      $.get(this.$root.api_root + "seminare/getFunktion.php",{
        gid: id
      },function(res){
        t.$root.checkRespForLoginFail(res);
        if(res.trim().indexOf("not found") == 0){
          alert("Eintrag nicht gefunden");
          t.funktion = {};
          return;
        }
        t.funktion = JSON.parse(res);
        t.$refs.saveBtn.reset();
      });
    },
    setId(id){
      if(id.indexOf("exists") != -1){
        alert("Es gibt bereits eine Funktion mit diesem Namen!");
        return;
      }
      this.funktion.id = id *1;
      this.$refs.saveBtn.reset();
      this.$store.dispatch("loadUser",true);
      this.$store.dispatch('loadFunktionen',true);
    },
    del(){
      var t = this;
      if(!confirm("Willst du diese Funktion wirklich löschen?") || !confirm("Wirklich wirklich?"))
        return;
      $.get(this.$root.api_root + "seminare/deleteFunktion.php",{
        id: this.funktion.id
      },function(res){
        t.$root.checkRespForLoginFail(res);
        t.$store.dispatch('loadFunktionen',true);
        t.$router.push("/");
      });
    }
  },
  created(){
    this.loadFunktion(this.$route.params.fid);
  },


  beforeRouteUpdate (to, from, next) {
    if(! this.$refs.saveBtn.confirmLeave()){
      next(false);
      return;
    }
    this.loadFunktion(to.params.fid);
    next();
  }
}

</script>

<style scoped>


</style>
