<template>
  <b-col cols="6" offset=2>
    <h1>Emails</h1>
    <router-link :to="{ name: 'email'}"><b-button variant="success" class="my-2">Neu</b-button></router-link>
    <b-table :items="emails" :fields="[
      {key:'subject', label:'Betreff', sortable:'true'},
      {key:'numRec', label:'Anzahl Empfänger', sortable:'true'},
      {key:'sendState', label:'Versand'}
    ]">
      <template v-slot:cell(subject)="data">
        <router-link :to="{ name: 'email', params: { id: data.item.id }}">
          <span v-bind:class="{draft: data.item.state == 0}">
            <template v-if="data.item.state == 0">Entwurf: </template>
            {{data.value}}
          </span>
        </router-link>
      </template>
      <template v-slot:cell(sendState)="data">
        <template v-if="data.item.state == 1">{{Math.round(data.item.numSentRec / data.item.numRec*100)}}%</template>
      </template>

    </b-table>
  </b-col>
</template>

<script>


export default {
  name: 'Emails',
  data(){
    return {
      emails: []
    };
  },
  components:{

  },
  created(){
    var t = this;
    $.get(this.$root.api_root + "seminare/getEmails.php",{},function(res){
      t.$root.checkRespForLoginFail(res);
       t.emails = JSON.parse(res);

    });
  },
  computed:{

  },
  methods:{

  },

}

</script>


<style scoped>
.draft{
  color: #666;
}

table{
  background: #fff;
}

 </style>
