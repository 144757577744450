<template>
  <div id="wrapperBox" v-bind:style="{background: themeColor}">
    <img svg-inline v-if="this.page.template == 'lastPage'" src="./rueckseite_alt.svg" />
    <img svg-inline v-if="this.page.template == 'lastPage-new-generic'" src="./rueckseite_neu_generic.svg" />
    <img svg-inline v-if="this.page.template == 'lastPage-new-sem'" src="./rueckseite_neu_sem.svg" />
    <img svg-inline v-if="this.page.template == 'lastPage-new-multi'" src="./rueckseite_neu_multi.svg" />
    <div id="textBox" v-html="text"></div>
    <div id='imgRightsBox'>
      Titelbild: {{imgAuthor}}<br>
      Druck: {{page.printer}}
    </div>
  </div>
</template>


<script>
export default {
  name: 'SemLastPage',
  data () {
    return {
      titlepage:{

      }
    }
  },
  created(){
    var parent = this;
    $.get(this.$root.api_root + "flyer/get_page.php",{
        pid: "titlepage",
        for: this.page.id
      },function(data){
        parent.$root.checkRespForLoginFail(data);
        parent.titlepage = JSON.parse(data);
      });
  },
  props:["page", "themeColor", "result"],
  computed:{
    addTitle(){
        switch(this.page.flyerColor){
          case "jugend": return "Jugend";
          case "kinder": return "Kinder";
          default: return "";
        }
    },
    imgAuthor(){
    if(!this.titlepage.hasOwnProperty("images") || this.titlepage.images.image1.imid == null)
        return "";
      var ret = "";
    if(this.titlepage.images.image1.authorFirst != "")
        ret += this.titlepage.images.image1.authorFirst.substr(0,1) + ". ";

      return ret + this.titlepage.images.image1.authorLast;
    },
    text(){
      if(this.page.template == "lastPage"){
        if(this.page.text != null && this.page.text.trim().length > 0)
            return this.page.text;
        return "Teilnahmebedingungen und weitere Informationen findest du auf unserer Internetseite:<br><b>naju-bayern.de</b>";
      }
      else{ //new backgrounds do not accept text
        return "";
      }
    },
    svgBackground() {
        switch(this.page.template){
            case 'lastPage': return "./rueckseite_alt.svg";
            case 'lastPage-new-generic': return "./rueckseite_neu_generic.svg";
            case 'lastPage-new-sem': return "./rueckseite_neu_sem.svg";
            case 'lastPage-new-multi': return "./rueckseite_neu_multi.svg";
        }
        return "./rueckseite_alt.svg";
    }
  },
  components: {},
}
</script>

<style scoped>

#textBox{
  position: absolute;
  left: 50px;
  top: 55mm;
  width:480px;
}

#wrapperBox{
  width: 154mm;
  height: 111mm;
  position: relative;
}

#imgRightsBox{
position: absolute;
  bottom: 24px;
  right: 55px;
  text-align: right;
}

</style>
