<template>
  <b-modal ref="imageSelector" :title="imageName + ' auswählen'" size="lg" ok-disabled @close="abort" @cancel="abort">
    Bitte wähle ein Bild aus indem du drauf klickst:
    <b-row>
      <b-col class="col-lg-6 col-12" v-for="image in avImages">
        <img class="selectableImage" @click="select(image)" :src="$root.api_root + 'flyerImages/flyerImage-' + image.imid + '-' + image.key + '.jpg'" width="100%" />
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import fillCodeEditor from "./fillFerienboerseFillCodeEditor.vue"

export default {
  name: 'ImageSelector',
  data(){
    return {
      accept: null,
      reject: null,
      imageName: "",
      forFb: false,
      exclude: []
    };
  },
  props:["page"],
  components: {

  },
  methods:{
    selectImage(imageName, forFb = false, exclude = []){
      var t = this;
      return new Promise((accept, reject) => {
        t.accept = accept;
        t.reject = reject;
        t.imageName = imageName
        t.$refs.imageSelector.show();
        t.forFb = forFb;
        t.$set(t,"exclude", exclude);
      });
    },
    select(image){
      this.$refs.imageSelector.hide();
      this.accept(image);
    },
    abort(){
      this.reject();
    }

  },
  computed:{
    avImages(){
      if(this.page == null || this.page.images == null) return [];
      return [
        this.page.images.image1,
        this.page.images.image2,
        this.page.images.rightImage,
        ...this.page.images.moreImages
      ].filter(x => {
        if(x == null || x.imid == null) return false;
        if(this.forFb && x.canBeUsedOnFacebook != true)  return false;
        if(this.exclude.includes(x))  return false;
        return true;
      });
    }
  }
}
</script>


<style scoped>
  .selectableImage{
    cursor: pointer;
  }
</style>
