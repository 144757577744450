<template>
  <b-table id="itemTable" :items="myItems" :fields="cols" striped selectable selectedVariant="selected" select-mode="single">
    <template v-slot:cell(num)="data">
      <b-input v-if="data.rowSelected" v-model="data.item.num" type="number" :state="data.item.num <= data.item.avNum || data.item.avNum == -1" class="numberInput d-print-none" :disabled="disabled"/>
      <span v-else class="d-print-none">{{data.value}}</span>
      <span class="d-none d-print-block">{{data.value}}</span>
      <b-form-invalid-feedback :state="data.item.num <= data.item.avNum || data.item.avNum == -1">
        Nur {{data.item.avNum}} {{data.item.name}} vorhanden
      </b-form-invalid-feedback>
      <ListItemSaver :listItem="data.item"/><!--can be instanced in one of the slots-->
    </template>

    <template v-slot:cell(name)="data">
      <router-link v-if="data.item.iid != ''" :to="{ name: 'editItem', params: { iid: data.item.iid }}">{{data.value}}</router-link>
      <i v-else>{{data.value}}</i>
      <template v-if="data.item.isDeleted"> (gelöscht)</template>
    </template>

    <template v-slot:cell(comment)="data">
      <b-input v-if="data.rowSelected" name="comment" v-model="data.item.comment" class="d-print-none" :disabled="disabled"/>
      <span v-else class="d-print-none">{{data.value}}</span>
      <span class="d-none d-print-block">{{data.value}}</span>
    </template>
    <template v-slot:cell(del)="data">
      <button v-if="data.rowSelected" class="btn btn-danger" @click="deleteRow(data.item.id)" :disabled="disabled"><span class="oi oi-trash"></span></button>
    </template>
  </b-table>
</template>


<script>

import ListItemSaver from "./editList-ListItemSaver.vue"

export default {
  name: 'EditListTable',
  props: ["items","disabled"],
  data(){
    return {
      cols:[
        {key:"num", label:"Anzahl", sortable:true, class:"numCell"},
        {key:"name", label: "Sache",sortable:true},
        {key: "comment", label: "Kommentar"},
        {key: "place", label: "Ort", sortable:true},
        {key: "del", label:"Löschen", class: "d-print-none"}
      ]
    };
  },
  components:{
    ListItemSaver
  },

  methods:{
    deleteRow(id){
      var t = this;
      $.get(this.$root.api_root + "stock/deleteListItem.php",{
        liid: id
      },function(data){
        t.$root.checkRespForLoginFail(data);
        var index = t.items.findIndex(x => x.id == id);
        console.log(index);
        t.items.splice(index,1);
      });
    }
  },
  computed:{
    myItems(){
      return this.items.map(x => {
        x._rowVariant = x.isDeleted ? "danger" : ""
        return x;
      });
    }
  }
}

</script>

<style scoped>
.numberInput{
  width:100px;
}

table{
  background: #fff;
}

#itemTable >>> td{
  padding: 0.4rem;
}

#itemTable >>>.numCell{
  padding-left:15px;
}

</style>

