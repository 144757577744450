<template>
  <b-col class="col-lg-8 col-xl-6 col-12 offset-lg-2">
    <h1>Fördertopf bearbeiten</h1>
      <b-form-row class="my-2">
        <b-col cols="2">Name</b-col>
        <b-col cols="10"> <b-input v-model="bowl.name" /></b-col>
      </b-form-row>

      <b-form-row class="my-2">
        <b-col cols="2">Infos</b-col>
        <b-col cols="10"> <vue-editor v-model="bowl.description" :editorToolbar="[
            [{ 'header': 1 }, { 'header': 2 }],
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['link']
          ]"></vue-editor></b-col>
      </b-form-row>
      <b-form-row class="my-2">
        <b-col cols="2">Jahre</b-col>
        <b-col cols="10">
          <ul>
            <li v-for="year in bowl.years"><router-link :to="{ name: 'editFeederbowelYear', params: { id: year.id }}">{{year.name}}</router-link></li>
          </ul>
          <router-link :to="{ name: 'editFeederbowelYear', params: { id: null }, query: {feederbowlId: bowl.id, feederbowlName: bowl.name}}"><b-button>Neues Jahr</b-button></router-link>
        </b-col>
      </b-form-row>
      <b-form-row class="my-2">
        <b-col cols="2"></b-col>
        <b-col cols="10"><saveBtn ref="saveBtn" :saveable="bowl" api="fundmonitor/saveFeederbowl.php" @saved="setId"/></b-col>
      </b-form-row>
  </b-col>
</template>

<script>

import { mapState } from 'vuex'
import saveBtn from '../saveBtn.vue'
import { VueEditor } from 'vue2-editor'


export default {

  name: 'editFeederbowel',
  data(){
    return{
      bowl: {
        name: "",
      }
    }
  },
  components:{
    saveBtn,
    VueEditor
  },
  created(){
    if(this.$route.params.id != null){
      this.loadBowl(this.$route.params.id);
    }
  },
  methods:{
    loadBowl(id){
      var t = this;

      if(id == null || id == "new"){
        t.bowl = {id: null, name: "", description: ""};
        if(t.$refs.saveBtn != null){
          t.$refs.saveBtn.reset();
        }
        return;
      }

      $.get(this.$root.api_root + "fundmonitor/getFeederbowl.php",{
        id: id
      },function(res){
        t.$root.checkRespForLoginFail(res);
        t.bowl = JSON.parse(res);
        if(t.$refs.saveBtn != null){
          t.$refs.saveBtn.reset();
        }
      });
    },
    setId(resp){
      var respD = JSON.parse(resp);
      if(respD == null || !respD.success){
        alert(resp);
        return;
      }
      this.$store.dispatch('loadFeederbowls',true);

      if(respD.id != this.bowl.id){
        this.$router.push({ name: 'editFeederbowel', params: { id: respD.id } })
      }

    }
  },
  beforeRouteUpdate (to, from, next) {
    if(! this.$refs.saveBtn.confirmLeave()){
      next(false);
      return;
    }
    this.loadBowl(to.params.id);
    next();
  }
}
</script>


<style scoped>
 >>> #quill-container{
  height: auto;
}
>>> .ql-editor{
  height: auto;
}

>>> .quillWrapper{
  background: #fff;
}
</style>
