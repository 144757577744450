import { render, staticRenderFns } from "./flyerEditor.vue?vue&type=template&id=cdbc6eae&scoped=true&"
import script from "./flyerEditor.vue?vue&type=script&lang=js&"
export * from "./flyerEditor.vue?vue&type=script&lang=js&"
import style0 from "./flyerEditor.vue?vue&type=style&index=0&id=cdbc6eae&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cdbc6eae",
  null
  
)

export default component.exports