<template>
  <b-col cols="6" offset=2>
    <h1>Email schreiben</h1>
    <RecipiantsEditor :recipiants="recipiants" :disabled="sendPending || sendDone" :noGebdat.sync="noGebdat"/>
    <b-form-row class="my-2">
      <b-col cols="2">Antworten an</b-col>
      <b-col cols="10"><b-input  v-model="replyTo" autocomplete="off" :disabled="sendPending || sendDone"/></b-col>
    </b-form-row>
    <b-form-row class="my-2">
      <b-col cols="2">Betreff</b-col>
      <b-col cols="10"><b-input placeholder="z.B. Nächstes Treffen" v-model="subject" autocomplete="off" :disabled="sendPending || sendDone"/></b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Text</b-col>
      <b-col cols="10">
        <!--<b-textarea rows="13" v-model="text" :disabled="sendPending || sendDone"/>-->
        <b-alert :show="imagePending">Bild wird hochgeladen ...</b-alert>
        <vue-editor v-model="text" :editorToolbar="editorToolbar" :disabled="sendPending || sendDone || imagePending"
          useCustomImageHandler   @imageAdded="handleImageAdded"></vue-editor>
          <small>$NAME wird durch den jeweiligen Vornamen ersetzt</small>
        <p v-if="encryptAttachments">{{attEncText}}</p>
        <p v-if="showRecipiantText" v-html="recipiantText"></p>
      </b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Anhänge</b-col>
      <b-col cols="10">
        <attachments :attachments="attachments" :isValid.sync="attachmentsValid" :disabled="sendPending || sendDone"/>
        <b-checkbox v-model="encryptAttachments" :disabled="sendPending || sendDone">Anhänge verschlüsseln</b-checkbox>

        <b-alert variant="danger" :show="cantEncrypt">
            <b>Verschlüsseln nicht möglich!</b> Zu folgenden Empfängern ist kein Geburtsdatum hinterlegt:
            <ul>
              <li v-for="a in noGebdat">
                <router-link :to="{ name: 'viewAktiven', params: { aid: a.id }}">{{a.vorname}} {{a.nachname}}</router-link>
              </li>
            </ul>
        </b-alert>
      </b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="10" offset="2">
        <b-button variant="info" :pressed.sync="showRecipiantText" :disabled="sendPending || sendDone">Diese E-Mail ging an... einfügen</b-button>
        <b-button variant="success" :disabled="sendDissabled" @click="send(true)" class="mx-1">{{sendBtnText}}</b-button>
        <b-button variant="secondary" @click="send(false)" v-if="!(sendPending || sendDone) && user.canEditSeminare" class="mx-1">{{draftBtnText}}</b-button>
      </b-col>
    </b-form-row>
  </b-col>
</template>

<script>
import RecipiantsEditor from "./emailRecipiants.vue"
import attachments from "./emailAttachments.vue"


import { VueEditor } from 'vue2-editor'
import { mapState } from 'vuex'

export default {
  name: 'Email',
  data(){
    var ret = {
      id: null,
      recipiants: this.$store.state.seminare.emailRecipiants.slice(),
      subject: "",
      text: "Hallo $NAME,\n",
      replyTo: "naju-bayern@lbv.de",
      sendPending:false,
      savePending:false,
      sendDone: false,
      saveDone: false,
      showRecipiantText:false,
      attachments: [],
      attachmentsValid: true,
      encryptAttachments: false,
      noGebdat: [],
      attEncText: "Die Anhänge dieser Mail wurden aus Datenschutzgründen verschlüsselt. Das Passwort ist dein Geburtsdatum. Also z.B. 01.01.2000",
      editorToolbar:[
            [{ 'header': 1 }, { 'header': 2 }],
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['image', "link"]
          ],
    images:{},
    imagePending: false
    }
    this.$store.commit("setEmailRecipiants",[]);
    return ret;
  },
  components:{
    RecipiantsEditor,
    attachments,
    VueEditor
  },
  created(){
    var t = this;
    this.$store.dispatch("loadUser");
    if(this.$route.params.id != null){
      $.get(this.$root.api_root + "seminare/getEmail.php",{
        id: this.$route.params.id
      }, function(res){
          t.$root.checkRespForLoginFail(res);
          res = JSON.parse(res);
          t.id = res.id;
          t.recipiants = res.recipiants;
          t.subject = res.subject;
          t.text = res.text;
          t.sendPending = false;
          if(res.replyTo != ""){
            t.replyTo = res.replyTo;
          }
          t.sendDone = res.state == 1;
          t.encryptAttachments = res.encryptAttachments,
          t.showRecipiantText = res.showRecipiantText,
          t.attachments = res.attachments
      });

    }
  },
  computed:{
    sendDissabled(){
      if(this.recipiants.length == 0)
        return true;
      if(this.subject.length == 0)
        return true;
      if(this.text.length == 0)
        return true;
      if(this.sendPending || this.sendDone || this.imagePending || this.savePending)
        return true;
      if(!this.attachmentsValid || this.cantEncrypt)
        return true;
      return false;
    },
    recipiantText(){
      return "Diese E-Mail wurde verschickt an:<br>" + this.recipiants.map(r => {
        var split = r.name.trim().split(" ");
        /*if(split.length > 1)
          return this.$options.filters.capitalize(split[0]) + " " + split[1][0].toUpperCase() + ".";*/
        return this.$options.filters.capitalize(split[0]);

      }).join(", ");
    },
    sendBtnText(){
      if(this.sendPending)
        return "Wird Gesendet";
      if(this.sendDone)
        return "Gesendet";
      return "Senden";
    },
    draftBtnText(){
      if(this.savePending)
        return "Wird Gespeichert";
      if(this.saveDone)
        return "Gespeichert";
      return "Entwurf speichern";
    },
    cantEncrypt(){
      return this.encryptAttachments && this.noGebdat.length > 0;
    },
    ...mapState({
      user: state => state.base.user,
    })
  },
  methods:{
    send(send){
      var t = this;
      t.sendDone = false;
      t.saveDone = false;
      if(send){
        t.sendPending = true;
      }
      else{
        t.savePending = true;
      }

      $.post(this.$root.api_root + "seminare/get_sendEmail.php",{
        email:JSON.stringify({
          id: this.id,
          recipiants: this.recipiants.map(e => {return {id: e.id}}),
          subject: this.subject,
          text: this.text,
          attachements: [... this.attachments.map(e => e.id)],
          encryptAttachments: this.encryptAttachments,
          replyTo: this.replyTo,
          send: send,
          showRecipiantText: this.showRecipiantText
        })
      },function(res){
        t.$root.checkRespForLoginFail(res);
        if(res.indexOf("success") !== -1){
          t.sendPending = false;
          t.savePending = false;
          if(send){
            t.sendDone = true;
          }else{
            t.saveDone = true;
          }
        }
        else{
          alert("Fehler: " + res);
        }
      });
    },
    handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
      var t = this;
      t.imagePending = true;
      this.compressImage(file,(compressedFile, compressedImage)=>{
        t.uploadImage(compressedFile, file.name, function(attachementId, attachementKey){
          t.images[compressedImage] = attachementId;
          //Editor.insertEmbed(cursorLocation, 'image', compressedImage);
          Editor.insertEmbed(cursorLocation, 'image', t.$root.api_root + "seminare/getEmailImage.php?id=" + attachementId + "&key=" + attachementKey);

          t.imagePending = false;
          resetUploader();
        });
      });

        //Editor.insertEmbed(cursorLocation, 'image', url);
    },

    uploadImage(img, filename, cb){
      var t = this;
      var formData = new FormData();
      formData.append('attachment', img, filename);
      formData.append('inlineImage',true);
      var t = this;
      $.ajax({
        url: this.$root.api_root + "seminare/uploadAttachment.php",
        data: formData,
        success: function(resp){
          t.$root.checkRespForLoginFail(resp);

          resp = JSON.parse(resp);
          if(!resp.success){
            alert(resp.error);
            return;
          }

          console.log("done");
          cb(resp.attachementId, resp.attachementKey);

        },
        type: 'post',
        dataType: 'text', // what to expect back from the server
        cache: false,
        contentType: false,
        processData: false
      });
    },

    compressImage(file, cb){
      var fr=new FileReader();
      fr.onload = function(e) {
        const img = new Image();
        img.onload = () => {

            const width = 400;
            const scaleFactor = width / img.width;
            const height = img.height * scaleFactor;
            const elem = document.createElement('canvas');
            elem.width = width;
            elem.height = height;
            const ctx = elem.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);
            //var compressedImage = ctx.canvas.toDataURL("image/jpeg", 0.8);
            ctx.canvas.toBlob(blob => {
               cb(blob,ctx.canvas.toDataURL("image/jpeg", 0.8));
            },"image/jpeg", 0.8);
            //cb(compressedImage);
        };

        img.src = this.result;
      };
      fr.readAsDataURL(file);
    },

  },
  beforeRouteLeave (to, from, next) {
    if((this.recipiants.length != 0 || this.text != "") && !this.sendDone && !confirm("Wirklich gehen? (Tipp: öffne links mit Strg im neuen Tab)")){
      next(false);
      return;
    }
    next();
  },

}

</script>


<style scoped>
 >>> #quill-container{
  height: auto;
}
>>> .ql-editor{
  height: auto;
}

>>> .quillWrapper{
  background: #fff;
}
</style>
