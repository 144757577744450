<template>
  <div>
   <draggable v-model="question.choices" class="list-group">
        <b-row v-for="choice, index in question.choices" v-bind:key="choice.id" class="my-1">
            <b-col cols="10"><b-input v-model="choice.text"/></b-col>
            <b-col cols="1"><b-button variant="danger" class="oi oi-trash" @click="question.choices.splice(index,1)"/></b-col>
        </b-row>
    </draggable>
    <small><b>Tipp: </b>Antwortmöglichkeiten können per Drag'n Drop umsortiert werden</small>
    <b-button variant="success" class="my-2" @click="addChoice">Antwortmöglichkeit hinzufügen</b-button>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import draggable from 'vuedraggable'

export default {

  name: 'editChoices',
  data(){
    return{
      
    }
  },
  props:{"question":{}},
  components:{
    draggable
  },
  created(){
    if(this.question.choices == null){
        this.$set(this.question, "choices", [{id: 0, text:""}]);
    }
    
  },
  methods:{
    addChoice(){
        var maxId = 0;
        this.question.choices.forEach(c => {
            if(c.id > maxId){
                maxId = c.id;
            }
        });
    
        this.$set(this.question.choices, this.question.choices.length, {id: maxId+1, text:""});
    }
  },
}
</script>
