<template>
    <div>
        <p v-for="reply in withNoEmpty" class="reply">
            {{reply}}
        </p>
    </div>
</template>

<script>

import { mapState } from 'vuex'

export default {

  name: 'freeTextResults',
  data(){
    return{
    }
  },
  props:{
    replys: {},
  },
  components:{
  },
  computed:{
    withNoEmpty(){
        return this.replys.filter(r => r != null && r != "");
    }
  
  },
  methods:{
    
  },
  
}
</script>

<style scoped>
    .reply{
        border-bottom: 1px solid #888;
    }
</style>
