<template>
<b-col cols="8" offset="1">
    <h1>Aktiven Fehlersuche</h1>
    Eine Anleitung für die Fehlersuche findest du <a href="https://aktiv.naju-bayern.de/najuwiki/index.php/Najulytics#Fehlersuche" target="_blank">hier</a>.
    <h2>Aktive mit verschiedenen Geburtsdaten</h2>
    <ul>
      <li v-for="err in result.diffGebdaten" :key="err.id">
        <router-link :to="{ name: 'viewAktiven', params: { aid: err.id }}">{{err.vorname}} {{err.zuname}}</router-link>
      </li>
    </ul>

    <h2>Aktive zu denen mehrere Einträge vorliegen</h2>
    <ul>
      <li v-for="err in result.sameName" :key="err.key">
        {{err.vorname}} {{err.nachname}} (
        <router-link v-for="id, index in err.ids" :key="id" :to="{ name: 'viewAktiven', params: { aid: id }}">{{index + 1}} </router-link>
        )
      </li>
    </ul>

    <h2>Aktive mit falschen E-Mail Adressen</h2>
    <ul>
      <li v-for="err in result.defektEmail" :key="err.id">
        <router-link :to="{ name: 'viewAktiven', params: { aid: err.id }}">{{err.vorname}} {{err.zuname}}</router-link>
      </li>
    </ul>
</b-col>

</template>

<script>
export default {
  name: 'AktivenDebugger',
  data(){
    return {
      result:{
        diffGebdaten:[]
      }
    }
  },
  created(){
    var t = this;
    $.get(this.$root.api_root + "seminare/findJugendbErrors.php",{}, function(res){
      t.$root.checkRespForLoginFail(res);
      t.result = JSON.parse(res);
    });
  }
}
</script>
