<template>
  <div>
    <small>Der Arbeitstitel diehnt dazu die Veranstaltungen über die Jahre hinweg zu vernetzen. Es ist also wichtig, dass du nich jedes Jahr einen neuen erfindest sondern wenn es die Veranstaltung schon einmal gab den exakt gleichen Titel wie letztes Jahr wählst!</small>
    <input type="text" name="searchfield" class="form-control" v-model="workingTitle" ref="search" no-autocomplete autocomplete="off"/>
    <div class="alert alert-warning my-2" role="alert" v-if="isNew">
      <strong>Warnung!</strong> Diesen Arbeitstitel giebt es noch nicht. Ist das Seminar wirklich neu?
    </div>
  </div>
</template>


<script>



export default {
  name: 'WorkingTitlePicker',
  data(){
    return {
      workingTitle: "",
      workingTitles: ["wert","asdf"]
    };
  },
  props:{
    value:{ },
  },

  mounted(){
    this.workingTitle = this.value;
      var t = this;

      $.get(this.$root.api_root + "getWorkingTitles.php",{
        },function(res){
          t.$root.checkRespForLoginFail(res);
          t.workingTitles = JSON.parse(res);

          $(t.$refs.search).autocomplete({
              source: t.workingTitles,
              select:function(event,ui){
                t.workingTitle = ui.item.label;
              }
          });
      });
  },
  beforeDestroy(){
    $(this.$refs.search).autocomplete("destroy");
  },
  methods:{

  },
  computed:{
    isNew(){
      if(this.workingTitle == "")
        return false;
      return !this.workingTitles.includes(this.workingTitle);
    }
  },
  watch:{
    workingTitle(){
      this.$emit("input",this.workingTitle);
    },
    value(newV){
      this.workingTitle = newV;
    }
  }
}

</script>

<style scoped>


</style>


