<template>
<div v-if="canSeeCriminalRecordCertificate">
  <h2>Führungszeugnis</h2>
  <span v-if="isRequired">Ein Führungszeugnis ist nötig, da {{aktiver.vorname}} {{requiringFunktionenText}}</span>
  <b-alert variant="danger" :show="isRequired && aktiver.dateOfCriminalRecordCertificate == null"><b>Fehlt</b> Obwohl ein Führungszeugnis benötigt wird wurde bisher keines abgegeben!</b-alert>
  <b-alert variant="warning" :show="isOutdated"><b>Veraltet!</b> Das Führungszeugnis wurde das letzte mal vor mehr als 3 Jahren vorgelegt!</b-alert>
   <b-form-row class="my-2">
      <b-col cols="4">Führungszeugnis abgegeben am:</b-col>
      <b-col cols="4"><datepicker v-model="aktiver.dateOfCriminalRecordCertificate" placeholder="Noch nie" :disabled="!canEditCriminalRecordCertificate"/></b-col>
      <b-col cols="4"><b-button @click="aktiver.dateOfCriminalRecordCertificate = Date.now()" v-if="canEditCriminalRecordCertificate">Heute</b-button></b-col>
   </b-form-row>

   <b-form-row class="my-2">
      <b-col cols="4">Führungszeugnis angefragt am:</b-col>
      <b-col cols="4"><datepicker v-model="aktiver.dateOfCallForCriminalRecordCertificate" placeholder="Aktuell nicht" :disabled="!canEditCriminalRecordCertificate"/></b-col>
      <b-col cols="4">
        <b-button @click="aktiver.dateOfCallForCriminalRecordCertificate = Date.now()" v-if="canEditCriminalRecordCertificate">Heute</b-button>
        <b-button @click="aktiver.dateOfCallForCriminalRecordCertificate = null" v-if="canEditCriminalRecordCertificate">Zurücksetzen</b-button>
      </b-col>
   </b-form-row>

   <b-form-row class="my-2">
      <b-col cols="4">Ehrenerklärung abgegeben:</b-col>
      <b-col cols="8"><b-form-checkbox v-model="aktiver.hasEhrenerklaerung" :disabled="!canEditCriminalRecordCertificate">{{aktiver.vorname}} hat eine Ehrenerklärung abgegeben</b-form-checkbox></b-col>
   </b-form-row>

</div>
</template>

<script>

import datepicker from "../datepicker.vue"
import { mapState } from 'vuex'

export default {
  name: 'CriminalRecordCertificateEditor',
  props:["aktiver"],
  data(){
    return {
    };
  },
  created(){
    this.$store.dispatch('loadFunktionen');
    this.$store.dispatch("loadUser");
  },
  components:{
    datepicker
  },
  computed:{
    requiringFunktionen(){
      if(this.aktiver.functionsRequiringCriminalRecordCertificate == null || this.$store.state.seminare.funktionen == null)
        return [];
      return this.aktiver.functionsRequiringCriminalRecordCertificate.map(gid => {
        return this.$store.state.seminare.funktionen[gid].name;
      });
    },
    requiringFunktionenText(){
      var ret = "";
      if(this.aktiver.kindergruppen.length > 0){
        ret += "eine Kindergruppe leitet";
      }
      if(this.isRequiredByFunktion){
        if(ret != ""){
          ret += " und ";
        }
        ret += "Mitglied in folgenden Gruppen ist: " + this.requiringFunktionen.join(", ");
      }
      return ret;
    },
    isRequiredByFunktion(){
      return this.aktiver.functionsRequiringCriminalRecordCertificate != null && this.aktiver.functionsRequiringCriminalRecordCertificate.length > 0;
    },
    isRequired(){
      return this.isRequiredByFunktion || this.aktiver.kindergruppen.length > 0;
    },
    isOutdated(){
      if(this.aktiver.dateOfCriminalRecordCertificate == null)
        return false;
      return Date.now() - this.aktiver.dateOfCriminalRecordCertificate > 3* 365 * 24*60*60 * 1000;
    },
    ...mapState({
      canSeeCriminalRecordCertificate: state => state.base.user.canSeeCriminalRecordCertificate,
      canEditCriminalRecordCertificate: state => state.base.user.canEditCriminalRecordCertificate,
    })
  },
  methods:{
  }
}

</script>
