<template>
<div>
  <div class="d-print-none">

  </div>


  <div id="card" class="inside">
    <img src="../assets/logoOhneSchrift.png" class="logo" />
    <h1>{{item.name}}</h1>
    <div id="atomicText" v-if="item.isAtomic">Darf nicht zerpflückt werden!</div>
    <ul>
      <li v-for="child in item.contained" v-bind:key="child.id">{{child.num}} {{child.name | capitalize}}</li>
    </ul>
  </div>
</div>
</template>


<script>

import { mapState } from 'vuex'

export default {
  name: 'LabelPrinter',
  data(){
    return {
      item: {},
    };
  },
  props:[],
  components:{
  },
  methods:{
    loadItem(id){
      var t = this;
      $.get(this.$root.api_root + "stock/getItem.php",{
        iid: id
      },function(data){

        t.$root.checkRespForLoginFail(data);
        t.item = JSON.parse(data);
      });
    }
  },
  beforeRouteUpdate(to, from, next){
    var t = this;
    this.loadItem(to.params.iid);
    next();
  },

  created(){
    var t = this;
    this.loadItem(this.$route.params.iid);
    this.$store.dispatch("loadRooms");
  },

   computed:{
    placeText(){
      if(this.item.containing != null)
        return "Gehört in " + this.item.containing.name;
      if(this.rooms == null || this.item.room == null)
        return this.place;
      return this.rooms[this.item.room] + " " + this.item.place;
    },

    ...mapState({
      rooms: state => state.stock.rooms
    })
  },

}

</script>

<style scoped>
#card.inside{
  font-size: 20px;
}

#card.outside.small{
  font-size: 30px;
}

#card.outside.medium{
  font-size: 40px;
}

#card.outside.large{
  font-size: 60px;
}

.logo{
  width:8em;
  float:right;
}

h1{
  font-size:3em;
}

#placeDesc{
  font-size: 1em;
}



</style>
