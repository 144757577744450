export default {
  state: {
    feederbowls: [],
    feederbowlsLoading: false
  },
  mutations: {
    setFeederbowls(state, feederbowls){
       state.feederbowls = feederbowls;
       state.feederbowlsLoading = false;
    },
    setFeederbowlsLoading(state){
      state.feederbowlsLoading = true;
    }
  },
  actions: {
    loadFeederbowls({ commit, state }, force = false){
      var t = this;
      if((state.feederbowls.length > 0 && !force) || state.feederbowlsLoading){
       return;
      }
      commit("setFeederbowlsLoading");
      $.get(api + "fundmonitor/getFeederbowls.php",{
      },function(res){
        t.checkRespForLoginFail(res);
        commit("setFeederbowls", JSON.parse(res));
      });
    }
  }
}
