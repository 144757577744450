<template>
  <b-col cols="6" offset=2>
    <h1>Ferienbörse bearbeiten</h1>
    <b-form-row class="my-2">
      <b-col cols="2">Name</b-col>
      <b-col cols="10"> <b-input v-model="boerse.name"/></b-col>
    </b-form-row>
    <b-form-row class="my-2">
      <b-col cols="2">Beschreibung</b-col>
      <b-col cols="10"> <b-textarea v-model="boerse.description"  rows="8"/></b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Veranstaltungen</b-col>
      <b-col cols="10">
        <b-checkbox v-model="boerse.noVacations" :value="false" :unchecked-value="true" inline>Für Freizeiten</b-checkbox>
        <b-checkbox v-model="boerse.noTrainings" :value="false" :unchecked-value="true" inline>Für Fortbildungen</b-checkbox>
      </b-col>
    </b-form-row>

    <b-form-row class="my-2">
      <b-col cols="2">Filter durch Region</b-col>
      <b-col cols="10"> <regionInput v-model="boerse.filterLoc" placeholder="Veranstaltungen aus allen Regionen"/></b-col>
    </b-form-row>

    <saveBtn ref="saveBtn" :saveable="boerse" :disabled="boerse.name == ''" api="flyer/saveFerienboerse.php" @saved="setId" class="mx-1"/>
    <b-button variant="danger" @click="del" class="mx-1">Ferienbörse löschen</b-button>
  </b-col>

</template>


<script>
import saveBtn from '../../saveBtn.vue'
import regionInput from "./regionInput.vue"
import { mapState } from 'vuex'

export default {
  name: 'EditFerienboerse',
  data(){
    return {
      boerse:{
        name:"",
        description: "",
        jsCode: "",
        filterLoc:null,
        noVacations: false,
        noTrainings: false
      },
    }
  },
  components:{
    saveBtn,
    regionInput
  },
  methods:{
    loadBoerse(id){
      var t = this;
      if(id=="new"){
        t.boerse = {
          id:"new",
          name:"",
          description: "",
          jscode: "",
          filterLoc:null,
          noVacations: false,
          noTrainings: false,
        };
        if(this.$refs.saveBtn != null)
          this.$refs.saveBtn.reset();
        return;
      }
      $.get(this.$root.api_root + "flyer/getFerienboerse.php",{
        id: id,
      },function(res){
        t.$root.checkRespForLoginFail(res);
        if(res.trim().indexOf("not found") == 0){
          alert("Eintrag nicht gefunden");
          t.gruppe = {};
          return;
        }
        t.boerse = JSON.parse(res);
        t.$refs.saveBtn.reset();
      });
    },
    setId(resp){
      this.boerse.id = resp;
      this.$refs.saveBtn.reset();
    },
    del(){
      var t = this;
      if(!confirm("Willst du diese Ferienbörse wirklich löschen?") || !confirm("Wirklich wirklich?"))
        return;
      $.get(this.$root.api_root + "flyer/deleteFerienboerse.php",{
        id: this.boerse.id
      },function(res){
        t.$root.checkRespForLoginFail(res);
        t.$router.push("/");
      });
    },
  },
  created(){
    this.loadBoerse(this.$route.params.id);
  },


  beforeRouteUpdate (to, from, next) {
    if(! this.$refs.saveBtn.confirmLeave()){
      next(false);
      return;
    }
    this.loadBoerse(to.params.id);
    next();
  }
}

</script>

<style scoped>


</style>
