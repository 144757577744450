<template>
  <div class="question">
    <h5>{{question.name}}</h5>
    <p>{{question.desc}}</p>
    <b-form-radio-group v-if="question.type=='simpleChoice'" v-model="result" :options="choices" stacked :disabled="disabled" :required="question.required"></b-form-radio-group>
    <template v-if="question.type=='multipleChoice'">
        <b-form-checkbox-group v-model="result" :options="choices" stacked :disabled="disabled"></b-form-checkbox-group>
        <small><b>Tipp:</b> Hier kannst du mehrere Optionen auswählen</small><br>
    </template>
    <b-form-select v-if="question.type=='simpleChoiceSelect'" v-model="result" :options="choices" stacked :disabled="disabled" :required="question.required"></b-form-select>
    <b-form-group invalid-feedback="Bitte gebe hier etwas an!" v-if="question.type == 'freeShort'" class="my-1">
        <b-form-input v-model="result" :disabled="disabled" :required="question.required"/>
    </b-form-group>
    <b-form-group invalid-feedback="Bitte gebe hier etwas an!" v-if="question.type == 'freeLong'" class="my-1">
        <b-form-textarea v-model="result" :disabled="disabled" :required="question.required"/>
    </b-form-group>
    
    <b-button class="my-1 mx-1 oi oi-trash" variant="danger" v-if="edit" @click="$emit('delete');"></b-button>
    <b-button class="my-1 oi oi-pencil" v-if="edit" @click="$refs.editQuestion.show()"></b-button>
    <editQuestion ref="editQuestion" :question="question"/>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import { VueEditor } from 'vue2-editor'
import editQuestion from "./editQuestion.vue"

export default {

  name: 'showQuestion',
  data(){
    return{
       result:null,
    }
  },
  props:{
    "question":{}, 
    "edit":{default: false}, 
    disabled:{default:false},
    results: {default: null}
  },
  components:{
    VueEditor,
    editQuestion
  },
  created(){
    if(this.question.type == null){
        this.$set(this.question,"type","");
    }
  },
  methods:{
  },
  computed:{
    choices(){
        return this.question.choices.map(c => c.text);
    }
  },
  watch:{
    result(){
        if(this.results == null)
            return;
        this.$set(this.results, this.question.id, this.result);
    }
  }
  
}
</script>

<style scoped>
.question{
    background: #fff;
    padding: 10px;
    margin-bottom: 10px;
}

</style>
