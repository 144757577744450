<template>
  <b-row>
    <b-col cols="8">
      <locationInput placeholder="Ort" v-model="value.location" :disabled="disabled"/>
    </b-col>
    <b-col cols="4">
      <b-input type="number" v-model="value.radius" placeholder="r in km" :disabled="disabled"/>
    </b-col>
  </b-row>
</template>

<script>
import locationInput from "./locationInput.vue"

export default {
  name: 'LocationFilter',
  components:{
    locationInput
  },
  props:["value", "disabled"],
  data(){
    return {
    }
  },
  methods:{


  },
  created(){
    if(typeof(this.value) != "object" || this.value == null || !this.value.hasOwnProperty("location") || !this.value.hasOwnProperty("radius")){
      this.$emit("input",JSON.parse('{"location":{}, "radius": null}'));
    }
  },
  mounted(){
    var t = this;

  },

}

</script>

<style scoped>

</style>
