<template>
  <b-col cols="6" offset="2">
    <h1>Änderungen</h1>
      <b-row>
        <b-col cols="6">
          <Seachfield v-model="filter.filterUser" placeholder="Eintrag finden"/>
          <b-checkbox class="my-2" v-model="filter.filterHasFunktion">Nur Einträge für Aktive mit Funktion (z.B. AK oder Kindergrupggenleitung) anzeigen</b-checkbox>
          <b-checkbox class="my-2" v-model="filter.filterDone">Nur Unbearbeitete anzeigen</b-checkbox>

        </b-col>
        <b-col cols="6"><b-pagination v-model="currentPage" :total-rows="numRows" :per-page="perPage" align="right"/> </b-col>
      </b-row>
      <b-table ref="table" :items="fetchLog" :fields="fields" :per-page="perPage" :current-page="currentPage" :filter="filterStr" class="whiteBox">
        <template v-slot:cell(id)="data">
            <router-link :to="{ name: 'viewAktiven', params: { aid: data.item.aid}}">
            {{data.item.vorname | capitalize}} {{data.item.zuname | capitalize}}
            </router-link>
        </template>

        <template v-slot:cell(field)="data">
            <template v-if="data.value == 'functionAdded'">Funktion hinzugefügt</template>
            <template v-else-if="data.value == 'functionRemoved'">Funktion entfernt</template>
            <template v-else>{{data.value| capitalize}}</template>
        </template>

        <template v-slot:cell(timestamp)="data">
            {{new Date(data.value).toLocaleDateString("de-DE")}}
        </template>

        <template v-slot:cell(done)="data">
          <DoneBtn :log="data.item"/>
        </template>

        <template v-slot:cell(prev_val)="row">
          <b-button size="sm" @click.stop="row.toggleDetails" v-if="row.item.field == 'infos'">
          {{ row.detailsShowing ? 'Verstecke' : 'Zeige'}} Änderunegn
          </b-button>
          <template v-else>
            {{row.value}}
          </template>
        </template>

        <template v-slot:cell(new_val)="row">
          <template v-if="row.item.field == 'functionAdded' || row.item.field == 'functionRemoved'">
            {{funktionName(row.value)}}
          </template>
          <template v-else-if="row.item.field == 'infos'"></template>
          <template v-else>
          {{row.value}}
          </template>
        </template>

        <template v-slot:row-details="row">
          <b-card>
            <b-row>
              <b-col cols="6">{{row.item.prev_val}}</b-col>
              <b-col cols="6">{{row.item.new_val}}</b-col>
            </b-row>
          </b-card>
        </template>
      </b-table>
      <b-pagination v-model="currentPage" :total-rows="numRows" :per-page="perPage" align="right"/>
  </b-col>
</template>


<script>

import DoneBtn from "./aktiveLogsDoneBtn.vue"
import Seachfield from "./search.vue"
import { mapState } from 'vuex'

export default {
  name: 'AktiveLogs',
  components:{
    DoneBtn,
    Seachfield
  },
  data(){
    return {
      logs:[],
      fields:[
        {key:"id", label:"Eintrag"},
        {key:"field", label:"Eigenschaft"},
        {key:"prev_val", label:"Alter Wert"},
        {key:"new_val", label:"Neuer Wert"},
        {key:"timestamp", label:"Änderungsdatum", sortable:true},
        {key:"done", label:"Übernommen"}
      ],
      currentPage: 1,
      numRows: 0,
      perPage: 20,
      filter: {
        filterUser:null,
        filterDone: false,
        filterHasFunktion: false
      }
    };
  },
  props:[""],
  created(){
    var t = this;
    this.$store.dispatch('loadFunktionen');
  },
  methods:{
    fetchLog(ctx, callback){
      var t = this;
      $.get(this.$root.api_root + "seminare/getLogs.php",{
        ctx: JSON.stringify(ctx)
      },function(res){
        t.$root.checkRespForLoginFail(res);
        var res = JSON.parse(res);
        t.numRows = res.count;
        callback(res.logs);
      });
    },
    funktionName(id){
      if(this.funktionen == null)
        return "";
      return this.funktionen[id].name;
    }
  },
  computed:{
    filterStr(){
      return JSON.stringify(this.filter);
    },
    ...mapState({
      funktionen: state => state.seminare.funktionen,
    })
  }
}

</script>

<style scoped>
.whiteBox{
  background: #fff;
}

</style>


