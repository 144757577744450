<template>
  <b-col cols="6" offset="2">
    <h1>Führungszeugnisse</h1>
      <p>{{crfs.length}} Aktive benötigen ein Führungszeugnis</p>
      <b-table :items="crfs" :fields="fields">
        <template v-slot:cell(vorname)="data">
          <router-link :to="{ name: 'viewAktiven', params: { aid: data.item.id }}">{{data.value}}</router-link>
        </template>

        <template v-slot:cell(nachname)="data">
          <router-link :to="{ name: 'viewAktiven', params: { aid: data.item.id }}">{{data.value}}</router-link>
        </template>

        <template v-slot:cell(hasEhrenerklaerung)="data">
          <span v-if="data.value" class="oi oi-check"></span>
        </template>
        <template v-slot:cell(dateOfCriminalRecordCertificate)="data">
          <template v-if="data.value == ''"><div class="fzMonitor missing" ></div> Fehlt</template>
          <template v-else-if="Date.now() - data.value > 5*365*24*60*60*1000"><div class="fzMonitor missing" ></div> Älter als 5 Jahre</template>
          <template v-else-if="Date.now() - data.value > 3*365*24*60*60*1000"><div class="fzMonitor over" ></div> Älter als 3 Jahre</template>
          <template v-else-if="Date.now() - data.value > 2.5*365*24*60*60*1000"><div class="fzMonitor almostOver" ></div> Älter als 2,5 Jahre</template>
          <template v-else><div class="fzMonitor ok" ></div> Alles im grünen Bereich</template>
        </template>
      </b-table>
  </b-col>
</template>

<script>
var idCtr = 0;
export default {
  name: 'CrimeRecordCertificates',
  data(){
    return {
      crfs:[],
      fields:[
        {key: "vorname", sortable:true},
        {key: "nachname", sortable:true},
        {key: "dateOfCriminalRecordCertificate", label: "Führungszeugnis", sortable:true},
        {key: "hasEhrenerklaerung", label: "Ehrenerklährung", sortable:true},
      ]
    };
  },
  created(){
    var t = this;
    $.get(this.$root.api_root + "seminare/getCrimeRecordCertificates.php",{
      },function(res){
        t.$root.checkRespForLoginFail(res);
        t.crfs = JSON.parse(res);
    });
  },
  mounted(){

  },
  methods:{

  }
}
</script>

<style scoped>
  .fzMonitor{
    width: 13px;
    height: 13px;
    margin-right: 2px;
    border-radius: 6.5px;
    display: inline-block;
  }

  .fzMonitor.ok{
    background: #3dce00;
  }
  .fzMonitor.missing{
    background: #ff3600;
  }

  .fzMonitor.over{
    background: #ff9c00;
  }

  .fzMonitor.almostOver{
    background: #c7ce00;
  }

  table{
    background:#fff;
  }
</style>

