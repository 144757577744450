<template>
  <b-col class="col-lg-10 col-12 offset-lg-1">
    <h1>Tabelle</h1>
    <b-row>
      <b-col class="col-12 col-lg-4 col-xl-3"><b-form-select v-model="year1" :options="years"/></b-col>
      <b-col class="col-12 col-lg-4 col-xl-3"><b-checkbox class="my-2" v-model="compare">Vergleichen mit</b-checkbox></b-col>
      <b-col class="col-12 col-lg-4 col-xl-3"><b-form-select v-if="compare" v-model="year2" :options="years"/></b-col>
    </b-row>
    <b-checkbox class="my-2" v-model="totalOnly">Nur Bilanz</b-checkbox>

    <table class="table table-striped table-bordered" v-if="year1 != null && (!compare || year2 != null)">
      <thead>
        <tr>
          <th scope="col">Kostenpunkt</th>
          <template  v-if="compare">
            <th scope="col" v-if="!totalOnly">Einnahmen {{baseYear}}</th>
            <th scope="col" v-if="!totalOnly">Einnahmen veränderung</th>
            <th scope="col" v-if="!totalOnly">Einnahmen {{compareYear}}</th>

            <th scope="col" v-if="!totalOnly">Ausgaben {{baseYear}}</th>
            <th scope="col" v-if="!totalOnly">Ausgaben veränderung</th>
            <th scope="col" v-if="!totalOnly">Ausgaben {{compareYear}}</th>

            <th scope="col">Bilanz {{baseYear}}</th>
            <th scope="col">Bilanz veränderung</th>
            <th scope="col">Bilanz {{compareYear}}</th>
          </template>
          <template v-else>
            <th scope="col" v-if="!totalOnly">Einnahmen</th>
            <th scope="col" v-if="!totalOnly">Ausgaben</th>
            <th scope="col">Bilanz</th>
          </template>
        </tr>
      </thead>
      <tableContent :projekt="$route.params.pid" :baseYear="baseYear" :compareYear="compareYear" :totalOnly="totalOnly" :compare="compare && year2 != null"/>
    </table>
  </b-col>
</template>
<script>
import { mapState } from 'vuex'
import tableContent from "./tableContent.vue"

export default {
  name: 'TableView',
  data(){
    return{
      year1:null,
      year2:null,
      compare:false,
      totalOnly: false,
    }
  },
  components:{
    tableContent
  },
  created(){
    this.$store.dispatch("loadHaushaltYears");
  },
  computed:{
    ...mapState({
      years_raw: state => state.haushalt.years,
    }),
    years(){
      if(this.years_raw == null)  return [];
      return this.years_raw;
    },
    baseYear(){
      if(!this.compare) return this.year1;
      var index = Math.min(this.years.indexOf(this.year1), this.years.indexOf(this.year2));
      if(index == -1) return null;
      return this.years[index];
    },

    compareYear(){
      if(!this.compare) return null;
      var index = Math.max(this.years.indexOf(this.year1), this.years.indexOf(this.year2));
      if(index == -1) return null;
      return this.years[index];
    }
  }
}
</script>

<style scoped>
  table{
    background: #fff;
  }
</style>
