<template>
  <div>
    <b-alert show variant="warning">
      <b>Achtung:</b> Es wurden verschiedene Geburtsdaten angegeben. Das kann zwei Gründe haben:
      <ol>
      <li>Jemand hat sich vertippt --> Du solltest das richtige Datum rausfinden und den Fehler beheben</li>
      <li>Es gibt zwei Personen mit den gleichen Namen --> Du solltest diesen Eintrag <span class="btn-link" @click='showModal = true'>aufsplitten</span></li>
      </ol>
      Hilfe findest du <a href="https://aktiv.naju-bayern.de/najuwiki/index.php/Najulytics#Fehlersuche" target="_blank">hier</a>.
    </b-alert>

    <b-modal id="modal1" title="Eintrag aufsplitten" v-model="showModal">
      <p class="my-2" v-if="!isSaved">Bevor du diesen Eintrag aufsplitten kannst musst du speichern!</p>
      <p class="my-2" v-else>Beim Aufsplitten wird der bestehende Eintrag in zwei oder mehrere Einträge aufgeteilt. Dabei wird für jedes Geburtsdatum ein eigener Eintrag erzeugt.<br><br>
        Bevor du einen Eintrag aufsplittest solltest du:<br>
        <ul>
          <li>Prüfen ob es sich wirklich nicht um die gleiche Personen handelt</li>
          <li>Tippfehler in den Geburtsdaten beheben</li>
        </ul>

        Im Moment gibt es {{differentGebDat}} verschiedene Geburtsdaten. Es werden also {{differentGebDat}} Einträger erzeugt.
        <br><br>
        <b-button variant="info" @click="doSplit">Aufsplitten</b-button>
      </p>
    </b-modal>
  </div>

</template>


<script>
export default {
  name: 'ViewAktivenDifferentGebdat',
  data(){
    return {
      showModal: false
    };
  },
  components:{

  },
  props:["aid","differentGebDat", "isSaved"],
  methods:{
    doSplit(){
      var t = this;
      $.get(this.$root.api_root + "seminare/splitJugenddbAktiven.php",{
        aid: this.aid
      },function(res){
        t.$root.checkRespForLoginFail(res);
        if(res.indexOf("success") != -1){
          t.showModal = false;
          t.$emit("splitted");
        }
        else{
          alert("Fehler: " + res);
        }
      });
    }
  }

}

</script>

<style scoped>
table{
  background: #fff;
}

</style>


