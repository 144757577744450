<template>
  <div>
    <b-checkbox v-if="!boerse.isMarkedAsDoesNotFit" v-model="boerse.isDone" class="my-1" @input="unsaved = true">Eingetragen?</b-checkbox>
    <b-input v-model="boerse.url" placeholder="Adresse der Seite" class="my-1" v-if="boerse.isDone" @input="unsaved = true"/>
    <b-button v-if="unsaved" @click="save" :disabled="saving" class="my-1">{{saving ? "Wird gespeichert" : "Speichern"}}</b-button>
    <b-checkbox v-model="boerse.isMarkedAsDoesNotFit" class="my-1" @input="save" v-if="!boerse.isDone">Wird nicht eingetragen</b-checkbox>
  </div>
</template>



<script>

export default {

  name: 'EntryEditor',
  data(){
    return {
      unsaved: false,
      saving:false
    };
  },
  props: ["boerse", "page"],
  methods:{
    save(){
      var t = this;
      this.saving = true;
      $.post(this.$root.api_root + "flyer/saveFerienboerseEntry.php",{
        data: JSON.stringify({id: t.boerse.id, isDone: t.boerse.isDone, url: t.boerse.url, page: t.page.id, isMarkedAsDoesNotFit: t.boerse.isMarkedAsDoesNotFit})
      },function(data){
        t.$root.checkRespForLoginFail(data);
        t.saving = false;
        t.unsaved = false;
      });
    }
  },
}

</script>
