import { render, staticRenderFns } from "./editListOwners.vue?vue&type=template&id=507e3ade&scoped=true&"
import script from "./editListOwners.vue?vue&type=script&lang=js&"
export * from "./editListOwners.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "507e3ade",
  null
  
)

export default component.exports