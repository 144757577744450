<template>
  <table ref="infoTable" id="infoTable" border=0  v-bind:class="{
    right:  rightOriented,
    left:  !rightOriented,
    rightImage: page.images.rightImage != null && page.images.rightImage.imid != null,
    bigRightImage: page.images.bigRightImage,
    semSinglePage: page.template == 'semSinglePage'}">
            <agerow v-if="page.ageFree != null || page.ageFrom != null || page.ageTo != null"
              :ageFrom="page.ageFrom" :ageTo="page.ageTo" :ageFree="page.ageFree"></agerow>
            <tr v-if="page.dateFrom != null"><td class="left">Wann?</td><td class="right"><dateViewer :dateFrom="page.dateFrom" :dateTo="page.dateTo" :showTime="page.options.showTime"/></td></tr>
            <tr v-if="page.place != null && page.place != ''"><td class="left">Wo?</td><td class="right"><span v-html="placeWithBr"></span><span v-if="page.bezirk != null && page.bezirk != ''"> ({{page.bezirk.toUpperCase()}})</span></td></tr>
            <priceRow :page="page"/>
            <tr v-if="page.leitung != null && page.leitung.length != 0">
              <td class="left">Leitung?</td>
              <td class="right">
                <div class="leitungsrow" v-for="leitung in page.leitung">
                  {{leitung.vorname}} {{leitung.nachname}} <span v-if="leitung.funktion != ''">({{leitung.funktion}})</span>
                </div>
              </td>
            </tr>
  </table>
</template>


<script>
import priceRow from './infoBoxPrice.vue'
import dateViewer from "../../dateViewer.vue"

export default {
  name: 'FlyerInfoBox',
  data(){
    return {
    };
  },
  props: ["page", "rightOriented"],
  components:{
    agerow:{
      props: ["ageFrom", "ageTo", "ageFree"],
      template: '<tr><td class="left">Alter?</td><td class="right">{{text}}</td></tr>',
      computed: {
        text: function(){
          if(this.ageFree != null)
            return this.ageFree;
          if(this.ageFrom != null && this.ageTo != null)
            return "von " + this.ageFrom + " bis " + this.ageTo + " Jahren";
          if(this.ageFrom != null)
            return "ab " + this.ageFrom + " Jahren";
          if(this.ageTo != null)
            return "bis " + this.ageTo + " Jahre";
        }

      }
    },
    priceRow,
    dateViewer
  },
  computed:{
    placeWithBr(){
        if(this.page.place == null)
            return "";
        return this.page.place.replace("\n", "<br>")
    }
  },
  methods:{
      indentInfoTable(){
          var posY = 0;
          var correctionFactor = 0.29;
          if(this.rightOriented)
            correctionFactor = 0;
          $(this.$refs.infoTable).find("td.left").each(function(k,each){
              var myHight = $(this).parent().height();
              $(this).css("padding-left", ((posY) * correctionFactor) + 2 + "px")
              posY += myHight;
          });
          var left = - posY * correctionFactor;
          $(this.$refs.infoTable).css("margin-left",left + "px");
      },
      isNull(p){
        return p == null || p == '';
      }
  },
  mounted(){
    this.indentInfoTable();
  },
  updated(){
    this.indentInfoTable();
  }
}

</script>

<style scoped>


#infoTable{
    font-size: 12px;
    position: absolute;
    bottom: 12mm;
}
#infoTable.left{
  left: 314px;
}

#infoTable.right{
  left: 45px;
}

#infoTable.right.rightImage{
  max-width: 440px;
}

#infoTable.right.rightImage.bigRightImage, #infoTable.semSinglePage{
  max-width: 240px;
}

#infoTable >>> .right{
  padding-left:10px;
}

td.left{
  vertical-align:top;
}

.leitungsrow{
  display: block;
  padding-left: 20px;
  text-indent: -20px
}

</style>

