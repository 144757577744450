<template>
<div>
<b-modal ref="pageInfoModal" title="Page Inspector" size="lg" ok-only>
  <VueObjectView v-model="page" />
</b-modal>
<b-modal ref="selectImageInfoModal" title="selectImage Help" ok-only>
  <p>Hilfsfunktion um ein Bild auszuwählen.</p>
  <p><b>Verwendung:</b><br>
  <code>var img = await selectImage("Titelbild");</code></p>
  <p><b>Referenz:</b><br>
  <code>selectImage(bildName, forFacebook = false, exclude = [])</code><br>
  <div class="my-1"><i>bildName</i>: Wir im Titel des Popups angezeigt</div>
  <div class="my-1"><i>forFacebook</i>: if true, all images that are not allowed to be displayed on facebook will be exkluded</div>
  <div class="my-1"><i>exclude</i>: Array of image objects that will not be in the selection</div>
  <div class="my-1"><i>return</i>: Image-Objekt aus der page</div>
  </p>

</b-modal>
<b>async function</b>(<span class="btn btn-link" @click="$refs.pageInfoModal.show()">page</span> <span class="btn btn-link" title="momentjs libraray">moment</span>,
  <span class="btn btn-link" @click="$refs.selectImageInfoModal.show()">selectImage</span>){<br>
<aceEditor ref="codeEditor" v-model="boerse.jsCode" @init="editorInit" lang="javascript" theme="chrome" width="100%" height="400"></aceEditor>
<b-button @click="insertOpen">Add Open</b-button>
<b-button @click="insertClick">Add Click</b-button>
<b-button @click="insertClearAndFill">Add Clear & Fill</b-button>
<b-button @click="insertFill">Add Fill</b-button>
<b-button @click="insertImage">Add Image</b-button>
<saveBtn  :saveable="boerse" api="flyer/saveFerienboerse.php"/>
<br>
}
</div>
</template>

<script>
import aceEditor from 'vue2-ace-editor'
import saveBtn from "../../saveBtn.vue"
import VueObjectView from 'vue-object-view'
export default {
  name: 'FillCodeEditor',
  created(){

  },
  data(){
    return {
      code:"",

    };
  },
  props: ["boerse","page"],
  components: {
    aceEditor,
    saveBtn,
    VueObjectView
  },
  methods: {
      editorInit: function () {
          require('brace/ext/language_tools') //language extension prerequsite...
          require('brace/mode/html')
          require('brace/mode/javascript')    //language
          require('brace/mode/less')
          require('brace/theme/chrome')
          require('brace/snippets/javascript') //snippet
      },
      insert(text){
        var editor = this.$refs.codeEditor.editor;
        editor.session.insert(editor.getCursorPosition(), text);
      },
      insertClick(){
        this.insert("{action:'click', data:{selector:''}},\n");
      },
      insertFill(){
        this.insert("{action:'fill', data:{selector:'', value: ''}},\n");
      },
      insertOpen(){
        this.insert("{action:'open', data:{url:''}},\n");
      },
      insertImage(){
        this.insert("{action:'image', data:{selector:'', value: 'IMAGE_ID'}},\n");
      },
      insertClearAndFill(){
        this.insert("{action:'clearAndFill', data:{selector:'', value: ''}},\n");
      }
  },
}
</script>
