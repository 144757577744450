<template>
  <b-modal ref="modal" title="Frage bearbeiten" size="lg" ok-only>
    <b-form-row class="my-2">
        <b-col cols="3">Name</b-col>
        <b-col cols="9"> 
            <b-input v-model="question.name" />
        </b-col>
    </b-form-row>
    <b-form-row class="my-2">
        <b-col cols="3">Beschreibung</b-col>
        <b-col cols="9"> <b-textarea v-model="question.desc" rows="4"/> </b-col>
    </b-form-row>
    <b-form-row class="my-2">
        <b-col cols="3">Fragentyp</b-col>
        <b-col cols="9"> 
            <b-form-select v-model="question.type">
                <option :value="null">Bitte Fragentyp auswählen</option>
                <option value="simpleChoice">Simple Choice (eine Option)</option>
                <option value="simpleChoiceSelect">Auswahlfeld</option>
                <option value="multipleChoice">Multiple Choice (mehrere Optionen)</option>
                <option value="freeShort">Freitext (kurz)</option>
                <option value="freeLong">Freitext (lang)</option>
            </b-form-select>
        </b-col>
    </b-form-row>
    <b-form-row class="my-2" v-if="question.type == 'simpleChoice' || question.type == 'multipleChoice' || question.type == 'simpleChoiceSelect'">
        <b-col cols="3">Antwortmöglichkeiten</b-col>
        <b-col cols="9"><editChoices :question="question"/></b-col>
    </b-form-row>
    <b-form-row class="my-2" v-if="question.type != 'multipleChoice'">
        <b-col cols="3">Pflichtfeld</b-col>
        <b-col cols="9"><b-form-checkbox v-model="question.required">Teinehmer müssen diese Frage beantworten</b-form-checkbox></b-col>
    </b-form-row>
  </b-modal>
</template>

<script>

import { mapState } from 'vuex'
import { VueEditor } from 'vue2-editor'
import editChoices from "./editChoices.vue"


export default {

  name: 'editQuestion',
  data(){
    return{
      
    }
  },
  props:["question"],
  components:{
    VueEditor,
    editChoices 
  },
  created(){
    
  },
  methods:{
    show(){
        this.$refs.modal.show();
    }
  },
}
</script>

