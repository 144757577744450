<template>
  <div id="doubleWrapperBox">
    <div class="singlePage">
        <flyerImageBox v-model="page.images" :rightOriented="false" :disabled="!page.locked"></flyerImageBox>
        <headline :value="page.headline" :rightOriented="false" :color="themeColor"></headline>
        <subheadline :value="page.subheadline" :rightOriented="false" :color="themeColor"></subheadline>
        <mainText class="mainText" :value="page.text" :rightOriented="false" :fullWidth='false' :fullHeight="true"
          :result="page1Result"></mainText>
        <imgRightsBox :images="page.images" :rightOriented="false"></imgRightsBox>
        <pageNrBox :nr="page.pageNr" :rightOriented="false"></pageNrBox>
    </div><div class="singlePage">
        <rightImagebox v-if="page.images.bigRightImage == null || page.images.bigRightImage == false" v-model="page.images" :disabled="!page.locked"></rightImagebox>
        <flyerImageBox v-else  v-model="page2Imgs" :rightOriented="true" :disabled="!page.locked"></flyerImageBox>

        <mainText class="mainText" :value="page1Result.overflow" :rightOriented="page.rightOriented" :fullWidth='true' :fullHeight="false"
          :result="result"></mainText>
        <infobox :page="page" :rightOriented="true" ></infobox>
        <imgRightsBox :images="page2Imgs" :rightOriented="true"></imgRightsBox>
        <pageNrBox :nr="Number(page.pageNr) + 1" :rightOriented="true"></pageNrBox>
    </div>
  </div>
</template>


<script>
import FlyerImageBox from './flyerImageBox.vue'
import rightImagebox from './rightImageBox.vue'
import HeadlineBox from './headlineBox.vue'
import SubheadlineBox from './subheadlineBox.vue'
import FlyerTextBox from './flyerTextBox.vue'
import InfoBox from './infoBox.vue'
import imgRightsBox from './imgRightsBox.vue'
import pageNrBox from './pageNrBox.vue'

export default {
  name: 'SemDoublePage',
  data () {
    return {
      page1Result: {uverflow: ""}
    }
  },
  props:["page", "themeColor", "result"],
  components: {
    'flyerImageBox': FlyerImageBox,
    'headline': HeadlineBox,
    'subheadline': SubheadlineBox,
    'mainText': FlyerTextBox,
    "infobox": InfoBox,
    imgRightsBox,
    pageNrBox,
    rightImagebox
  },
  computed:{
    page2Imgs(){
      return  {
        image1 : this.page.images.rightImage,
        image2: {imid: null},
        rightImage: {imid: null}
      }
    }
  }
}
</script>

<style>

#doubleWrapperBox{
  display: inline-block;
}

.singlePage{
  width: 154mm;
  height: 111mm;
  position: relative;
  display: inline-block;
  vertical-align:top;
}


</style>
