<template>
  <div>
    <h2>Zeitlicher Verlauf</h2>
    <EnrollmentStatistic :seminar="seminar"/>
    <h2>Werbemedien</h2>
    <advStatistics :semId="seminar.id"/>
  </div>
</template>


<script>


import EnrollmentStatistic from './seminarEnrollmentStatistic.vue';
import advStatistics from "./advStatistics/advStatistics.vue";

export default {
  name: 'SeminarStatistics',
  data(){
    return {

    };
  },
  components:{
    EnrollmentStatistic,
    advStatistics
  },
  props:["seminar"],
}

</script>

<style scoped>


</style>


