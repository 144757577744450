import {base64ToArray, arrayToBase64} from "./helperFunctions";

class Attestation {
    constructor(api_root) {
        this.api_root = api_root;
    }

    fetchChallengeFromServer(userId) {
        return new Promise((resolve, reject) => {
            $.post({
                type: 'POST',
                url: this.api_root + "fido2/fido2registerStart.php",
                data: {
                    id: userId
                },
                success: function (data) {
                    resolve(data);
                },
                error: function () {
                    //something went wrong. Show error box
                    reject("Verbindungsfehler");
                },
                dataType: 'json',
            });
        });
    }

    signWithSecuretyKey(credentialCreationOptions) {
        //All Variables here  are named  as described in W3C Recommendation: https://www.w3.org/TR/webauthn/
        console.log(credentialCreationOptions);
        credentialCreationOptions.challenge = base64ToArray(credentialCreationOptions.challenge);
        credentialCreationOptions.user.id = base64ToArray(credentialCreationOptions.user.id);
        if (credentialCreationOptions.excludeCredentials) {
            for (let i = 0; i < credentialCreationOptions.excludeCredentials.length; i++) {
                credentialCreationOptions.excludeCredentials[i].id = base64ToArray(credentialCreationOptions.excludeCredentials[i].id);
            }
        }
        console.log(credentialCreationOptions);
        let credential = navigator.credentials.create({
            publicKey: credentialCreationOptions
        });
        return credential;
    }

    uploadResponseToServer(credential, keyName) {
        console.log(this);
        return new Promise((resolve, reject) => {
            let attestationObject = credential.response.attestationObject;
            let clientDataJSON = credential.response.clientDataJSON;
            let rawId = credential.rawId;

            console.log("======= added credential:" + credential.id);
            $.ajax({
                type: 'POST',
                url: this.api_root + "fido2/fido2registerFinish.php",
                data: {
                    data: JSON.stringify({
                        id: credential.id,
                        rawId: arrayToBase64(rawId),
                        type: credential.type,
                        response: {
                            attestationObject: arrayToBase64(attestationObject),
                            clientDataJSON: arrayToBase64(clientDataJSON),
                        },
                    }),
                    keyName: keyName

                },
                success: function (data) {
                    if (data.success !== true) {
                        reject(data.error);
                    }
                    resolve(data.keyId);
                },
                error: function () {
                    reject("Verbindungsfehler");
                },

                dataType: 'json',
            });
        });
    }

    register(userId, keyName) {
        let t = this;
        return this.fetchChallengeFromServer(userId)
            .then((credentialCreationOptions) => t.signWithSecuretyKey(credentialCreationOptions))
            .then((credential) => t.uploadResponseToServer(credential, keyName));
    }
}

export {Attestation};