<template>
  <b-col cols="6" offset="2">
    <h1>Nutzer verwalten</h1>
    <b-table :items="users" :fields="cols" striped >
      <template v-slot:cell(name)="data">
        <router-link :to="{ name: 'user', params: { uid: data.item.id }}">
          {{data.value | capitalize}}
        </router-link>
      </template>
      <template v-slot:cell(flyer)="data"><icon :val="data.value"/></template>
      <template v-slot:cell(seminare)="data"><icon :val="data.value"/></template>
      <template v-slot:cell(kindergruppen)="data"><icon :val="data.value"/></template>
      <template v-slot:cell(stock)="data"><icon :val="data.value"/></template>
      <template v-slot:cell(admin)="data"><icon :val="data.value"/></template>
      <template v-slot:cell(fz)="data"><icon :val="data.value"/></template>
      <template v-slot:cell(fm)="data"><icon :val="data.value"/></template>
      <template v-slot:cell(insta)="data"><icon :val="data.value"/></template>
      <template v-slot:cell(poll)="data"><icon :val="data.value"/></template>
      <template v-slot:cell(useTwoFactor)="data"><icon :val="data.value"/></template>
    </b-table>
    <router-link :to="{ name: 'user', params: { uid: 'new' }}">
      <b-button variant="success">Neu</b-button>
    </router-link>
  </b-col>
</template>


<script>

export default {
  name: 'Users',
  components:{

  },
  data(){
    return {
      users:[],
      cols:[
        {key:"name"},
        {key:"flyer"},
        {key:"seminare"},
        {key:"kindergruppen"},
        {key:"stock", label: "Lager"},
        {key:"admin", label: "Administrator"},
        {key:"fz", label: "Führungszeugnisse"},
        {key:"fm", label: "Fördermonitor"},
        {key:"insta", label: "Instagram"},
        {key:"poll", label: "Umfragen"},
        {key:"useTwoFactor", label: "2fa"},
      ]
    };
  },
  props:[],
  components:{
    icon:{
      props: ["val"],
      template: "<span v-if='val == true' class='oi oi-check'></span>"
                +"<span v-else-if='val == \"edit\"' class='oi oi-pencil'></span>"
                +"<span v-else-if='val == \"view\"' class='oi oi-eye'></span>"
                +"<span v-else-if='val == \"email\"' class='oi oi-envelope-closed' title='Ist Betreuer einer Funktion und kann deren Mitglieder bearbeiten und E-Mails schreiben.'></span>"
    }
  },
  created(){
    var t = this;
    $.get(this.$root.api_root + "getUsers.php",{},function(res){
        t.$root.checkRespForLoginFail(res);
        var res = JSON.parse(res);
        t.users = res.map(u => {
          return {
            id: u.id,
            name: u.name,
            flyer: u.canFlyer,
            seminare: u.canViewSeminare ? (u.canEditSeminare ? "edit" : "view") : (u.isFunktionOwner ? "email" : false),
            kindergruppen: u.canEditKindergruppen ? "edit" : "view",
            stock: u.canViewStock ? (u.canEditStock ? "edit" : "view") : false,
            fz: u.canSeeCriminalRecordCertificate ? (u.canEditCriminalRecordCertificate ? "edit" : "view") : false,
            fm: u.canViewFundMonitor ? (u.canEditFundMonitor ? "edit" : "view") : false,
            insta: u.canInsta,
            poll: u.canPoll,
            admin: u.isAdmin,
            useTwoFactor: u.useTwoFactor,
          }
        });
    });
  }

}
</script>


<style scoped>
table{
  background: #fff;
}

</style>
