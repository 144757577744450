<template>
  <b-col col cols="12" lg="10" offset-lg=1 offset-xl=2 xl=8>
    <router-link :to="{ name: 'printItems'}"><b-button class="my-2">Etikettendruck</b-button></router-link>
    <b-row>
      <stockBox title="Listen">
          <lists />
      </stockBox>

      <stockBox title="Neue Sache">
          <add @newItem="newItem"/>
      </stockBox>

      <stockBox class="order-lg-last" title="ToDos">
        <todos />
      </stockBox>

      <stockBox title="Sachen finden">
          <search></search>
      </stockBox>
    </b-row>
  </b-col>
</template>


<script>

import search from "./searchItem.vue"
import add from "./addItem.vue"
import lists from "./lists.vue"
import todos from "./todos.vue"

export default {
  name: 'StockStartPage',
  data(){
    return {

    };
  },
  components:{
    search,
    add,
    lists,
    todos,
    stockBox:{template:'<b-col col cols="12" lg="6" class="stockBox"><div class="stockBoxInner"><h2>{{title}}</h2><hr /><slot /></div></b-col>', props: ["title"]}
  },
  methods:{
    newItem(i){
      this.$router.push({ name: 'editItem', params: { iid: i.id }});
    }
  }


}

</script>



<style scoped>
.stockBox{
  margin-bottom:10px;
  padding:20px;
}
>>> .stockBoxInner{
  background: #fff;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
}
</style>

