<template>
  <b-modal ref="modal" :title="headline" size="lg" ok-only class="viewDetailsModal">
    <b-table :items="values" :fields="fields" striped>
      <template v-slot:cell(value)="data">
        <slot v-bind:value="data.value">
          {{data.value}}
        </slot>
      </template>
      <template v-slot:cell(from)="data">
        <router-link v-if="data.item.anmeld != null" :to="{ name: 'editSeminaranmeldung', params: { id: data.item.anmeld.id }}">{{data.value}}</router-link>
        <template v-else>{{data.value}}</template>
      </template>
      <template v-slot:cell(edit)="data">
        <b-button variant="primary" @click="setVal(data)" v-if="aktiver.editable">Übernehmen</b-button>
      </template>

      <template v-slot:bottom-row="data" v-if="aktiver.editable">
        <slot name="addRow">
          <td colspan="2"><b-input ref="addField" v-model="addVal" placeholder="Manuelle Eingabe"/></td>
        </slot>
        <td><b-button @click="add">Übernehmen</b-button></td>
      </template>
    </b-table>
  </b-modal>
</template>


<script>



export default {
  name: 'ViewAktivenDetails',
  data(){
    return {
      addVal: ""
    };
  },
  props:{
    aktiver:{},
    semanmeldField: {},
    label: String,
    enteredValue:{},
    selectedValue:{},
    enterDate: Number,
    headline: String,
    hasDifferent: Number,
    takeoverClicked: {
      type: Function
    }
  },
  computed:{
    values(){
      if(this.aktiver.semanmeld == null)
        return [];
      var ret = this.aktiver.semanmeld.filter(anmeld => anmeld[this.semanmeldField] != null).map(anmeld => {
        return{
          value: anmeld[this.semanmeldField],
          fromDate: anmeld.anmeldedatum,
          from: "Seminaranmeldung \"" + anmeld.seminar.name + "\" vom " + new Date(anmeld.anmeldedatum).toLocaleDateString("de-DE"),
          anmeld: anmeld
        };
      });

      var firstOf2017 = 1483228800*1000;
      if(this.enteredValue != null){
      ret.push({
          value: this.enteredValue,
          fromDate: this.enterDate > firstOf2017 ? this.enterDate : firstOf2017,
          from: "Manuelle Eingabe" + (this.enterDate != null ?  " am " + new Date(this.enterDate).toLocaleDateString("de-DE") : "")
        });
      }

      ret.sort((a,b) => b.fromDate - a.fromDate);
      if(ret.length > 0)
        ret[0]._rowVariant = 'success';
      return ret;
    },
    hasDifferentValues(){
      /*var last = null;
      for(var i = 0; i < this.values.length; i++){
        if(last != null && last != this.values[i].value)
          return true;
        last = this.values[i].value;
      }
      return false;*/


      var unique = this.values.map(x => x.value).filter(function (value, index, self) {
        return self.indexOf(value) === index;
      });
      if(unique.length == 1)
        return 0;
      return unique.length;
    },
    fields(){
      var ret = [
        {key: "value", label: this.label},
        {key: "from", label: "Quelle"},
      ];
      if(this.aktiver.editable){
        ret.push({key: "edit", label: "Übernehmen"});
      }
      return ret;
    }
  },
  watch:{
    hasDifferentValues(val){
      this.$emit("update:hasDifferent",val);
    }
  },

  methods: {
    setVal(row){
      console.log(row);
      this.$emit("update:enterDate", Date.now());
      if(this.takeoverClicked != null)
        this.takeoverClicked(row);
      else{
        this.$emit("update:enteredValue", row.item.value);
        this.$emit("update:selectedValue", row.item.value);
      }

    },
    add(){
       if(this.$refs.addField != null){
        this.$emit("update:enteredValue", this.addVal);
        this.$emit("update:selectedValue", this.addVal);
       }
       this.$emit("add", true);
       this.$emit("update:enterDate", Date.now());
    },
    show(){
      this.$refs.modal.show();
    }
  }
}

</script>

<style scoped>
>>> .modal-dialog{
    min-height: 450px;
}

>>> .vdatetime-overlay{
    display:none;
}

</style>


