<template>
    <div class="offset-md-2 col-md-6">
      <h1>Printprodukte</h1>
      <b-table :items="filteredFlyers" :fields="cols">
        <template v-slot:cell(name)="data">
          <router-link :to="{ name: 'editFlyer', params: { fid: data.item.id }}">{{data.value}}</router-link>
        </template>

        <template v-slot:cell(lastModyfied)="data">
          {{new Date(data.value).toLocaleDateString("de-DE")}}
        </template>

        <template v-slot:cell(type)="data">
          {{data.value | capitalize}}
        </template>
      </b-table>
      <b-button variant="info" :pressed.sync="showDeleted">Gelöschte Anzeigen</b-button>

      <b-button @click="showModal = true" variant="success">Neu</b-button>

      <b-modal v-model="showModal" title="Neu" :okDisabled="!isNewTitleNew || newTitle == ''" @ok="add">
        <b-input placeholder="Titel" v-model="newTitle"/>
        <div class="my-2" v-if="!isNewTitleNew"><span ref="icon" class="oi oi-warning" />Es existiert bereis ein Produkt mit diesem Titel</div>
        <b-form-group label="Typ" class="my-3">
          <b-form-radio-group id="radio-group-2" v-model="newType" name="radio-sub-component">
            <b-form-radio value="flyer">Flyer</b-form-radio>
            <b-form-radio value="poster">Plakat</b-form-radio>
            <b-form-radio value="handzettel">Handzettel</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-modal>
    </div>
</template>


<script>
export default {
  name: 'FlyersEditor',
  data(){
    return {
      flyers:[],
      cols:[
        {key:"name", sortable:true},
        {key:"lastModyfied", label:"Letzte Änderung", sortable: true},
        {key:"type", label:"Typ"}
      ],
      showModal:false,
      newTitle: "",
      newType: "flyer",
      showDeleted:false
    }
  },
  created(){
    var t = this;
    $.get(this.$root.api_root + "flyer/getFlyers.php",{ },
    function(res){
      t.$root.checkRespForLoginFail(res);
      t.flyers = JSON.parse(res);
    });
  },
  props: [],
  components: {

  },
  methods:{
    add(){
      var t = this;
      $.post(this.$root.api_root + "flyer/newFlyer.php",{
          title: t.newTitle,
          type: t.newType
        },
        function(res){
          t.$root.checkRespForLoginFail(res);
          res = JSON.parse(res);
          t.flyers.push({
            id: res.fid,
            name: t.newTitle,
            type: t.newType,
            lastModyfied: Date.now()
          });
          t.newTitle = "";
      });
    }

  },
  computed:{
    isNewTitleNew(){
      return !this.flyers.map(f => f.name).includes(this.newTitle);
    },
    filteredFlyers(){
      if(this.showDeleted)
        return this.flyers.map(each => {
          each._rowVariant = each.deleted ? "hidden" : "";
          return each;
        });
      return this.flyers.filter(f => !f.deleted);
    },
  }
}

</script>

<style scoped>
.oi-warning{
    color: #cc0000;
  }

  table{
  background: #fff;
}

>>> .table-hidden{
  color:gray;
  font-style:italic;
}

</style>

