<template>
  <b-col class="col-lg-8 col-xl-6 col-12 offset-lg-2">
    <h1>Schätzungen</h1>
    <b-table :items="result" :fields="[
      {key:'name', sortable:'true'},
      {key:'feederbowl', label:'Fördertopf', sortable:'true'},
      {key:'price', label:'Preis', sortable:'true'}]" class="my-2">
        <template v-slot:cell(name)="data">
          <router-link :to="{ name: 'editFundEntry', params: { id: data.item.id }}">{{data.value}}</router-link>
        </template>
        <template v-slot:cell(price)="data">{{data.value}} €</template>
    </b-table>



  </b-col>
</template>

<script>

import { mapState } from 'vuex'


export default {

  name: 'ShowGuesses',
  data(){
    return{
      result:[]
    }
  },
  components:{

  },
  created(){
    var t = this;
    $.get(this.$root.api_root + "fundmonitor/getGuesses.php",{
    },function(res){
        t.$root.checkRespForLoginFail(res);
        t.result = JSON.parse(res);
    });
  },
}
</script>

<style scoped>

>>> table{
    background: #fff;
  }

>>> .table-guess{
  color:#666;
}

  .description{

  }

</style>
