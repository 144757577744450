<template>
  <span>
    <b-button variant="info" @click="transmit" class=" my-1 mx-1">Auf Website übertragen</b-button>
    <page2wordpress ref="p2w"/>
  </span>
</template>
<script>
import page2wordpress from "./page2wordpress.vue";

export default {
  name: 'Flyer2Wordpress',
  data(){
    return {
    };
  },
  props: ["flyer"],
  components: {
    page2wordpress
  },
  methods:{
    transmit(){
      var semPages = this.flyer.pages.filter(p => p.template == "semSinglePage" || p.template == "semDoublePage");
      console.log(semPages);
      this.$refs.p2w.show(semPages);
    }
  },
  computed:{
  }
}
</script>
