<template>
  <div class="my-2">
    <b>Passwort ändern</b>
    <b-input type="password" v-model="oldPw" class="my-1" placeholder="Altes Passwort"/>
    <b-input type="password" v-model="newPw" class="my-1" placeholder="Neues Passwort"/>
    <b-input type="password" v-model="newPw2" class="my-1" placeholder="Und nochmal das neue Passwort"/>
    <b-button variant="info" @click="changePw" :disabled="!isNotEmpty || done">{{done ? "Geändert" : "Ändern"}}</b-button>
  </div>
</template>



<script>
import { mapState } from 'vuex'

export default {
  name: 'ChangePassword',
  data(){
    return {
      oldPw:"",
      newPw:"",
      newPw2:"",
      done: false,
    };
  },
  props:[],
  components:{

  },
  methods:{
    changePw(){
      if(this.newPw != this.newPw2){
        alert("Passwörter stimmen nicht überein");
        return;
      }

      if(this.newPw.length < 8){
        alert("zu kurz");
        return;
      }

      if(this.newPw.indexOf("Eisvogel") != -1){
        alert("Passwärter mit Eisvogel sind nicht erlaubt!");
      }

      var t = this;
      $.post(this.$root.api_root + "changePw.php",{
        oldPw:t.oldPw,
        newPw: t.newPw
      },function(res){
        t.$root.checkRespForLoginFail(res);
        if(res.indexOf("wrong old") != -1){
          alert("Aktuelles Passwort ist falsch!");
          return;
        }
        t.oldPw = "";
        t.newPw = "";
        t.newPw2 = "";
        t.done = true;
      });

    }
  },
  mounted(){
  },
  created(){
    this.$store.dispatch("loadUser");
  },
  computed:{
    ...mapState({
      user: state => state.base.user,
    }),
    isNotEmpty(){
      return this.oldPw != "" && this.newPw != "" && this.newPw2 != "";
    }
  },
  watch:{
    newPw(){
      this.done = false;
    }
  }
}

</script>

<style scoped>

</style>

