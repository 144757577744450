<template>
<onlyInChrome>
  <div class="row flyerEditor">
      <div class="offset-md-2 col-md-6">
        <h1>{{flyer.name}}</h1>

            <div class="alert alert-danger" role="alert" v-if="flyer.deleted">
              <b>Achtung:</b> Dieser Flyer ist als gelöscht markiert. Er wird irgendwann automatisch entgültig gelöscht. <span class="btn btn-link" @click="setDeleted(false)">Wiederherstellen</span>
            </div>

            <div v-bind:class="{ hideDeleted:  hideDeleted}">
              <draggable v-model="flyer.pages" class="list-group">
                  <li v-for="page in flyer.pages" :key="page.id" :pid="page.id" class="list-group-item flyerPage" v-bind:class="{ deleted:  page.deleted}">
                    <template v-if="page.template == 'handzettelFront'">
                        Vorderseite
                    </template>
                    <template v-else-if="page.template == 'handzettelBack'">
                        Rückseite
                    </template>
                    <template v-else>
                    {{page.title}} <span v-if="page.title == ''">-- unbenannte Seite --</span>
                    </template>
                    <router-link :to="{ name: 'editPage', params: { pid: page.id }}"><span class="oi oi-pencil" style="float:right; margin-left:8px;">Edit</span></router-link>
                    <span style="float:right">
                      <span v-if="page.lockedBy != null"  ><span class="oi oi-lock-locked"></span> Wird bearbeitet von {{page.lockedBy}}</span>


                      <span class="oi oi-image orange" v-bind:class="{hide:!imagesMissing(pagesChecks.get(page.id))}" title="Bilder fehlen"/>
                      <span class="oi oi-image red" v-bind:class="{hide:!imageAutorMissing(pagesChecks.get(page.id))}" title="Bildautor fehlt"/>
                      <span class="oi oi-warning orange" v-bind:class="{hide: !(pagesChecks.has(page.id) && isDataIncomplete(pagesChecks.get(page.id)) != '')}" :title="isDataIncomplete(pagesChecks.get(page.id))"/>

                      <span class="oi oi-check" v-bind:class="{hide:page.textState == '', gray: page.textState == 'final', green: page.textState == 'spellchecked'}" :title="getTextStateText(page)"/>
                      <span class="oi oi-globe" v-bind:class="{hide:!page.onWebsite}" title="Auf Website übertragen"/>
                      <router-link :to="{ name: 'ferienboersen', params: { pid: page.id }}">
                        <b-badge v-if="(page.template=='semSinglePage' || page.template=='semDoublePage') && page.onWebsite"
                          class="mx-1"
                          :title="'Eingetragen auf ' + page.numRecomendedDoneBoersen + ' von ' + page.numRecomendedBoersen + ' vorgeschlagenen Ferienbörsen. Außerdem auf ' + page.numAdditionalDoneBoersen + ' weiteren Börsen.'"
                          :variant="page.numRecomendedDoneBoersen - page.numRecomendedBoersen == 0 ? 'success' : ''">{{page.numRecomendedDoneBoersen}} / {{page.numRecomendedBoersen}}<template v-if="page.numAdditionalDoneBoersen > 0"> + {{page.numAdditionalDoneBoersen}}</template>
                        </b-badge>
                      </router-link>
                    </span>
                  </li>
              </draggable>
            </div><br>

            <div class="alert alert-danger" role="alert" v-if="flyer.checks.length > 0">
                <strong>Warnung!</strong> Es wurden Fehler gefunden:
                <ul v-for="check in flyer.checks">
                  <li>{{check.error}}<span v-if="check.page != null"> auf Seite
                    <router-link :to="{ name: 'editPage', params: { pid: check.page }}">"{{check.title}}"</router-link></span>
                  </li>
                </ul>
            </div><br>

            <div class="alert alert-danger" role="alert" v-if="nrPages % 4 != 0 && flyer.type != 'poster'  && flyer.type != 'handzettel'" >
                <strong>Warnung!</strong> Anzahl der Seiten ({{nrPages}}) sollte ein Vielfaches von vier sein.
            </div><br>

            <template v-if="flyer.type != 'poster'">
              <button v-on:click="addPage" type="button" class="btn btn-primary my-1 mx-1"><span class="oi oi-plus"></span> Seite einfügen</button>
              <button v-on:click="saveOrder" type="button" class="btn btn-success my-1 mx-1"> Sortierung speichern</button>
              <button v-on:click="hideDeleted = !hideDeleted" type="button" class="btn btn-info my-1 mx-1">
                <span v-if="hideDeleted">Gelöschte  anzeigen</span>
                <span v-else>Gelöschte ausbelnden</span>
              </button><br><br>
            </template>
            <div v-if="pdfPending">
              <b>{{flyer.name}} wird erstellt: </b><br>
              {{actionText}}
              <div id="progressBar" ref="progressBar" v-bind:style="{width: progress + '%'}"></div>
              <button v-on:click="cancelPdf" type="button" class="btn btn-danger  my-1 mx-1">Gennerierung Abbrechen</button>
            </div>
            <template v-else>
              <button v-on:click="generatePdf(false)" type="button" class="btn btn-info my-1 mx-1">PDF erstellen</button>
              <button v-on:click="generatePdf(true)" type="button" class="btn btn-info my-1 mx-1">Vorschau erstellen</button>
            </template>
            <iframe ref="downloadFrame" style="width: 0; height: 0; visibility: hidden;"> </iframe>
            <b-button v-if="!flyer.deleted" variant="danger" @click="setDeleted(true)" class=" my-1 mx-1">Flyer löschen</b-button>
            <flyer2wordpress :flyer="flyer" v-if="flyer.type != 'poster'"/>
            <flyer2rce :flyer="flyer" v-if="flyer.type != 'poster'"/>
      </div>
  </div>
</onlyInChrome>
</template>


<script>
import draggable from 'vuedraggable'
import onlyInChrome from './onlyInChrome.vue'
import flyer2wordpress from "./flyer2wordpress.vue"
import flyer2rce from "./flyer2rce.vue"
export default {
  name: 'PageEditor',
  data(){
    return {
      flyer: {
        name: "Flyer A",
        pages: [],
        checks:[]
      },
      pdfPending: false,
      progress: 60,
      actionText: "",
      hideDeleted: true
    }
  },
  created(){
    this.loadFlyer(this.$route.params.fid);
  },
  mounted () {
    var parent = this;

   // $( "tbody" ).sortable();
      $.contextMenu({
            selector: '.flyerPage',
            build: function ($trigger, e) {

            var pid = $(e.target).attr("pid");
            if(pid == null){
              pid = $(e.target).parent("li").attr("pid");
            }

            var page = parent.flyer.pages.find(function(eachPage){
              console.log(eachPage.title);
              return eachPage.id == pid;
            });

            var items = {};
            if($(e.target).hasClass("deleted")){
              items.delete= {
                  name: "Wiederherstellen",
                  icon: "redo",
                  callback: function(itemKey, opt, rootMenu, originalEvent) {
                      $.get(parent.$root.api_root + "flyer/get_delPage.php?restore",{
                        pid: pid
                      },function(data){
                        parent.$root.checkRespForLoginFail(data);
                        page.deleted = false;
                        parent.$set(page, "deleted", false);
                      });
                  }
              };
            }
            else{
              items.delete= {
                  name: "Löschen",
                  icon: "delete",
                  callback: function(itemKey, opt, rootMenu, originalEvent) {

                      if(!confirm("Willst du diese Seite " + page.title + " wirklich löschen?"))
                        return;
                      $.get(parent.$root.api_root + "get_delPage.php",{
                        pid: pid
                      },function(){
                        parent.$set(page, "deleted", true);
                      });
                  }
              };
            }

                return {
                    items: items
                }
            },
        });
    },
  beforeDestroy(){
    $.contextMenu( 'destroy' );
  },
  props: [],
  components: {
    draggable,
    onlyInChrome,
    flyer2wordpress,
    flyer2rce
  },
  methods:{
    addPage(){
    var t = this;
    $.get(this.$root.api_root + "flyer/get_createPage.php",{
          fid: this.flyer.id
      },function(ret){
        t.$root.checkRespForLoginFail(ret);
        ret = JSON.parse(ret);
        t.flyer.pages.push({id:ret.pid, title: ""});
      });
    },
    saveOrder(){
      var t = this;
      $.get(this.$root.api_root + "flyer/get_savePageOrder.php",{
        fid: this.flyer.id,
        order: this.flyer.pages.map(page => page.id).toString()
      },function(ret){
        t.$root.checkRespForLoginFail(ret);
      });
    },
    generatePdf(prevMode){
    var t = this;
    this.pdfPending = true;
    this.progress = 0;
    this.actionText = "Erstellung gestartet";
    this.ws = new WebSocket(t.$root.full_api_root.replace("https://","wss://").replace("http://","ws://") + "flyer/flyerRenderer/");
    this.ws.onopen = function(){
      t.ws.send(JSON.stringify({
        fid: t.flyer.id,
        prevMode:prevMode
      }));

      t.ws.onmessage = function (event) {
        console.log(event.data);
        var msg = JSON.parse(event.data);
        if(msg.downloadKey != null){
          $(t.$refs.downloadFrame).attr("src",t.$root.api_root + "flyer/getRenderedFlyer/?key=" + msg.downloadKey);
          t.pdfPending = false;
        }
        if(msg.error != null){
          if(msg.error == "canceled")
            return; //we probably canceled it
          alert("Es ist ein Fehler aufgetreten: " + msg.error);
          t.pdfPending = false;
        }
        else if(msg.progress != null){
          t.progress = msg.progress.per * 100;
          if(msg.progress.action.creatingPage != null){
            t.actionText = "Erstelle Seite: " + msg.progress.action.creatingPage;
          }
          else if(msg.progress.action.merging != null){
            t.actionText = "Seiten werden zusammengefügt";
          }
        }
      }
    };
    },
    cancelPdf(){
      this.ws.send(JSON.stringify({
        cancel:true
      }));
      this.pdfPending = false;
    },

    loadFlyer(fid){
      var parent = this;
      $.get(this.$root.api_root + "flyer/get_flyer.php?incDeleted&runChecks",{
        fid: fid
      },function(data){
        parent.$root.checkRespForLoginFail(data);
        parent.flyer = JSON.parse(data);
      });
    },
    setDeleted(deleted){
      var t = this;
      $.get(this.$root.api_root + "flyer/setDeleted.php",{
        fid: t.flyer.id,
        deleted: deleted
      },function(data){
        t.$root.checkRespForLoginFail(data);
        t.flyer.deleted = deleted;
      });
    },

    isDataIncomplete(checks){
        if(checks == null)
          return "";
        return checks.filter(c => {
          return c.error == "Kein Alter angegeben" ||
            c.error == "kein Ort angegeben" ||
            c.error == "Kein Datum angegeben" ||
            c.error == "Kein Arbeitstitle angegeben" ||
            c.error == "Startdatum liegt in der Vergangenheit" ||
            c.error == "kein Bezirk angegeben" ||
            c.error == "kein Preis angegeben" ||
            c.error == "Preis für Mitglieder ist höher als für nicht-Mitglieder" ||
            c.error == "von-Alter größer als bis-Alter" ||
            c.error == "keine PLZ angegeben" ||
            c.error == "Startdatum ist nach dem Enddatum" ;
      }).map(c => c.error).join("\n");
    },
    imagesMissing(checks){
      if(checks == null)
          return false;
      return checks.some(c => {
        return c.error == "Bilder fehlen";
      });
    },

    imageAutorMissing(checks){
      if(checks == null)
          return false;
      return checks.some(c => {
        return c.error == "Bildautor fehlt";
      });
    },
    getTextStateText(page){
      switch(page.textState){
        case 'final': return "Text ist final geschrieben";
        case 'agreed': return "Text ist vom Seminarleiter genemigt";
        case 'spellchecked': return "Text ist von Iris korrigiert.";
      }
    }
  },
  computed:{
    nrPages(){
      return this.flyer.pages.filter(function(page){ return !page.deleted }).reduce(function(num, each){
        console.log(each.pageCount);
        return num + each.pageCount;
      },0);
    },
    pagesChecks(){
      var ret = new Map();
      this.flyer.checks.forEach((c) => {
        if(!ret.has(c.page)){
          ret.set(c.page, []);
        }
        ret.get(c.page).push(c);
      });
      return ret;
    }
  },
  beforeRouteLeave (to, from, next) {
    if(this.pdfPending){
      alert("PDF wird gerade Erstellt. Bitte nicht gehen!");
      next(false);
      return;
    }
    next();
  },
  beforeRouteUpdate (to, from, next) {
    if(this.pdfPending){
      alert("PDF wird gerade Erstellt. Bitte nicht gehen!");
      next(false);
      return;
    }
    this.loadFlyer(to.params.fid);
    next();
  }
}

</script>

<style scoped>
.flyerEditor .deleted{
  background: #ffaaaa88;
}

.flyerEditor .hideDeleted .deleted{
  display: none;
}

.orange{
  color: orange;
}

.red{
  color: red;
}

.green{
  color: #84ba02;
}

.gray{
  color: #999;
}

.hide{
  visibility:hidden;
}

#progressBar {
	background-color: #3E6FAD;
	width: 0px;
	height: 30px;
	margin-top: 10px;
	margin-bottom: 10px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	-o-border-radius: 5px;
	border-radius: 5px;
	-moz-transition: .25s ease-out;
	-webkit-transition: .25s ease-out;
	-o-transition: .25s ease-out;
	transition: .25s ease-out;
}

</style>

